import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


function SubjectsByExamSectionDropDown(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [Subjects, setSubjects] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
            
        setLoading(true);

        if(props.ExamID > 0 && props.Section_Id > 0) {
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            Section_Id: props.Section_Id,
            ExamID:props.ExamID,
        };
        console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Exam/GetSubjectsBySectionIDExamID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

       // console.log(data);

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if(response.data.status_code == "1")
                {
                    setSubjects(response.data.ListofRecords);
                }
                else
                {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        else {
            setLoading(false);
        }

    }, [props.Section_Id, props.ExamID]);

    <ToastContainer />
    if(loading) {
        return <option selected disabled>Loading...</option>
    }
    else {
        if(Subjects.length > 0) {
            return (
                
                Subjects.map((item, index) => {
                    return (
                        <option value={item.ID} key={index}>{item.Name}</option>
                    )
                })
            );
        }
        
    }
}

export default SubjectsByExamSectionDropDown;
