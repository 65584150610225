import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import RightsCheck from '../General/RightsCheck';
import { scrollToTop } from '../Shared/ScrollOnTop';

function StudentMenu(props) {

    return (
        <>
            <RightsCheck />
            <nav className={`navbar navbar-expand-lg navbar-module MenuSticky`}>
                <div className="container-fluid">
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} to="/students/AddStudent" >
                                    <span className='nav-link'> Add Student</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} to="/students/AdmitStudent">
                                    <span className="nav-link">
                                        Admit Student </span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} to="/students/NewStudentList">
                                    <span className="nav-link">
                                        New Student List </span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/students/SearchStudents"> Search Student</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/students/AdvanceSearch"> Advance Search</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/students/ChangeRegistrationNo">Change Registration No</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/students/BiodataLists">Biodata Lists</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default StudentMenu;