const config = {
    
  date_format: "DD/MM/YYYY",
  date_format_input: "YYYY-MM-DD",
  
  //  MEDIA_ROOT_URL: "http://localhost:8000/",
    base_url: "https://api.nscholar.com/api/",
    StaffImagePath: "https://api.nscholar.com/staff_images/",
    StudentImagePath: "https://api.nscholar.com/student_images/",
    images: "https://api.nscholar.com/images/",
    assets: "https://api.nscholar.com/assets/",
    root: "https://api.nscholar.com/",
  
  // changes in config file
  
  //  base_url: "http://192.168.1.99:8072/api/",
  // StaffImagePath: "http://192.168.1.99:8072/staff_images/",
  // StudentImagePath: "http://192.168.1.99:8072/student_images/",
  // images: "http://192.168.1.99:8072/images/",
  // assets: "http://192.168.1.99:8072/assets/",
  // root: "http://192.168.1.99:8072/",

//apis for mandi bahaudin school
  // base_url: "http://192.168.1.99/api/",
  // StaffImagePath: "http://192.168.1.99/staff_images/",
  // StudentImagePath: "http://192.168.1.99/student_images/",
  // images: "http://192.168.1.99/images/",
  // assets: "http://192.168.1.99/assets/",
  // root: "http://192.168.1.99/",


  //apis for Local HOst
  // base_url: "http://localhost:52476/api/",
  // StaffImagePath: "http://localhost:52476/staff_images/",
  // StudentImagePath: "http://localhost:52476/student_images/",
  // images: "http://localhost:52476/images/",
  // assets: "http://localhost:52476/assets/",
  // root: "http://localhost:52476/",


  };
  export default config;