import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import RightsCheck from '../General/RightsCheck';
import { scrollToTop } from '../Shared/ScrollOnTop';

function StaffAtMenu(props) {

    return (
        <>
            <RightsCheck />
            <nav className={`navbar navbar-expand-lg navbar-module MenuSticky`}>
                <div className="container-fluid">
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/StaffAttendance/MarkAttendance">Mark Attendance</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/StaffAttendance/AttendanceSummary">Attendance Summary</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/StaffAttendance/LeaveRegister">Leave Register</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/StaffAttendance/AttendanceRights">Attendance Rights</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/StaffAttendance/Report">Attendance Report</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default StaffAtMenu;