import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import Page from "../General/Page";
import { getMonthName } from '../General/Utility';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ReportStamps from "../General/ReportStamps";

const PrintMarkedAttendance = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    
    const [listOfLedger, setLedger] = useState([]);
    const [selectedDate, setselectedDate] = useState("");
    


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const date = urlParams.get('date');
        const Department = urlParams.get('Department');

        setselectedDate(date);

        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            StaffTypeID: Department,
            AttendanceDate: date,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'StaffAttendance/GetStaffAttendance',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
              //  console.log(response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofRecords);
                    setLoading(false);
                    
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });

    }, []);

    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            let count = 1;
            
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{count++}</td>
                            <td>{item.Code}</td>
                            <td>{item.Name}</td>
                            <td>{item.SelectedDesignation?.Name}</td>
                            <td className="text-center">{item.Absent_Leave == "P" ? <i class="ri-check-fill"></i> : ''}</td>
                            <td className="text-center">{item.Absent_Leave == "AB" ? <i class="ri-check-fill"></i> : ''}</td>
                            <td className="text-center">{item.Absent_Leave == "CL" ? <i class="ri-check-fill"></i> : ''}</td>
                            <td className="text-center">{item.Absent_Leave == "ML" ? <i class="ri-check-fill"></i> : ''}</td>
                            <td className="text-center">{item.Absent_Leave == "MAT" ? <i class="ri-check-fill"></i> : ''}</td>
                            <td className="text-center">{item.Absent_Leave == "LWOP" ? <i class="ri-check-fill"></i> : ''}</td>
                            <td className="text-center">{item.Absent_Leave == "ETC" ? <i class="ri-check-fill"></i> : ''}</td>
                            
                            
                        </tr>

                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="11"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    //P	AB	CL	ML	MAT	LWOP	ETC

    const statusCount = {
        P: 0, 
        AB: 0,
        CL: 0,
        ML: 0,
        MAT: 0,
        LWOP: 0,
        ETC: 0,
      };
      
      listOfLedger.forEach(({ Absent_Leave }) => {
        if (Absent_Leave === "P") {
          statusCount.P++;
        } else if (Absent_Leave === "AB") {
          statusCount.AB++;
        } else if (Absent_Leave === "CL") {
          statusCount.CL++;
        } else if (Absent_Leave === "ML") {
            statusCount.ML++;
        } else if (Absent_Leave === "MAT") {
            statusCount.MAT++;
        } else if (Absent_Leave === "LWOP") {
            statusCount.LWOP++;
        } else if (Absent_Leave === "ETC") {
            statusCount.ETC++;
          }

      });


   


    return (
        <>
            <ToastContainer />
            <Page orientation="potrait" size="a4">

                <table className="table table-sm table-attendance table-bordered table-print">
                    <thead>
                        <tr>
                            <th colSpan={11}>
                                <PrintHeader title={`STAFF ATTENDANCE DETAILS ${moment(selectedDate).format(config.date_format)} `} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Sr.#</th>
                            <th>Code</th>
                            <th>Staff Name </th>
                            <th>Designation  </th>
                            <th className="text-center">P</th>
                            <th className="text-center">AB </th>
                            <th className="text-center">CL </th>
                            <th className="text-center">ML</th>
                            <th className="text-center">MAT</th>
                            <th className="text-center">LWOP </th>
                            <th className="text-center">ETC  </th>
                           

                        </tr>
                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colspan="11">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                <>
                                    {renderLedger()}
                                    <tr>
                                        <td colSpan={4} style={{fontSize: "15px"}} className="text-end">Total:</td>
                                        <td className="text-center" style={{fontSize: "15px"}}>{statusCount.P}</td>
                                        <td className="text-center" style={{fontSize: "15px"}}>{statusCount.AB}</td>
                                        <td className="text-center" style={{fontSize: "15px"}}>{statusCount.CL}</td>
                                        <td className="text-center" style={{fontSize: "15px"}}>{statusCount.ML}</td>
                                        <td className="text-center" style={{fontSize: "15px"}}>{statusCount.MAT}</td>
                                        <td className="text-center" style={{fontSize: "15px"}}>{statusCount.LWOP}</td>
                                        <td className="text-center" style={{fontSize: "15px"}}>{statusCount.ETC}</td>
                                    </tr>
                                </>

                        }

                    </tbody>
                </table>
                    
                <ReportStamps />

            </Page>
        </>
    );
}

export default PrintMarkedAttendance;
