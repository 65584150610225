import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { Link, NavLink } from "react-router-dom";
import WingsDropDown from '../Shared/WingsDropDown';
import SessionsDropDown from '../Shared/SessionsDropDown';
import ClassesDropDown from '../Shared/ClassesDropDown';
import SectionDropDown from '../Shared/SectionDropDown';
import ExamsDropDown from '../Shared/ExamsDropDown';
import SubjectsByExamSectionDropDown from "../Shared/SubjectsByExamSectionDropDown";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Pagination from "../General/Pagination";
import { scrollToTop } from '../Shared/ScrollOnTop';
import TeacherMenu from "./TeacherMenu";
import SectionByTeacherDropDown from "../Shared/SectionByTeacherDropDown";
import { Form, PageItem } from 'react-bootstrap';
import GetSubjectsBySectionIDExamIDDropDown from "../Shared/GetSubjectsBySectionIDExamIDDropDown";

const Section_Incharge = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));

    const [SessionId, setSessioinId] = useState(localStorage.getItem("DefaultSession"));
    const [WingId, setWingId] = useState(0);
    const [ClassId, setClassId] = useState(0);
    const [SectionID, setSectionID] = useState(0);
    const [TeacherID, setTeacherID] = useState(0);
    const [ExamID, setExamID] = useState(0);
    const [Exams, setExams] = useState([]);

    const [SubjectID, setSubjectID] = useState(0);
    const [isUpdated, setIsUpdated] = useState(false);

    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [validated, setValidated] = useState(false);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [Employee, setEmployee] = useState({});


    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{count++ + (pageSize * (pageNo - 1))}</td>
                            <td>{item.Student_ID}</td>
                            <td>{item.Registration_No}</td>
                            <td>{item.Student_Name}</td>
                            <td>{item.Father_Name}</td>
                            <td>
                                <input type="number"
                                    value={item.Obtained_Marks} style={{ width: "100px" }}
                                    data-student-id={item.Student_ID}
                                    className="form-control marks text-center" />

                            </td>
                            <td>{item.Contact_Phone}</td>
                            <td>{item.Absent_Leave}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    const handleSubmit = (event) => {
        //console.log(event);
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                ID: UserID,

            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Payroll/GetEmployeeByID',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                    // console.log(response.data);
                    if (response.data.status_code == 1) {
                        setEmployee(response.data.Employee?.ID);
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });

            setLoading(true);
        }

        setValidated(true);
    }


    return (
        <>
            <ToastContainer />
            <TeacherMenu />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/teacher/Dashboard">Home</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Marks Entry as Section Incharge
                                        </li>
                                    </ol>
                                </nav>
                                <div className="page-title">
                                    Marks Entry as Section Incharge
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container body-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label for="" className="">Select Session:</label>
                                                        <select className="form-select" onChange={(e) => setSessioinId(e.target.value)} name="SessionID">
                                                            <option value="0">---All---</option>
                                                            <SessionsDropDown selectedSession={SessionId} />
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label for="" className="">Select Exam:</label>
                                                        <select className="form-select" onChange={(e) => setExamID(e.target.value)}
                                                            required>
                                                            <option value="0" >Please Select</option>
                                                            <ExamsDropDown SessionID={SessionId} />
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label for="" className="">Select Section:</label>
                                                        <select
                                                            className="form-select"
                                                            onChange={(e) => setSectionID(e.target.value)}
                                                            value={SectionID}
                                                            required
                                                        >
                                                            <option value="0">Please Select</option>
                                                            {SessionId !== 0 && (

                                                                <SectionByTeacherDropDown Sessionid={SessionId} />
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label for="" className="">Select Subject:</label>
                                                        <select className="form-select" name={SubjectID} onChange={(e) => setSubjectID(e.target.value)} required >
                                                            <option value="0" >Please Select</option>
                                                            <GetSubjectsBySectionIDExamIDDropDown SectionID={SectionID} ExamID={ExamID} />
                                                            {console.log(SectionID, ExamID)}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 offset-md-10">
                                                    <div className="form-group">
                                                        <input type="submit" value="Search" className="w-100 button button-primary mt-4"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content mt-2">
                <div className="container body-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <table className="table table-theme">
                                            <thead>
                                                <tr>
                                                    <th colspan="8">
                                                        TotalRecords  &nbsp; Records Found!
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>ID</th>
                                                    <th>Registration No</th>
                                                    <th>Student Name</th>
                                                    <th>Father Name</th>
                                                    <th>Obtained Marks</th>
                                                    <th>Total Marks</th>
                                                    <th>Pass Marks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    loading ?
                                                        <tr>
                                                            <td className="text-center" colspan="8">
                                                                <Spinner animation="border" role="status"></Spinner>
                                                            </td>
                                                        </tr>
                                                        :
                                                        <>
                                                            {renderLedger()}
                                                        </>

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Section_Incharge;