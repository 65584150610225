import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import AccountsMenu from './AccountsMenu';
import { NavLink } from 'react-router-dom';
import VoucherTypeDropdown from '../Shared/VoucherTypeDropdown';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import { calculateColumnTotal } from "../General/Utility";
import { scrollToTop } from '../Shared/ScrollOnTop';


function AddVoucher(props) {


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [DefaultFYear, setDefaultFYear] = useState(localStorage.getItem("DefaultFYear"));
    const [dData, setdData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [VoucherNo, setVoucherNo] = useState("");
    const [VoucherTypeDesc, setVoucherTypeDesc] = useState("");
    const [Accounts, setAccounts] = useState([]);
    const [date, setDate] = useState("");
    const [mainRemarks, setmainRemarks] = useState("");
    const [VoucherTypeID, setVoucherTypeID] = useState(0);

    // form data
    const [formData, setFormData] = useState([]);
    const [seq, setSeq] = useState('');
    const [accountId, setAccountId] = useState('');
    const [drAmt, setDrAmt] = useState('');
    const [crAmt, setCrAmt] = useState('');
    const [remarks, setRemarks] = useState('');
    const [code, setCode] = useState('');
    const [description, setDescription] = useState('');

    
    // loaders

    const [loadingVoucherType, setLoadingVoucherType] = useState(false);
    const [loadingAddVoucher, setLoadingAddVoucher] = useState(false);


    useEffect(() => {


        setLoading(true);


        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            FYear: DefaultFYear,
            AccountLevel: 3,
            pageNo: 1,
            pageSize: 1000,
            // pAccountID: props.ParentAccountID,
        };

        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Accounts/GetAccountsByYear',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        // console.log(data);

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setAccounts(response.data.ListofRecords);
                setLoading(false);

            })
            .catch(function (error) {
                console.log(error);
            });




    }, []);

    const handleNextVoucher = (e) => {

        setLoadingVoucherType(true);
        setVoucherTypeID(e.target.value);
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            FYear: DefaultFYear,
            VoucherTypeID: e.target.value
        };

        // console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Voucher/GetNextVoucherNo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setVoucherNo(response.data.VoucherNo);
                    setVoucherTypeDesc(response.data.VoucherTypeDesc);

                    setLoadingVoucherType(false);

                }
                setLoading(false);
            })
            .catch(function (error) {

                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }




    const handleDropdownChange = (selectedOption) => {

        let label = selectedOption.label;
        setAccountId(selectedOption.value);


        const description = document.getElementById('description');
        if (description) {
            description.value = selectedOption ? selectedOption.label : '';
            setDescription(selectedOption.label);
        }


        const code = document.getElementById('code');
        if (code) {
            let codevalue = dropdownOptions.find((option) => option.value === selectedOption.value)?.code
            code.value = codevalue;
            setCode(codevalue);
        }




    };

    const handleDelete = (index) => {
        const updatedFormData = [...formData];
        updatedFormData.splice(index, 1);
        setFormData(updatedFormData);
    };


    const dropdownOptions = Accounts.map(item => ({
        value: item.ID,
        label: item.Account_desc,
        code: item.Account_code
    }));


    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: '250px', // Set your desired width here
        }),
    };


    const handeSaveVoucher = () => {

        setLoadingAddVoucher(true);
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            FYear: DefaultFYear,
            VoucherTypeID: VoucherTypeID,
            Voucher_number: VoucherNo,
            Voucher_date: date,
            Remarks: mainRemarks,
            ListofVoucherDetail: formData
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Voucher/AddNewVoucher',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });


                    setLoadingAddVoucher(false);
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setLoadingAddVoucher(false);
                }
                
            })
            .catch(function (error) {

                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }


    const handleAdd = () => {
        const newObject = {
            Seq: formData.length,
            AccountID: accountId,
            Dr_Amt: parseInt(drAmt),
            Cr_Amt: parseInt(crAmt),
            Remarks: remarks,
            Code: code,
            Description: description,
        };

        setFormData([...formData, newObject]);

        //console.log(newObject);

        // Clearing the input fields after adding the object
        setSeq('');
        setAccountId('');
        setDrAmt('');
        setCrAmt('');
        setRemarks('');
        setCode('');
        setDescription('');

        const description1 = document.getElementById('description');
        description1.value = '';

        const code1 = document.getElementById('code');
        code1.value = '';
    };

    return (
        <>
        <ToastContainer />
            <AccountsMenu />
            <div className="container body-content">

                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/Accounts/AccountsDashboard">Accounts</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Voucher</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Add Voucher</div>
                            </div>
                            <div className="col-md-6">
                                <div className="page-right-actions">
                                    <NavLink to="/Accounts/ManageVouchers" className="button button-white button-icon">
                                        Manage Vouchers
                                        <i className="ri-add-line"></i>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body p-md-4">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group mb-3">
                                            <label>Date</label>
                                            <input type="date" onChange={(e) => setDate(e.target.value)} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group mb-3 position-relative">
                                            <label>Voucher Type</label>
                                            <select className="form-select" onChange={handleNextVoucher} required>
                                                <option value="">Please Select</option>
                                                <VoucherTypeDropdown />
                                            </select>
                                            {
                                                loadingVoucherType &&
                                                <div style={{position: "absolute", right: "40px", top: "35px"}}>
                                                    <Spinner animation="border" size="sm" role="status"></Spinner>
                                                </div>

                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group mb-3">
                                            <label>Voucher Type</label>
                                            <input className="form-control" value={VoucherTypeDesc} disabled />
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <div className="form-group">
                                            <label>Voucher No: </label>
                                            <input className="form-control" value={VoucherNo} disabled />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Remarks:</label>
                                            <input className="form-control" onChange={(e) => setmainRemarks(e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                {
                                    VoucherNo.length > 0 &&
                                    <div >

                                        <table className="table table-theme table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Account</th>
                                                    <th>Description</th>
                                                    <th>Code</th>
                                                    <th>Dr.</th>
                                                    <th>Cr.</th>
                                                    <th>Remarks</th>
                                                    <th className="text-center">Action</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td></td>
                                                        <td>{item.Description}</td>
                                                        <td>{item.Code}</td>
                                                        <td>{item.Dr_Amt}</td>
                                                        <td>{item.Cr_Amt}</td>
                                                        <td>{item.Remarks}</td>
                                                        <td>

                                                            <ul className="inline-action">
                                                                <li>
                                                                    <button type="button" onClick={() => handleDelete(index)} className="red"><i className="ri-delete-bin-line"></i></button>
                                                                </li>
                                                            </ul>

                                                        </td>
                                                    </tr>
                                                ))}

                                                <tr className="bg-light">
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><strong>{calculateColumnTotal(formData, 'Dr_Amt')}</strong></td>
                                                    <td><strong>{calculateColumnTotal(formData, 'Cr_Amt')}</strong></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr>
                                                    <td>


                                                        <Select
                                                            styles={customStyles}
                                                            onChange={handleDropdownChange}
                                                            options={dropdownOptions}
                                                        />

                                                    </td>
                                                    <td>
                                                        <input type="text"
                                                            placeholder='Description'
                                                            id="description"
                                                            onChange={(e) => setDescription(e.target.value)}
                                                            className="form-control" />
                                                    </td>
                                                    <td>

                                                        <input type="text"
                                                            placeholder='Code'
                                                            id='code'
                                                            onChange={(e) => setCode(e.target.value)}
                                                            className="form-control" />

                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={drAmt}
                                                            onChange={(e) => {
                                                                setDrAmt(e.target.value);
                                                                setCrAmt(0);
                                                            }}
                                                            placeholder="Debit"
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={crAmt}
                                                            onChange={(e) => {
                                                                setCrAmt(e.target.value);
                                                                setDrAmt(0);
                                                            }}
                                                            placeholder="Credit"
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={remarks}
                                                            onChange={(e) => setRemarks(e.target.value)}
                                                            placeholder="Remarks"
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        <ul className="inline-action">
                                                            <li>
                                                                <button onClick={handleAdd} className="blue"><i className="ri-add-line"></i></button>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="text-end">
                                        {
                                                loadingAddVoucher ?
                                                <Spinner animation="border" role="status"></Spinner>
                                                :
                                                <button onClick={handeSaveVoucher} className="button button-primary">Save</button>

                                            }

                                            
                                        </div>

                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default AddVoucher;