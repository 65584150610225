import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import SetupMenu from './SetupMenu';
import SessionsDropDown from '../Shared/SessionsDropDown';
import { toast, ToastContainer } from 'react-toastify';
import WingsDropDown from '../Shared/WingsDropDown';
import ClassesDropDown from '../Shared/ClassesDropDown';
import { scrollToTop } from '../Shared/ScrollOnTop';

const AddSection = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [SessionID, setSessionId] = useState(0);

    const [WingID, setWingId] = useState(0);
    // const [ClassID, setClassId] = useState(0);

    let navigate = useNavigate();

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                Section_Code: event.target.Section_Code.value,
                Section_Name: event.target.Section_Name.value,
                Section_Short_Name: event.target.Section_Short_Name.value,
                Section_Strength_Limit: event.target.Section_Strength_Limit.value,
                Class_Id: event.target.SelectedClass.value,
            };

            console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Sections/AddSection',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });



        }

        setValidated(true);
    };

    return (
        <>
            <SetupMenu />
            <ToastContainer />
            <div class="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                        <li className="breadcrumb-item"> <Link to="/Setup/SetupDashboard">Setup</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Section</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Add New Section</div>
                            </div>
                            <div className="col-md-6">
                                <div className="page-right-actions">
                                    <Link to="/Setup/ManageSection" className="button button-white button-icon">
                                        Manage Sections
                                        <i className="ri-list-check"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body m-md-4">
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <div className="row gy-3 align-items-center">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Select Session: </label>
                                                <select onChange={(e) => setSessionId(e.target.value)}
                                                    className="form-select" required>
                                                    <option value="">Please Select</option>
                                                    <SessionsDropDown />

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="">Select Wing: </label>
                                                <select onChange={(e) => setWingId(e.target.value)}
                                                    className="form-select" required>
                                                    <option value="">Please Select</option>
                                                    <WingsDropDown />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Select Class:</label>
                                                <select className="form-select" required name="SelectedClass" >
                                                    <option value="">Please Select</option>
                                                    {
                                                        SessionID != 0 && WingID != 0 &&
                                                        <ClassesDropDown SessionID={SessionID} WingID={WingID} />
                                                    }

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for=""> Section Code:</label>
                                                <input type="text" name="Section_Code" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="" className=""> Section Short Name:</label>
                                                <input type="text" name="Section_Short_Name" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="" className=""> Section Name:</label>
                                                <input type="text" name="Section_Name" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="" className=""> Strength Limit:</label>
                                                <input type="number" name="Section_Strength_Limit" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-md-3 text-end">
                                            <div className="form-group mt-md-4">
                                                {
                                                    loading ?
                                                        <Spinner animation="border" role="status"></Spinner>

                                                        :
                                                        <button type="submit" value="Save" className="button button-primary w-100 ">ADD SECTION</button>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddSection;
