import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import QRCode from "react-qr-code";
import StudentInfo from '../FeeSystem/StudentInfo';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import Page from "../General/Page";
import { calculateColumnTotal } from '../General/Utility';
import { scrollToTop } from '../Shared/ScrollOnTop';

const PrintZeroReport = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);
    const [sMonth, setsMonth] = useState(0);
    const [sYear, setsYear] = useState(0);

    const urlParams = new URLSearchParams(window.location.search);

    const selectedYear = urlParams.get('year');
    const selectedMonth = urlParams.get('month');
    const EmployeeTypeId = urlParams.get('EmployeeTypeId');
    const Deparment = urlParams.get('Deparment');

    
    useEffect(() => {
        document.title="DPS School Management System"

        setLoading(true);
        // Get the URL parameters
       

        // Retrieve individual parameter values
       
        setsMonth(selectedMonth);
        setsYear(selectedYear);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Fyear: selectedYear,
            SelectedMonth: selectedMonth,
            EmployeeTypeId: EmployeeTypeId
        };

        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/GetZeroReports',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        // console.log(data);

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);

    const getMonthName = (monthNumber) => {
        const monthNames = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
    
        return monthNames[monthNumber - 1];
      };

    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            let count = 1;
            let countbreak = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                {count++}
                            </td>
                            <td>{item.Code}</td>
                            <td>{item.Name}</td>
                            <td>{item.Selected_Designation?.Name}</td>
                            <td>{item.TotalWorkingDays}</td>
                            <td>{item.Deductions}</td>
                            <td>{item.MiscAllowance}</td>
                            <td>{item.TotalSalary}</td>
                        </tr>

                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    return (
        <>
            <Page orientation="portrat" size="a4">
                <table className="table table-sm table-bordered table-print">
                    <thead>
                        <tr>
                            <th colSpan={8}>
                                <PrintHeader title={`ZERO REPORT FOR THE MONTH OF ${getMonthName(sMonth) + ' ' + sYear} ${Deparment}`} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colspan="8">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                <>
                                    <tr>
                                        <th>Sr.#</th>
                                        <th>Code</th>
                                        <th>Staff Name </th>
                                        <th>Designation</th>
                                        <th>Days</th>
                                        <th>Deduction Details</th>
                                        <th>Arrears</th>
                                        <th>Net Salary</th>
                                    </tr>
                                    {renderLedger()}
                                    <tr>
                                        <td colspan={5}>Total Amount: </td>
                                        <th>{calculateColumnTotal(listOfLedger, 'Deductions')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'MiscAllowance')}</th>
                                        <th>{calculateColumnTotal(listOfLedger, 'TotalSalary')}</th>
                                    </tr>
                                </>

                        }

                    </tbody>
                </table>




                <div style={{ marginTop: "30px", width: "100%" }}>
                    <table className="table table-sm table-bordered table-print" style={{ borderCollapse: "collapse", border: "transparent" }}>
                        <tbody>
                            <tr className="my-5">
                                <th style={{ width: "33.33%", textAlign: "left", textDecoration: "underline" }}>
                                    Prepared By
                                </th>
                                <th style={{ width: "33.33%", textAlign: "center", textDecoration: "underline" }}>
                                    Burser
                                </th>
                                <th style={{ width: "33.33%", textAlign: "right", textDecoration: "underline" }}>
                                    Head of Wing / Deptt
                                </th>
                            </tr>
                            <tr className="my-5">
                               <br/><br/>
                            </tr>
                            <tr className="my-5">
                                <th style={{ width: "33.33%", textAlign: "left", textDecoration: "underline" }}>
                                    Admin Officer
                                </th>
                                <th style={{ width: "33.33%", textAlign: "center", textDecoration: "underline" }}>
                                   
                                </th>
                                <th style={{ width: "33.33%", textAlign: "right", textDecoration: "underline" }}>
                                   Principal
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </Page>
        </>
    );
}

export default PrintZeroReport;
