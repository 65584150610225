import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import PayrollMenu from './PayrollMenu';
import DesignationDropDown from '../Shared/DesignationDropDown';
import SalaryTypeDropDown from '../Shared/SalaryTypeDropDown';
import EmployeeTypeDropDown from "../Shared/EmployeeTypeDropDown";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from 'react-bootstrap/Dropdown';
import PayScaleBasic from "../Shared/PayScaleBasic";
import { Form } from "react-bootstrap";
import { scrollToTop } from '../Shared/ScrollOnTop';
import csvDownload from "json-to-csv-export";

function AddArrear(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [designationID, setDesignationId] = useState(0);
    const [empTypeID, seEmployeeTypeId] = useState(0);
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingAddArear, setloadingAddArear] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [showFilters, setShowFilters] = useState(true);
    const [RetireMentStatus, setRetireMentStatus] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loaddata, setLoaddata] = useState(false);

    // for filters
    const [Code, setCode] = useState("");
    const [DesignationID, setDesignationID] = useState(0);
    const [EmployeeTypeID, setEmployeeTypeID] = useState(0);
    const [Keywords, setKeywords] = useState("");

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {

        document.title = "Manage Employees";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            SchoolID: 1,
            IS_Resigned: RetireMentStatus,
            Code: Code,
            DesignationID: DesignationID,
            EmployeeTypeID: EmployeeTypeID,
            Keywords: Keywords,
        };

        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/SearchEmployees',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        console.log(data);

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                console.log(error);
            });

    }, [pageNo, pageSize, isUpdated, Code, DesignationID, EmployeeTypeID]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const handleSubmitForCvs = (event) => {
        console.log(event);

        setLoaddata(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: 10000,
            SchoolID: 1,
            IS_Resigned: RetireMentStatus,
            Code: Code,
            DesignationID: DesignationID,
            EmployeeTypeID: EmployeeTypeID,
            Keywords: Keywords,
        };

        // console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Payroll/SearchEmployees",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    if (response.data.totalRecords > 0) {


                        let data = response.data.ListofRecords;
                        let count = 0;
                        const listOfLedgerNew = data.map(({ ...rest }) => ({
                            Serial_No: count++ + pageSize * (pageNo - 1),
                            ID: rest.ID,
                            Name: rest.Name,
                            Father_Name: rest.Father_Name,
                            Mobile_No: `=T("${rest.Mobile_No}")`,
                            Account_No: `=T("${rest.Account_No}")`,
                            CNIC_NO: `=T("${rest.CNIC_NO}")`,
                            Basic_Pay_Scale: rest.Basic_Pay_Scale,
                            DOB: moment(rest.Date_Of_Birth).format("DD/MM/YYYY"),
                            DOJ: moment(rest.Date_Of_Joining).format("DD/MM/YYYY"),
                            Home_Address: rest.Home_Address,
                            Marital_Status: rest.Marital_Status,
                            Qualification: rest.Qualification,
                        }));


                        const dataToConvert = {
                            data: listOfLedgerNew,
                            filename: "Add_Arrears/Deductions",
                            delimiter: ",",
                            headers: [
                                "Serial_No",
                                "ID",
                                "Name",
                                "Father_Name",
                                "Mobile_No",
                                "Account_No",
                                "CNIC",
                                "Basic_Pay_Scale",
                                "DOB",
                                "DOJ",
                                "Home_Address",
                                "Marital_Status",
                                "Qualification"
                            ],
                        };
                        csvDownload(dataToConvert);

                        setLoaddata(false);
                    } else if (response.data.totalRecords == 0) {
                        toast.error("There is no data available for download", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setLoaddata(false);
                    }
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoaddata(false);
                }
            })
            .catch(function (error) {
                toast.error("Network Error..", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoaddata(false);
            });
    };

    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <div className="form-check">
                                    <input key={item.ID} data-type-id={item.ID} className="form-check-input checkStudent" type="checkbox" value={item.ID} />
                                </div>
                            </td>
                            <td>{item.ID}</td>
                            <td>{item.Code}</td>
                            <td>{item.Name}</td>
                            <td>{item.Father_Name}</td>
                            <td>
                                {moment(item.Date_Of_Birth).format('DD/MM/YYYY')}
                            </td>
                            <td>{moment(item.Date_Of_Joining).format('DD/MM/YYYY')}</td>
                            <td>{item.Mobile_No}</td>
                            <td>{item.Selected_Designation.Name}</td>
                            <td>

                                <div class="dropdown dropdown-table-actions">
                                    <a href="/ExaminationSystem/ManageExaminations" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" class="">
                                        <i class="ri-more-fill">
                                        </i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                        <li><NavLink to={'/PayRoll/EditEmployee/' + item.ID} className="dropdown-item" ><i className="ri-edit-line"></i>Edit</NavLink></li>

                                        <li>
                                            <NavLink target="_blank" to={'/PayRoll/PaySlip/?ids=' + item.ID} className="dropdown-item" ><i className="ri-profile-line"></i>Pay Slip</NavLink>
                                        </li>
                                        <li> <NavLink to={'/PayRoll/EmployeeLedger/' + item.ID} className="dropdown-item" ><i className="ri-file-list-3-line"></i>Employee Ledger</NavLink></li>
                                        <li>
                                            <NavLink to={'/PayRoll/ManageSalary/' + item.ID} className="dropdown-item" ><i className="ri-bank-card-line"></i>Manage Salary</NavLink>
                                        </li>
                                        <li><NavLink to={'/Payroll/changeStatus/' + item.ID} className="dropdown-item" ><i className="ri-settings-2-line"></i>Change Status</NavLink></li>
                                        <li><NavLink to={'/Payroll/AddLeave/' + item.ID} className="dropdown-item" ><i className="ri-hospital-line"></i>Add Leave</NavLink></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="10"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    const handleFilters = (event) => {
        console.log("Handle Fitlers clicked");
        event.preventDefault();
        event.stopPropagation();
        setCode(event.target.Code.value);
        setDesignationID(event.target.DesignationID.value);
        setEmployeeTypeID(event.target.EmployeeTypeID.value);
        setKeywords(event.target.Keywords.value);
    }


    const handleAddLeave = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        }
        else {


            setloadingAddArear(true);

            const inputsStudents = document.querySelectorAll('input.checkStudent');
            const valuesSelected = [];
            inputsStudents.forEach(input => {
                if (input.checked == true) {
                    const value = parseInt(input.value);
                    if (value > 0) {
                        valuesSelected.push(value);
                    }
                }

            });

            // console.log(valuesSelected);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                SelectedStaff: valuesSelected,
                SalaryTypeId: event.target.SalaryTypeId.value,
                Amount: event.target.Amount.value,
                Description: event.target.Description.value

            };

            // console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Payroll/AttachArrears',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            //console.log(data);

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setloadingAddArear(false);


                })
                .catch(function (error) {
                    console.log(error);
                });


        }

        setValidated(true);
    }

    return (
        <>
            <ToastContainer />
            <PayrollMenu />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Home/Index">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/PayRoll/PayRollDashboard">Payroll</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add Arrears / Deductions</li>
                                </ol>
                            </nav>
                            <div className="page-title">Add Arrears / Deductions</div>
                        </div>

                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/PayRoll/AddNewEmployee" className="button button-white button-icon">
                                    Add Employee
                                    <i className="ri-add-line"></i>
                                </Link>
                                <button
                                    onClick={() => {
                                        setShowFilters((prevIsActive) => !prevIsActive);
                                    }}
                                    className="button button-white button-icon">
                                    {showFilters ? 'Hide Filters' : 'Show Filters'}
                                    <i className="ri-filter-3-line"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`collapse ${showFilters ? 'show' : ''} `} id="collapseFilteres">
                <div className="page-content">
                    <form onSubmit={handleFilters}>
                        <div className="container">
                            <div className="card mb-4">
                                <div className="card-body p-md-4">
                                    <div className="row align-items-center">
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Gender</label>
                                                <select className="form-select" >
                                                    <option Value="">--All--</option>
                                                    <option Selected Value="1">Male</option>
                                                    <option Value="0">Female</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Designation</label>
                                                <select
                                                    className="form-select" name="DesignationID">
                                                    <option value="">Please Select</option>
                                                    <DesignationDropDown />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Department</label>
                                                <select name="EmployeeTypeID"
                                                    className="form-select">
                                                    <option value="">Please Select</option>
                                                    <EmployeeTypeDropDown />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Status</label>
                                                <select className="form-select">
                                                    <option Value="">--All--</option>
                                                    <option Value="false">Current</option>
                                                    <option Value="true">Retired</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Basic Pay Scale</label>
                                                <select className="form-select">
                                                    <option Value="">--All--</option>
                                                    <PayScaleBasic />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Staff Code</label>
                                                <input type="text" name="Code" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Staff ID</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Keywords</label>
                                                <input type="text" name="Keywords" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Order by</label>
                                                <select className="form-select">
                                                    <option Selected Value="Name">Alphabetic</option>
                                                    <option Value="Date_Of_Joining ASC">DOJ ASC</option>
                                                    <option Value="Date_Of_Joining DESC">DOJ DESC</option>
                                                    <option Value="Code Asc">Code Asc</option>
                                                    <option Value="Code Desc">Code Desc</option>

                                                </select>
                                            </div>
                                        </div>
                                        {loaddata ? (
                                            <div class="col-md-2 offset-md-5 text-center mt-4">
                                                <div>

                                                    <div>
                                                        <Spinner
                                                            animation="border"
                                                            role="status"
                                                        ></Spinner>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="col-md-2 offset-md-5 text-end ">
                                                <div className="form-group text-end mt-4">
                                                    <a
                                                        type="button"
                                                        onClick={handleSubmitForCvs}
                                                        className="print-icon"
                                                    >
                                                        <span>
                                                            <i class="ri-file-excel-2-line"></i>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-md-2 ">
                                            <div className="form-group text-end">
                                                <button type="submit" value="Search" className="button button-primary mt-4 w-100">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <table className="table table-theme">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>ID</th>
                                        <th>Code</th>
                                        <th>Employee Name</th>
                                        <th>Father Name</th>
                                        <th>DOB</th>
                                        <th>DOJ</th>
                                        <th>Mobile No.</th>
                                        <th>Designation</th>

                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                                        <th colspan="8">
                                            TotalRecords &nbsp; Records Found!
                                        </th>
                                    </tr> */}
                                    {
                                        loading ?
                                            <tr>
                                                <td className="text-center" colspan="10">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {renderLedger()}
                                            </>

                                    }
                                </tbody>
                            </table>
                            {
                                totalRecords > 10 &&
                                <div className="pagination-wrap">

                                    <div className="row align-items-center">
                                        <div className="col-md-2 form_default">
                                            <div className="d-flex align-items-center">
                                                <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                <div className="form-group">
                                                    <select className="form-select"
                                                        style={{ width: "100px" }}
                                                        onChange={(e) => {
                                                            setPageNo(1);
                                                            setPageSize(parseInt(e.target.value));


                                                        }}>
                                                        <option value="10" selected>10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <Pagination
                                            pageNo={pageNo}
                                            pageSize={pageSize}
                                            totalRecords={totalRecords}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                </div>

                            }


                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="card mt-2">
                        <Form noValidate validated={validated} onSubmit={handleAddLeave}>
                            <div className="card-body">
                                <div className="card-body p-md-4">
                                    <div className="row align-items-center">
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="">
                                                    Deduction Type:
                                                </label>
                                                <select className="form-select" name="SalaryTypeId" required="">
                                                    <option Value="">Please Select</option>
                                                    <SalaryTypeDropDown />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="">Amount to Add/Deduct:</label>
                                                <input type="text" className="form-control" name="Amount" required="" />
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="">Descriptions:</label>
                                                <input type="text" className="form-control" name="Description" required="" />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                {
                                                    loadingAddArear ?
                                                        <Spinner animation="border" role="status"></Spinner>

                                                        :


                                                        <input type="submit" value="Add Leave" className="w-100 button button-primary" />


                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddArrear;