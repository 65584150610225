import React from 'react';
import { Link } from "react-router-dom";
import PayrollMenu from './PayrollMenu.js';
import SalaryTypeDropDown from '../Shared/SalaryTypeDropDown.js';
import { scrollToTop } from '../Shared/ScrollOnTop';

const IncreaseDecreaseSalary = () => {
    return (
        <>
            <PayrollMenu />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/PayRoll/PayRollDashboard">Payroll</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Increase or Decrease Salaries</li>
                                </ol>
                            </nav>
                            <div className="page-title">Increase or Decrease Salaries</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/PayRoll/ManageDesignations" className="button button-white button-icon">
                                    Manage Designations
                                    <i className="ri-list-check"></i>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="ng-scope">
                                <div className="card mb-4">
                                    <div className="card-body card-form-wrap">
                                        <div className="form-wrap">
                                            <div className="form-legend" style={{ left: "64px" }}>Option 1</div>
                                            <div className="row align-items-center">
                                                <div className="col-md-12">
                                                    <h5 style={{ fontSize: ".9rem" }}>Increase In Salary Type (%) :</h5>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Select Type</label>
                                                        <select className="form-select" name="statustype">
                                                            <option value="" disabled="" className="" selected="selected">Please Select</option>
                                                            {
                                                                <SalaryTypeDropDown />
                                                            }

                                                        </select>
                                                        {/* <select className="form-select" required="">
                                                            <option value="" disabled="" className="" selected="selected">Please Select</option>
                                                            <option label="Basic Salary " value="object:10">Basic Salary </option>
                                                            <option label="House Rent" value="object:11">House Rent</option>
                                                            <option label="15% 01-07-2011" value="object:12">15% 01-07-2011</option>
                                                            <option label="Tax " value="object:13">Tax </option>
                                                            <option label="EOBI" value="object:14">EOBI</option>
                                                            <option label="SWF" value="object:15">SWF</option>
                                                            <option label="Leave Deduction" value="object:16">Leave Deduction</option>
                                                            <option label="Misc Allowances" value="object:17">Misc Allowances</option>
                                                            <option label="10% W.E.F 01-07-12" value="object:18">10% W.E.F 01-07-12</option>
                                                            <option label="10% W.E.F 01-07-13" value="object:19">10% W.E.F 01-07-13</option>
                                                            <option label="Arrears" value="object:20">Arrears</option>
                                                            <option label="10% W.E.F 01-07-14" value="object:21">10% W.E.F 01-07-14</option>
                                                            <option label="W.E.F 01-07-15" value="object:22">W.E.F 01-07-15</option>
                                                            <option label="W.E.F 01-07-17" value="object:23">W.E.F 01-07-17</option>
                                                            <option label="W.E.F 01-07-18" value="object:24">W.E.F 01-07-18</option>
                                                            <option label="W.E.F 01-07-19" value="object:25">W.E.F 01-07-19</option>
                                                            <option label="Misc Deductions" value="object:26">Misc Deductions</option>
                                                            <option label="15% 01-07-2021" value="object:27">15% 01-07-2021</option>
                                                            <option label="W.E.F 01-07-22" value="object:28">W.E.F 01-07-22</option>
                                                        </select> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Amount</label>
                                                        <input type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 text-end">
                                                    <div className="form-group">
                                                        <input type="button" value="Save" className="button button-primary" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="ng-scope">
                                                <div className="form-wrap">
                                                    <div className="form-legend" style={{ left: "64px" }}>Option 2</div>
                                                    <h5 style={{ fontSize: ".9rem" }}>Increase In Salary Type (Amount)</h5>
                                                    <div className="row align-items-center">
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Select Type</label>
                                                                <select className="form-select" name="statustype">
                                                                    <option value="" disabled="" className="" selected="selected">Please Select</option>
                                                                    {
                                                                        <SalaryTypeDropDown />
                                                                    }

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Grades</label>
                                                                <select ng-model="Grades" className="form-select" required="">
                                                                    <option value="AllGrades">AllGrades</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Amount</label>
                                                                <input type="text" ng-model="amount" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 text-end">
                                                            <div className="form-group mt-3">
                                                                <input type="button" value="Save" className="button button-primary" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="form-wrap">
                                                <div className="form-legend" >Option 3</div>
                                                <h5 style={{ fontSize: ".9rem" }}>Add New Column in Salary Type to All Staff</h5>
                                                <div className="row align-items-center">
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label>Select Type</label>
                                                            <select className="form-select" name="statustype">
                                                                <option value="" disabled="" className="" selected="selected">Please Select</option>
                                                                {
                                                                    <SalaryTypeDropDown />
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label>Grades</label>
                                                            <select className="form-select" required="">
                                                                <option value="AllGrades">AllGrades</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label>Amount</label>
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="d-flex justify-content-between mt-3">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" value="%" id="flexRadioDefault3" name="5" />
                                                                <label className="form-check-label" for="flexRadioDefault3">
                                                                    Percentage (%)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" value="Value" id="flexRadioDefault4" name="6" />
                                                                <label className="form-check-label" for="flexRadioDefault4">
                                                                    Value
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label>Add As</label>
                                                            <select className="form-select" name="statustype">
                                                                <option value="" disabled="" className="" selected="selected">Please Select</option>
                                                                {
                                                                    <SalaryTypeDropDown />
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9 text-end">
                                                        <div className="form-group mt-3">
                                                            <input type="button" value="Save" className="button button-primary" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div className="form-wrap">
                                                    <div className="form-legend" style={{ left: "64px" }}>Option 4</div>
                                                    <h5 style={{ fontSize: ".9rem" }}>
                                                        Decrease Salary As Misc Deduction
                                                    </h5>
                                                    <div className="row align-items-center">
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Select Type</label>
                                                                <select ng-model="SalaryType" className="form-select" required="">
                                                                    <option value="Gross">Gross</option>
                                                                    <option value="Basic">Basic</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Grades</label>
                                                                <select ng-model="Grades" className="form-select" required="">
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <label>Amount</label>
                                                                <input type="number" ng-model="amount" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group mb-3">
                                                                <div className="d-flex justify-content-between mt-3">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" value="%" id="flexRadioDefault1" name="8" />
                                                                        <label className="form-check-label" for="flexRadioDefault1">
                                                                            Percentage (%)
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check">

                                                                        <input className="form-check-input" type="radio" value="Value" id="flexRadioDefault2" name="9" />
                                                                        <label className="form-check-label" for="flexRadioDefault2">
                                                                            Value
                                                                        </label>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 text-end">
                                                            <input type="button" value="Save" className="button button-primary" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default IncreaseDecreaseSalary;
