import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';

import StaffAtMenu from './StaffAtMenu';
import { NavLink } from 'react-router-dom';
import EmployeeTypeDropDown from "../Shared/EmployeeTypeDropDown";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import PrintHeader from '../General/PrintHeader';
import Page from "../General/Page";

import { RenderHTMLString } from "../General/Utility";

function PrintAttendanceSummary(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [htmlstring, setHtmlString] = useState("");


    useEffect(() => {
        document.title="DPS School Management System"

        const urlParams = new URLSearchParams(window.location.search);

        const employeetypeid = urlParams.get('employeetypeid');
        const month = urlParams.get('month');
        const year = urlParams.get('year');
      

        if(employeetypeid && month && year) {
            
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
MemberType: MemberType,
                Wing_Id: employeetypeid,
                SelectedMonth: month,
                FYear: year
            };

            //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'StaffAttendance/GetAttendanceSummary',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
    
            axios(api_config)
                .then(function (response) {
                    
                    if (response.data.status_code == 1) {
                        setHtmlString(response.data.attendenceTable);
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
    
                    setLoading(false);
    
    
                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
    
        }
        

    }, []);


    return (
        <>
             <Page orientation="landscape" size="legal">
                <PrintHeader title="Attendance Summary" />
                <div className="table-reset table-attendence">{RenderHTMLString(htmlstring)}</div>
            </Page>
        </>
    );
}

export default PrintAttendanceSummary;