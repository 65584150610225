import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import QRCode from "react-qr-code";
import StudentInfo from '../FeeSystem/StudentInfo';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from '../General/Utility';
import { scrollToTop } from '../Shared/ScrollOnTop';

const DefaultersListReport = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [selectedYear, setselectedYear] = useState("");
    const [selectedMonth, setselectedMonth] = useState("");

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [TotalAmount, setTotalAmount] = useState(0);



    useEffect(() => {
        document.title="DPS School Management System"

        setLoading(true);
        // Get the URL parameters
        const urlParams = new URLSearchParams(window.location.search);

        // Retrieve individual parameter values
        const ReportMonth = urlParams.get('ReportMonth');
        const ReportYear = urlParams.get('ReportYear');
        const StartFromMonth = urlParams.get('StartFromMonth');
        const StartFromYear = urlParams.get('StartFromYear');
        const OrderBy = urlParams.get('OrderBy');
        const SectionID = urlParams.get('SectionID');
        const ClassId = urlParams.get('ClassId');
        const WingId = urlParams.get('WingId');
        const SessioinId = urlParams.get('SessioinId');


        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            Wing_Id: WingId,
            Class_Id: ClassId,
            Section_Id: SectionID,
            ReportYear: ReportYear,
            ReportMonth: ReportMonth,
            StartFromYear: StartFromYear,
            StartFromMonth: StartFromMonth,
            OrderBy: OrderBy,
        };

        console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Reports/GetDefaulterList',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
                setTotalAmount(response.data.TotalAmount);

            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);

    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            let count = 1;
            let countbreak = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index} className={countbreak++ == 35 ? 'page-break' : ''}>
                            <td>{item.Serial_No}</td>
                            <td>{item.Registration_No}</td>
                            <td>{item.Student_Name}</td>
                            <td>{item.Father_Name}</td>
                            <td>{item.Contact_Mobile}</td>
                            <td>{item.Class_Name}</td>
                            <td>{item.Section_Name}</td>
                            <td>{item.Current_Balance}</td>
                        </tr>

                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="20"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    return (
        <>
            <Page orientation="portrait" size="a4">
                <table className="table table-sm table-bordered table-print">
                    <thead>
                        <tr>
                            <th colspan={20}>
                                <PrintHeader title="List of Defaulter Students:" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colspan="8">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                <>
                                    <tr>
                                        <th className="text-end" colSpan={8}>Total Amount: {calculateColumnTotal(listOfLedger, 'Current_Balance')}</th>

                                    </tr>
                                    <tr>
                                        <th>
                                            Sr.#
                                        </th>
                                        <th>
                                            Code #
                                        </th>
                                        <th>
                                            Student's Name
                                        </th>
                                        <th >
                                            Father's Name
                                        </th>
                                        <th>
                                            Contact #.
                                        </th>
                                        <th>
                                            Class Name
                                        </th>
                                        <th>
                                            Section Name
                                        </th>
                                        <th>
                                            Amount
                                        </th>
                                    </tr>
                                    {renderLedger()}

                                </>

                        }

                    </tbody>
                </table>
            </Page>
        </>
    );
}

export default DefaultersListReport;
