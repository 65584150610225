import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import QRCode from "react-qr-code";
import StudentInfo from "../FeeSystem/StudentInfo";
import moment from "moment";
import PrintHeader from "../General/PrintHeader";
import Page from "../General/Page";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
const PrintStockDetail = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState({});
  const [detail, setdetail] = useState({});

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(2000);
  const [totalRecords, setTotalRecords] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);

  const [stockId, setstockId] = useState("");
  const [stockBalance, setstockBalance] = useState("");

  useEffect(() => {
    document.title = "DPS School Management System";
    setLoading(true);
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const ID = urlParams.get("id");
    const balance = urlParams.get("balance");
    // setstockId(ID)
    // setstockBalance(balance)

    // Retrieve individual parameter values

    console.log(stockId);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: ID,
    };

    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "/Stock/GetItemDetailByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    console.log(data);
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        setLedger(response.data.ItemName);
        setdetail(response.data.ItemDetail);

        console.log(listOfLedger);
        setLoading(false);
        // setTotalRecords(response.data.totalRecords);
        // setTotalAmount(response.data.TotalAmount);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const renderLedger = () => {
    if (listOfLedger != null) {
      return (
        <tr>
          <td>{listOfLedger.ID}</td>
          <td>
            {listOfLedger.Item_Balance}
            <br />
          </td>
          <td>{moment(listOfLedger.Updated_Date).format("DD/MM/YYYY")}</td>
          <td>{listOfLedger.Description}</td>
          <td>{listOfLedger.Custodian}</td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td colspan="20">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="portrait" size="a4">
        <table className="table table-sm table-bordered table-print">
          <thead>
            <tr>
              <td colspan={20}>
                {listOfLedger.Item_In !== 0 ? (
                  <PrintHeader title={`Stock Issuance Note`} />
                ) : (
                  <PrintHeader title={`Stock Receipt Note`} />
                )}

                <table className="table table-sm table-bordered table-print" style={{ borderColor: "transparent", margin: "0" }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "end", width: "80%" }}>
                        Stock Code:
                      </td>
                      <td style={{ textAlign: "start", width: "20%" }}>
                        <b>{detail.Stock_Code}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "end", width: "80%" }}>
                        Register Type:
                      </td>
                      <td style={{ textAlign: "start", width: "20%" }}>
                        <b>{detail.Register_Type}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "end", width: "80%" }}>
                        Register Reference:
                      </td>
                      <td style={{ textAlign: "start", width: "20%" }}>
                        <b>{detail.Register_Ref}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-sm table-bordered table-print" style={{ borderColor: "transparent", margin: "0" }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "start", width: "20%" }}>
                        Item Name:
                      </td>
                      <td style={{ textAlign: "start", width: "80%", fontSize: "17px" }}>
                        <b>{detail.Item_Name}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "start", width: "20%" }}>
                        Description:
                      </td>
                      <td style={{ textAlign: "start", width: "80%" }}>
                        <b>{detail.Item_Description}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "start", width: "20%" }}>
                        Main Custodian:
                      </td>
                      <td style={{ textAlign: "start", width: "80%" }}>
                        {detail.Main_Custodian}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <h4 style={{ borderBottom: "1pt solid black", marginTop: "13pt", marginBottom: "15pt",fontSize:"13pt" }}>Issuance Details</h4>
                </div>
                <table className="table table-sm table-bordered table-print" style={{ borderColor: "transparent", margin: "0", fontWeight: "bold" }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "start", width: "20%", fontSize: "13pt" }}>
                        Dated:
                      </td>
                      <td style={{ textAlign: "start", width: "80%", fontSize: "13pt" }}>
                        {moment(listOfLedger.Updated_Date).format("DD/MM/YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "start", width: "20%", fontSize: "13pt" }}>
                        Record ID:
                      </td>
                      <td style={{ textAlign: "start", width: "80%", fontSize: "13pt" }}>
                        {listOfLedger.ID}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "start", width: "20%", fontSize: "13pt" }}>
                        Quantity:
                      </td>
                      <td style={{ textAlign: "start", width: "80%", fontSize: "13pt" }}>
                        {listOfLedger.Item_In !== 0 ? (
                          <>{listOfLedger.Item_In}</>
                        ) : (
                          <>{listOfLedger.Item_Out}</>
                        )}
                      </td>
                    </tr>

                  </tbody>
                </table>
                <table className="table table-sm table-bordered table-print" style={{ borderColor: "transparent", margin: "0" }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "start", width: "10%" }}>
                        Description:
                      </td>
                      <td style={{ textAlign: "start", width: "90%" }}>
                        {listOfLedger.Description}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "start", width: "10%" }}>
                        Custodian:
                      </td>
                      <td style={{ textAlign: "start", width: "90%" }}>
                        {listOfLedger.Custodian}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "start", width: "20%"}}>
                        Remaining Balance:
                      </td>
                      <td style={{ textAlign: "start", width: "80%"}}>
                        {listOfLedger.Item_Balance}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-sm table-bordered table-print" style={{ marginTop: "50pt", borderColor: "transparent" }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "end" }}>
                        stock Manager
                        <br />
                        ________________________________
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-sm table-bordered table-print" style={{ borderColor: "transparent" }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "start" }}>
                        Principal
                        <br />
                        ________________________________
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </thead>
        </table >

      </Page >
    </>
  );
};

export default PrintStockDetail;
