import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import PayrollMenu from './PayrollMenu.js';
import YearsDropDown from '../Shared/YearsDropDown.js';
import MonthsDropDown from '../Shared/MonthsDropDown.js';
import moment from "moment";
import { scrollToTop } from '../Shared/ScrollOnTop';

const CreateSalary = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [SelectedMonth, setSelectedMonth] = useState(moment().format("M"));
    const [SelectedYear, setSelectedYear] = useState(moment().format("YYYY"));
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingReCreate, setloadingReCreate] = useState(false);
    const [validated, setValidated] = useState(false);

    const CreateSalaryHandler = (event) => {
        setLoading(true);


        event.preventDefault();
        event.stopPropagation();



        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                SelectedMonth: SelectedMonth,
                FYear: SelectedYear,
            };

            console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Payroll/CreateSalary',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {

                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setValidated(true);

    }

    const ReCreateSalaryHandler = () => {
        setloadingReCreate(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            SelectedMonth: SelectedMonth,
            FYear: SelectedYear,
        };

        // console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/RecreateSalary',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {

                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                setloadingReCreate(false);


            })
            .catch(function (error) {

                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    return (
        <>
            <PayrollMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                    <li className="breadcrumb-item"><NavLink to="/PayRoll/PayRollDashboard">PayRoll</NavLink></li>
                                    <li className="breadcrumb-item active" aria-current="page">Create Salary</li>
                                </ol>
                            </nav>
                            <div className="page-title">Create Salary</div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body card-form-wrap">
                                    <div className="form-wrap">
                                        <form onSubmit={CreateSalaryHandler}>
                                            <div className="form-legend" style={{ left: "64px" }}>Option 1</div>
                                            <div className="row align-items-center">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Select Month</label>
                                                        <select className="form-select"
                                                            required=""
                                                            value={SelectedMonth}
                                                            onChange={(e) => setSelectedMonth(e.target.value)}>
                                                            <MonthsDropDown />
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Select Year</label>
                                                        <select className="form-select"
                                                            value={SelectedYear}
                                                            onChange={(e) => setSelectedYear(e.target.value)}
                                                            required="">
                                                            <YearsDropDown />
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Year</label>
                                                        <select className="form-select" onChange={(e) => setSelectedYear(e.target.value)} required=""
                                                            value={SelectedYear}>
                                                            <option value="" disabled="" selected="selected">Please Select</option>
                                                            <YearsDropDown />
                                                        </select>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-6 text-end">
                                                    <div className="form-group mt-2">
                                                        {
                                                            loading ?
                                                                <Spinner animation="border" role="status"></Spinner>
                                                                :
                                                                <input type="submit" value="Create Salary" className="button button-primary" />
                                                        }


                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="form-wrap">
                                        <div className="form-legend" style={{ left: "64px" }}>Option 2</div>
                                        <div className="row align-items-center">
                                            <div className="col-md-2 text-end">
                                                <div className="form-group mb-3">
                                                    {
                                                        loadingReCreate ?
                                                            <Spinner animation="border" role="status"></Spinner>
                                                            :
                                                            <input type="button"
                                                                value="Re-Create Salary"
                                                                className="button button-primary" onClick={ReCreateSalaryHandler} />
                                                    }

                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <p className="m-0">

                                                    <b>

                                                        if the salary has already been created, it will delete existing and recreate the salary.
                                                    </b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateSalary;
