import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import RightsCheck from '../General/RightsCheck';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { scrollToTop } from '../Shared/ScrollOnTop';
import Main from '../Main';

function PayrollMenu(props) {

    return (
        <>
            <RightsCheck />
       
            <nav className={`navbar navbar-expand-lg navbar-module MenuSticky nav`} style={{zIndex:"4"}}>
                <div className="container-fluid">
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item">
                                <NavDropdown title="Employee">
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/ManageEmployee">Manage Employee</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/ManageEmployeeTypes">Employee Types</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/ManageDesignations">Designation</NavLink>
                                </NavDropdown>
                            </li>
                            <li className="nav-item">
                                <NavDropdown title="Salary">
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/ManageSalaryTypes">Salary Types</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/BankSalalryList">Bank Salary List</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/CreateSalary">Create Salary</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/IncreaseDecreaseSalary">Increase/Decrease Salary</NavLink>
                                </NavDropdown>
                            </li>
                            <li className="nav-item">
                                <NavDropdown title="Reports">
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/EmployeeReports">View Reports</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/ZeroReports">Zero Reports</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/SummarySheet">Summary Sheet</NavLink>
                                </NavDropdown>
                            </li>
                            <li className="nav-item">
                                <NavDropdown title="Arrear / Leave">
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/AddArrear">Arrear / Leave Deduction</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/ManageLeaves">Manage Leaves</NavLink>
                                </NavDropdown>
                            </li>
                            <li className="nav-item">
                                <NavDropdown title="Deductions">
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/AddLoanDeduction">Add Loan Deduction</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/ManageLoanDeduction">Manage Loan Deductions</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/AddOtherDeduction">Add Other Deduction</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/PayRoll/ManageOtherLoanDeductions">Manage Other Deductions</NavLink>
                                </NavDropdown>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/PayRoll/PaySlips">Pay Slips</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default PayrollMenu;