import React from "react";


const Page = ({ size, orientation, children }) => {
  // Set the default size and orientation to A4 and portrait
  const pageSize = size || "A4";
  const pageOrientation = orientation || "portrait";

  // Generate the CSS class names based on size and orientation props
  const pageClassName = `page  ${pageSize.toLowerCase()} ${pageOrientation.toLowerCase()}`;
  const contentClassName = `content ${pageSize.toLowerCase()}`;

  return (
    <div className={pageClassName}>
      <div className={contentClassName}>{children}</div>
    </div>
  );
};

export default Page;
