import React, { useState, useEffect } from "react";
import TeacherMenu from './TeacherMenu';
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import { scrollToTop } from '../Shared/ScrollOnTop';

function Change_Password(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [password1, setPassword1] = useState(null);
    const [password2, setPassword2] = useState(null);

    let navigate = useNavigate();

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        if (password1 != password2) {

            toast.error('Both Password doesn\'t match ', {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }


        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                OldPassword: event.target.txtOldPassword.value,
                NewPassword: event.target.txtNewPassword.value,
            };

            // console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/ChangePassword',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);

                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setValidated(true);
    };

    return (
        <>
            <TeacherMenu />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/teacher/Dashboard">Home</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Change Password</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Change Password</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-md-4">
                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                            <div className="row align-items-center">
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="">Old Password:</label>
                                                        <input type="password" className="form-control" required name="txtOldPassword" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="" className="">New Password</label>
                                                        <input type="password" className="form-control" required name="txtNewPassword" onChange={(e) => setPassword1(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="" className="">confirm Password</label>
                                                        <input type="password" className="form-control" required name="txtRePassword" onChange={(e) => setPassword2(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 text-end">
                                                    <div className="form-group">
                                                        {
                                                            loading ?
                                                                <Spinner animation="border" role="status"></Spinner>

                                                                :
                                                                <button type="submit" value="Change" className="button button-primary">Change</button>

                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
}

export default Change_Password;