import ReportsMenu from "./ReportsMenu";
import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import { toast, ToastContainer } from "react-toastify";
import { NavLink, Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";
import { RenderHTMLString } from "../General/Utility";
import { calculateColumnTotal, getMonthName } from "../General/Utility";
import { scrollToTop } from '../Shared/ScrollOnTop';

const StAttendenceSummary = () => {
  
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);
  const [loading, setLoading] = useState(false);
  const [StAttendence, setStAttendence] = useState();
  const [validated, setValidated] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [Wing, setWing] = useState("");
  const [attendenceString, setattendenceString] = useState("");
  const [Section, setSection] = useState("");
  const [Session, setSession] = useState("");
  const [Class, setClass] = useState("");
  const [SelectedMonth, setSelectedMonth] = useState(moment().format("M"));
  const [SelectedYear, setSelectedYear] = useState(moment().format("YYYY"));

  // for filters
  useEffect(() => {
    document.title = "New Students List";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Class_Id: Class,
      FYear: SelectedYear,
      SelectedMonth: SelectedMonth,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "StudentAttendance/GetAttendanceDetails",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data.attendenceTable);
        setattendenceString(response.data.attendenceTable);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [Class, Session, Wing, Section, SelectedMonth, SelectedYear]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setSelectedYear(event.target.SelectedYear.value);
      setSelectedMonth(event.target.SelectedMonth.value);
      setWing(event.target.Wing.value);
      setClass(event.target.Class.value);
      setSection(event.target.Section.value);
    }

    setValidated(true);
  };


  return (
    <>
      <ReportsMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/Reports/ReportsDashboard">Reports</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Attendence Summary
                  </li>
                </ol>
              </nav>
              <div className="page-title">Attendence Summary</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        <div className="page-content">
          <div className="container">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="card mb-2">
                <div className="card-body p-md-4">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="">
                          Select Session:
                        </label>
                        <select
                          className="form-select"
                          name="Session"
                          onChange={(e) => setSession(e.target.value)}
                        >
                          <option value="">---All---</option>
                          <SessionsDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label htmlFor="">Select Wing:</label>
                        <select
                          name="Wing"
                          className="form-select"
                          onChange={(e) => setWing(e.target.value)}
                        >
                          <option value="">---All---</option>
                          <WingsDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label htmlFor="">Select Class:</label>

                        <select
                          className="form-select"
                          required
                          name="Class"
                          onChange={(e) => setClass(e.target.value)}
                        >
                          <option value="">Please Select</option>
                          {Session !== 0 && Wing !== 0 && (
                            <ClassesDropDown
                              SessionID={Session}
                              WingID={Wing}
                            />
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label htmlFor="">Select Section:</label>
                        <select
                          className="form-select"
                          name="Section"
                          required
                          onChange={(e) => setSection(e.target.value)}
                        >
                          <option value="">Please Select</option>
                          {Class !== 0 && <SectionDropDown ClassID={Class} />}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="">Select Month:</label>
                        <select
                          name="SelectedMonth"
                          defaultValue={SelectedMonth}
                          onChange={(e) => setSelectedMonth(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Please Select</option>
                          <MonthsDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="">Select Year:</label>
                        <select
                          name="SelectedYear"
                          value={SelectedYear}
                          onChange={(e) => setSelectedYear(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Please Select</option>
                          <YearsDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2 offset-md-4 text-end">
                      <div className="form-group text-end">
                        <button
                          type="submit"
                          value="Search"
                          className="w-100 button button-primary mt-4"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className="card p-3 table-reset">
          {
            loading ?
              <Spinner animation="border" role="status"></Spinner>
              :

              <div className="table-responsive table-attendence">
                {RenderHTMLString(attendenceString)}
              </div>
          }
        </div>
      </div>
    </>
  );
};

export default StAttendenceSummary;
