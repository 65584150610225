import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import Page from "../General/Page";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { calculateColumnTotal } from "../General/Utility";
import { useLocation } from 'react-router-dom';
import { scrollToTop } from '../Shared/ScrollOnTop';

function PaySlipPrint(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [slip, setSlip] = useState([]);


    let params = useParams();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const selectedIds = searchParams.get('ids')?.split(',');

    const month = searchParams.get('month');
    const year = searchParams.get('year');


    useEffect(() => {


        //console.log(selectedIds);

        document.title = "Employee Pay Slip";
        setLoading(true);
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            SelectedStaff: selectedIds,
            FYear: year,
            SelectedMonth: month

        };

        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/GetMultiplePaySlip',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setSlip(response.data.ListofRecords);
                    //setListofSalary(response.data.ListofSalary);
                }
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);


    const getMonthName = (monthNumber) => {
        const monthNames = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
    
        return monthNames[monthNumber - 1];
      };


    return (
        slip.map((item, index) => {
            return (
                <>



                    <Page orientation="portrait" size="a4">


                        {
                            loading ?
                                'Loading..'
                                :
                                <>
                                
                                    <div style={{ padding: "20px  20px 10px 20px" }}>
                                    <PrintHeader title={`PAY SLIP FOR THE MONTH OF ${getMonthName(month) + ' ' + year} `} />
                                       
                                        <table className="table table-sm table-payslip" style={{ fontSize: "12px" }}>
                                            <thead>
                                                <tr>
                                                    <th colSpan={4}>
                                                    <h3 style={{ fontSize: "14px" }}>Personal Information:</h3>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "100px" }}>Employe ID:</td>
                                                    <td>{item.StaffID}</td>
                                                    <td>Type/Depart</td>
                                                    <td>{item.Department}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "100px" }}>Full Name:</td>
                                                    <td>{item.Name}</td>
                                                    <td>Designation</td>
                                                    <td>{item.Designation}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "100px" }}>Father Name:</td>
                                                    <td>{item.Father_Name}</td>
                                                    <td>Pay Scale</td>
                                                    <td>{item.Payscale}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "100px" }}>Address:</td>
                                                    <td>{item.Home_Address}</td>
                                                    <td>Joining Date:</td>
                                                    <td>{moment(item.JoiningDate).format('DD/MM/YYYY')}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "100px" }}>A/C No.:</td>
                                                    <td>{item.Account_No}</td>
                                                    <td>CNIC No:</td>
                                                    <td>{item.CNIC_NO}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h3 style={{ fontSize: "14px", marginTop: "50px" }}>Salary Details:</h3>
                                        <table className="table table-sm table-payslip" style={{ fontSize: "12px" }}>
                                            {/* <thead>
                                                <tr>
                                                    <th colSpan={4}>
                                                        <PrintHeader title={`PAY SLIP FOR THE MONTH OF ${moment(item.PaySlipDate).format('MMM, YYYY')} `} />
                                                    </th>
                                                </tr>

                                            </thead> */}
                                            <tbody>
                                                <tr>
                                                    <td>Sr # </td>
                                                    <td>Salary Type / Description </td>
                                                    <td>Salary Due </td>
                                                    <td>Deducations  </td>
                                                </tr>
                                                {item.ListofSalary && item.ListofSalary.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.Serial_No}</td>
                                                        <td>{item.SalaryTypeName}</td>
                                                        <td>{item.Salary_Value == 0 ? '' : item.Salary_Value}</td>
                                                        <td>{item.Deduction == 0 ? '' : item.Deduction}</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td colSpan={2}>Gross Pay</td>
                                                    <td>{item.ListofSalary ? calculateColumnTotal(item.ListofSalary, 'Salary_Value') : ''}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>Deducations</td>
                                                    <td></td>
                                                    <td>{item.ListofSalary ? calculateColumnTotal(item.ListofSalary, 'Deduction') : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>Net Pay Rs. </td>
                                                    <td>{item.ListofSalary ? calculateColumnTotal(item.ListofSalary, 'Salary_Value') + calculateColumnTotal(item.ListofSalary, 'Deduction') : ''}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div style={{ margin: "60px 0" }}>
                                            <p style={{ textAlign: "right", fontSize: "13px" }}><strong>Muhammad Younas</strong> <br />Accounts Officer </p>

                                        </div>
                                        <p style={{ fontSize: "13px" }}><strong>Note:</strong> <br />This payslip has been generated by the system and do not require any signature.  </p>
                                    </div>
                                </>
                        }

                    </Page>


                </>
            );
        })
    )

}

export default PaySlipPrint;