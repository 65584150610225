import ReportsMenu from './ReportsMenu';
import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import { toast, ToastContainer } from 'react-toastify';
import { NavLink, Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from 'react-bootstrap/Dropdown';
import { Form, PageItem } from 'react-bootstrap';
import MonthsDropDown from '../Shared/MonthsDropDown';
import YearsDropDown from '../Shared/YearsDropDown';
import { scrollToTop } from '../Shared/ScrollOnTop';

const DefaultersList = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

    const [showFilters, setShowFilters] = useState(true);

    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [validated, setValidated] = useState(false);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [TotalAmount, setTotalAmount] = useState(0);


    const [WingId, setWingId] = useState(0);
    const [SessionId, setSessioinId] = useState(localStorage.getItem("DefaultSession"));
    const [ClassId, setClassId] = useState(0);
    const [SectionID, setSectionID] = useState(0);
    const [isUpdated, setIsUpdated] = useState(false);
    const [OrderBy, setOrderBy] = useState("Wing_Name,Class_Name,Section_Name");
    const [StartFromYear, setStartFromYear] = useState(2023);
    const [StartFromMonth, setStartFromMonth] = useState(3);
    const [ReportYear, setReportYear] = useState(2023);
    const [ReportMonth, setReportMonth] = useState(5);


    useEffect(() => {

        document.title = "Defaulters List";

        setIsUpdated(false);
        setLoading(true);

        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            Wing_Id: WingId,
            Class_Id: ClassId,
            Section_Id: SectionID,
            ReportYear: ReportYear,
            ReportMonth: ReportMonth,
            StartFromYear: StartFromYear,
            StartFromMonth: StartFromMonth,
            OrderBy: OrderBy,
        };

        console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Reports/GetDefaulterList',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
                setTotalAmount(response.data.TotalAmount);

            })
            .catch(function (error) {
                console.log(error);
            });

    }, [pageNo, pageSize, WingId, ClassId, SectionID, SessionId, OrderBy]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{count++ + (pageSize * (pageNo - 1))}</td>
                            <td>{item.Student_ID}</td>
                            <td>{item.Registration_No}</td>
                            <td>{item.Student_Name}</td>
                            <td>{item.Father_Name}</td>
                            <td>{item.Current_Balance}</td>
                            <td>{item.Current_Balance_Type}</td>
                            <td>{item.Wing_Name}</td>
                            <td>{item.Class_Name}</td>
                            <td>{item.Section_Name}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="10"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    return (
        <>
            <ReportsMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/Reports/ReportsDashboard">Reports</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Defaulters List</li>
                                </ol>
                            </nav>
                            <div className="page-title">Defaulters Student List</div>
                        </div>

                    </div>
                </div>
            </div>
            <div className={`collapse ${showFilters ? 'show' : ''} `} id="collapseFilteres">
                {/* <Form noValidate validated={validated} onSubmit={handleSubmit}> */}
                <div className="page-content">
                    <div className="container">
                        <div className="card mb-2">
                            <div className="card-body p-md-4">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group mb-3">
                                            <label for="" className="">Select Session:</label>
                                            <select className="form-select" onChange={(e) => setSessioinId(e.target.value)} name="SessionID">
                                                <option value="0">---All---</option>
                                                <SessionsDropDown />
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group mb-3">
                                            <label>Select Wing:</label>
                                            <select name="Wing_Id" onChange={(e) => setWingId(e.target.value)} className="form-select">
                                                <option value="0">---All---</option>
                                                <WingsDropDown />
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group mb-3">
                                            <label for="" className="">Select Class:</label>
                                            <select className="form-select" onChange={(e) => setClassId(e.target.value)} name="Class_ID">
                                                <option value="">Please Select</option>
                                                {
                                                    SessionId != 0 && WingId != 0 &&
                                                    <ClassesDropDown SessionID={SessionId} WingID={WingId} />
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group mb-3">
                                            <label for="" className="">Select Section:</label>
                                            <select className="form-select" onChange={(e) => setSectionID(e.target.value)} name="Section_Id">
                                                <option value="0">Please Select</option>
                                                {
                                                    ClassId !== 0 &&
                                                    <SectionDropDown ClassID={ClassId} />
                                                }

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group mb-3">
                                            <label for="" className="">Report Month:</label>
                                            <select name="OrderBy" onChange={(e) => setReportMonth(e.target.value)} className="form-select">
                                                <option value="" disabled="">Please Select</option>
                                                <MonthsDropDown />
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group mb-3">
                                            <label for="" className="">Report Year:</label>
                                            <select name="OrderBy" onChange={(e) => setReportYear(e.target.value)} className="form-select">
                                                <option value="" disabled="">Please Select</option>
                                                <YearsDropDown />
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group mb-3">
                                            <label for="" className="">Start From:</label>
                                            <select name="OrderBy" onChange={(e) => setStartFromMonth(e.target.value)} className="form-select">
                                                <option value="" disabled="">Please Select</option>
                                                <MonthsDropDown />
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group mb-3">
                                            <label for="" className="">Start From:</label>
                                            <select name="OrderBy" onChange={(e) => setStartFromYear(e.target.value)} className="form-select">
                                                <option value="" disabled="" >Please Select</option>
                                                <YearsDropDown />
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label for="" className="">Order by:</label>
                                            <select name="OrderBy" onChange={(e) => setOrderBy(e.target.value)} className="form-select">
                                                <option Selected Value="Student_Name">Student Name</option>
                                                <option Value="Registration_No">Registration No</option>
                                                <option Value="Wing_Name">Wing Name</option>
                                                <option Value="Class_Name">Class</option>
                                                <option Value="Wing_Name,Class_Name,Section_Name">Wing Name,Class Name,Section</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2 offset-md-7 text-end">
                                        <div className="form-group text-end">
                                            <button type="submit" value="Search" className="w-100 button button-primary mt-4">Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <table className="table table-theme">
                                <thead>
                                    <tr>
                                        <th colSpan={5}>
                                            Total Records Found: {totalRecords}
                                        </th>
                                        <th colSpan={5} className='text-end'>
                                            Total Amount:{TotalAmount}
                                        </th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th>Sr.#</th>
                                        <th>Student ID</th>
                                        <th>Registration No.</th>
                                        <th>Student Name</th>
                                        <th>Father Name</th>
                                        <th>Balance</th>
                                        <th>Type</th>
                                        <th>Wing Name</th>
                                        <th>Class</th>
                                        <th>Section</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td className="text-center" colspan="10">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {renderLedger()}
                                            </>

                                    }

                                </tbody>
                            </table>
                            {
                                totalRecords > 10 &&
                                <div className="pagination-wrap">

                                    <div className="row align-items-center">
                                        <div className="col-md-2 form_default">
                                            <div className="d-flex align-items-center">
                                                <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                <div className="form-group">
                                                    <select className="form-select"
                                                        style={{ width: "100px" }}
                                                        onChange={(e) => {
                                                            setPageNo(1);
                                                            setPageSize(parseInt(e.target.value));

                                                        }}>
                                                        <option value="10" selected>10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <Pagination
                                            pageNo={pageNo}
                                            pageSize={pageSize}
                                            totalRecords={totalRecords}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                </div>

                            }

                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="card mt-2">
                        <div className="card-body p-md-4">
                            <div className="row">
                                <div className="col-md-12 text-end">
                                    <NavLink
                                        className="button button-primary"
                                        to={`/Reports/DefaultersListReport?ReportMonth=${ReportMonth}&ReportYear=${ReportYear}&StartFromMonth=${StartFromMonth}&StartFromYear=${StartFromYear}&OrderBy=${OrderBy}&SectionID=${SectionID}&ClassId=${ClassId}&WingId=${WingId}&SessioinId=${SessionId}`}
                                        target="_blank"
                                    >Print Report</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DefaultersList;
