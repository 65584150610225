import React from 'react';
import { Link } from "react-router-dom";
import Monthly_navbar from './Monthlytasks_navbar';
import arrears from '../../assets/images/dashboard_icon/arrears.svg';
import no_fee from '../../assets/images/dashboard_icon/no_fee.svg';
import { scrollToTop } from '../Shared/ScrollOnTop';

const MonthlyTasksDashboard = () => {
    return (
        <>
            <Monthly_navbar />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Monthly Task</li>
                                </ol>
                            </nav>
                            <div className="page-title">Monthly Task</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/MonthlyTasks/ManageMonth" className="button button-white button-icon">
                                    Manage Month
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div class="row gy-3 align-items-center">
                        <div class="col-md-12">
                            <div class="card m-card">
                                <div class="card-body p-2">
                                    <p class="text-center m-0"><b>Posting Details</b></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card m-card">
                                <div class="card-body text-center">
                                    <img src={arrears} class="pb-3" alt="" />
                                    <p class="m-2">Students With 500 Fine</p>
                                    <h4 class="m-2">123</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card m-card">
                                <div class="card-body text-center">
                                    <img src={arrears} class="pb-3" alt="" />
                                    <p class="m-2">Student With 50 Fine</p>
                                    <h4 class="m-2">233</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body text-center">
                                    <img src={no_fee} class="pb-3" alt="" />
                                    <p class="m-2">Unpaid in Last Month</p>
                                    <h4 class="m-2">100</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table table-theme table-condensed pb-md-0">
                                        <thead style={{ backgroundColor: "#F8F8F8" }}>
                                            <tr class=" mb-1">
                                                <th colspan="4" class="text-center">MANAGE STARTED MONTHS</th>
                                            </tr>
                                        </thead>
                                        <thead style={{ backgroundColor: "#F8F8F8" }}>
                                            <tr>
                                                <th>
                                                    Month ID
                                                </th>
                                                <th>
                                                    Month
                                                </th>

                                                <th>
                                                    Year
                                                </th>
                                                <th class="text-center">
                                                    Started On
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    1
                                                </td>
                                                <td>
                                                    2
                                                </td>
                                                <td>
                                                    2023
                                                </td>
                                                <td class="text-center">
                                                    02/02/2000
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MonthlyTasksDashboard;
