import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import StudentMenu from "./StudentMenu";
import { toast, ToastContainer } from "react-toastify";
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, PageItem } from "react-bootstrap";
import ShowStudent from "../Shared/ShowStudent";
import StudentCard from "../Shared/StudentCard";
import { scrollToTop } from "../Shared/ScrollOnTop";

function StudentLedger(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const [totalRecords, setTotalRecords] = useState(0);

  let params = useParams();
  const [StudentID, setStudentID] = useState(params.id);
  const [StartDate, setStartDate] = useState("2023-01-01");
  const [EndDate, setEndDate] = useState("2025-06-01");
  let totalDebit = 0;
  let totalCredit = 0;
  let navigate = useNavigate();

  useEffect(() => {
    document.title = "View Student Ledger";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      Student_ID: StudentID,
      StartDate: StartDate,
      EndDate: EndDate,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Student/GetStudentLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code === 1) {


          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        }
        else {
          setLedger(null);
          setTotalRecords(0);
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [pageNo, pageSize, isUpdated]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger!=null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        totalDebit += item.Debit;
        totalCredit += item.Credit;

        return (
          <>
            <tr key={index}>
              <td>{item.Transaction_ID}</td>
              <td>{moment(item.Transaction_Date).format("DD/MM/YYYY")}</td>
              <td>{item.Fee_Code}</td>
              <td>{item.Receipt_No}</td>
              <td>{item.Description}</td>
              <td>{item.Debit}</td>
              <td>{item.Credit}</td>
              {/* <td>{item.Balance}</td>
                            <td>{item.Balance_Type}</td> */}
              <td className="text-center">
                {/* <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                    <li><button className="dropdown-item" onClick={(e) => deleteStudentLedger(item.Transaction_ID)}><i className="ri-close-circle-fill"></i> Delete</button></li>
                                </ul> */}
                <ul
                  className="inline-action"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {console.log(UserID)}
                  <li >
                    <button
                      onClick={(e) => deleteStudentLedger(item.Transaction_ID)}
                      
                      className={`red ${UserID != 1 ? 'd-none' : ''}`}
                      
                    

                    >
                      <i className="ri-delete-bin-line"></i>
                    </button>
                  </li>
                </ul>
              </td>
            </tr>
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="8">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  const deleteStudentLedger = (ID) => {

    const confirm = window.confirm("Are you sure you want to proceed ?");
    if (confirm) {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        ID: ID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Student/DeleteStudentLedger",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          console.log(response.data);
          if (response.data.status_code == 1) {

            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });


            setIsUpdated(true);
          }
          // setIsUpdated(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <>
      <StudentMenu />
      <ToastContainer />

      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/student/studentdashboard">Students</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Student Ledger
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Student Ledger</div>
              </div>
              <div className="col-md-6">
                                <div className="page-right-actions">
                                    <NavLink to="/students/AdvanceSearch" className="button button-white">
                                        Back
                                       
                                    </NavLink>
                                  
                                </div>
                            </div>
            </div>
          </div>
        </div>
       

        {/* <StudentCard StudentID={StudentID} /> */}
        <ShowStudent StudentID={StudentID} />

        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th colspan="8" className="text-end">
                        {totalRecords} Records Found!
                      </th>
                    </tr>
                    <tr>
                      <th>Transaction ID</th>
                      <th>DATED</th>
                      <th>FEE CODE</th>
                      <th>RECEIPT NO.</th>
                      <th>DESCRIPTION</th>
                      <th>DEBIT</th>
                      <th>CREDIT</th>
                      <th className={`text-center ${UserID != 1 ? 'd-none' : ''}`}>Action</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="8">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                     <tr>
                      <td colspan="4"></td>
                      <td className="text-end"><b> Total : </b></td>
                      <td >
                        <b>{totalDebit} </b>
                      </td>

                      <td>
                        <b>{totalCredit} </b>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colSpan={5}></td>
                      <td  >
                       <b> Total Payable : </b> 
                      </td>
                      <td ><b>{totalDebit-totalCredit}</b></td>
                      <td colSpan="2"></td>
                    </tr>
                  </tbody>
                </table>
                {totalRecords > 1000 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                              <option value="1000" selected>
                                1000
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentLedger;
