import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import Examination_navbar from './Examination_navbar';
import SessionsDropDown from "../Shared/SessionsDropDown";
import ExamTypeDropDown from "../Shared/ExamTypeDropDown";
import ExamStatusDropDown from "../Shared/ExamStatusDropDown";
import ExamsDropDown from "../Shared/ExamsDropDown";
import { scrollToTop } from '../Shared/ScrollOnTop';

const AddExaminations = () => {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const [End_Date, setEnd_Date] = useState("");
    const [Start_Date, setStart_Date] = useState("");
    const [Session, setSession] = useState(localStorage.getItem("DefaultSession"));
    const [Status, setStatus] = useState("");
    const [ExamType, setExamType] = useState("");
    const [PreviousExamID, setPreviousExamID] = useState(0);

    let navigate = useNavigate();

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
MemberType: MemberType,
                Name: event.target.Name.value,
                Print_Title: event.target.Print_Title.value,
                Start_Date: Start_Date,
                End_Date: End_Date,
                Current_Status: Status,
                Exam_Code: event.target.Exam_Code.value,
                Exam_Type:ExamType,
                School_ID: 1,
                Session_ID:Session,
                PreviousExamID: 0,
            };

             console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Exam/AddExam',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });



        }

        setValidated(true);
    };
    return (
        <>
            <Examination_navbar />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/ExaminationSystem/ExaminationDashboard">Examinations</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add New Examination</li>
                                </ol>
                            </nav>
                            <div className="page-title">Add New Examination</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/ExaminationSystem/ManageExaminations" className="button button-white button-icon">
                                    Manage Examinations
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="card-body p-md-4">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <div className="row align-items-center">
                                            <div className="col-md-2">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="">Exam Code:</label>
                                                    <input type="text" className="form-control" required name="Exam_Code" />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Exam Name:</label>
                                                    <input type="text" className="form-control" required name="Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Exam Title:</label>
                                                    <input type="text" className="form-control" required name="Print_Title" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Current Status:</label>
                                                    <select className="form-select" required onChange={(e) => setStatus(e.target.value)}>
                                                        <option value="">Please Select</option>
                                                        <option value="Announced">Anounced</option>
                                                        <option value="Current">InProgress</option>
                                                        <option value="Finished">Finished</option>
                                                        <option value="Delayed">Delayed</option>
                                                        <option value="Cancelled">Cancelled</option>
                                                        <option value="Terminated">Terminated</option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Start Date:</label>
                                                    <input type="date" className="form-control" required value={Start_Date} onChange={(e) => setStart_Date(e.target.value)}  />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">End Date:</label>
                                                    <input type="date" required className="form-control" value={End_Date} onChange={(e) => setEnd_Date(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Exam Type:</label>
                                                    <select className="form-select"  required onChange={(e) => setExamType(e.target.value)}>
                                                        <option value="">Please Select</option>
                                                        <option value="1st Term Annual">1st Term Annual</option>
                                                        <option value="2nd Term Annual">2nd Term Annual</option>
                                                        <option value="3rd Term Annual">3rd Term Annual</option>
                                                        <option value="Class Test">Class Test</option>
                                                        <option value="Cancelled">Cancelled</option>
                                                        <option value="Terminated">Terminated</option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="" className="">Session:</label>
                                                    <select className="form-select" required onChange={(e) => setSession(e.target.value)}>
                                                        <option value="">Please Select</option>
                                                        <SessionsDropDown />
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="" className="">Previous Exam to Copy Classes and Subjects:</label>
                                                    <select className="form-select" required onChange={(e) => setPreviousExamID(e.target.value)}>
                                                        <option value="">Please Select</option>
                                                        <ExamsDropDown />
                                                    </select>
                                                </div>
                                            </div>


                                            <div className="col-md-2 offset-md-1 text-end">
                                                <div className="form-group mt-md-3">
                                                    {
                                                        loading ?
                                                            <Spinner animation="border" role="status"></Spinner>

                                                            :
                                                            <button type="submit" value="Save" className="button button-primary w-100 ">SAVE</button>

                                                    }

                                                </div>
                                            </div>

                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddExaminations;
