import React, { useEffect, useState } from "react";
import AccountsMenu from './AccountsMenu';
import { NavLink } from 'react-router-dom';
import config from "../../Config";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { scrollToTop } from '../Shared/ScrollOnTop';

function AddNewAccount(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [FYear, setFYear] = useState(localStorage.getItem("DefaultFYear"));
    const [Accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);

    const [FirstAccountId, setFirstAccountId] = useState(0);
    const [SecondAccountId, setSecondAccountId] = useState(0);
    const [ThirdAccountId, setThirdAccountId] = useState(0);

    const [FirstLevelCode, setFirstLevelCode] = useState(0);
    const [SecondLevelCode, setSecondLevelCode] = useState(0);
    const [ThirdLevelCode, setThirdLevelCode] = useState(0);

    const [numOfItemsinSecond, setnumOfItemsinSecond] = useState();

    const [LevelID, setLevelID] = useState(1);
    const [ParentAccountID, setParentAccountID] = useState(0);

    const [AccountCode, setAccountCode] = useState("");

    const [AccountDetail, setAccountDetail] = useState("");
    const [AccountRemarks, setAccountRemarks] = useState("");

    const [Level1Status, setLevel1Status] = useState(false);
    const [Level2Status, setLevel2Status] = useState(true);
    const [Level3Status, setLevel3Status] = useState(true);

    // What account level needs to be added.

    const [AccountLevel, setAccountLevel] = useState(1);

    useEffect(() => {

        setLoading(true);
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            FYear: FYear,
            //AccountLevel: LevelID,
            pageNo: 1,
            pageSize: 1000,
            pAccountID: ParentAccountID,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Accounts/GetAccountsByYear',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        // console.log(data);

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                setAccounts(response.data.ListofRecords);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });



    }, []);

    useEffect(() => {
        if (!loading) {
            let level1 = Accounts.filter(item => item.iLevel == 1);
            let nextlevel = level1.length + 1;
            setFirstLevelCode(nextlevel.toString().padStart(2, '0'));
            setAccountCode(nextlevel.toString().padStart(2, '0'));
        }
    }, [Accounts]);


    const renderAccountsFirst = () => {
        if (Accounts.length > 0) {
            let level1 = Accounts.filter(item => item.iLevel == 1);

            return (

                level1.map((item, index) => {
                    return (
                        loading ?
                            <option selected disabled>Loading...</option>
                            :
                            <option value={item.ID} data-code={item.Account_code} key={index}>{item.Account_desc}</option>
                    )
                })
            );
        }
    }

    const renderAccountsSecond = () => {
        if (Accounts.length > 0) {
            let level2 = Accounts.filter(item => item.iLevel == 2 && item.pAccountID == FirstAccountId);
            return (

                level2.map((item, index) => {
                    return (
                        <option value={item.ID} data-code={item.Account_code} key={index}>{item.Account_desc}</option>
                    )
                })
            );
        }
    }


    const renderAccountsThird = () => {
        if (Accounts.length > 0) {
            let level1 = Accounts.filter(item => item.iLevel == 3 && item.pAccountID == SecondAccountId);
            return (

                level1.map((item, index) => {
                    return (
                        <option value={item.ID} data-code={item.Account_code} key={index}>{item.Account_desc}</option>
                    )
                })
            );
        }
    }

    const handleFirstChange = (e) => {
        setAccountLevel(2);
        setFirstAccountId(e.target.value);
        const selectedOptionCode = e.target.options[e.target.selectedIndex].dataset.code;
        let firstcode = selectedOptionCode.toString().padStart(2, '0');
        setFirstLevelCode(firstcode);
        setLevelID(2);
        setParentAccountID(e.target.value);

        let level2 = Accounts.filter(item => item.iLevel == 2 && item.pAccountID == e.target.value);

        let second_code = parseInt(level2.length) +  parseInt(1);

        let code = firstcode + "-" + second_code.toString().padStart(2, '0');
        setSecondLevelCode(code);
        setAccountCode(code);

        setLevel1Status(true);
        setLevel2Status(false);
        setAccountDetail("");
        setAccountRemarks("");

    }

    const handleSecondChange = (e) => {
        //levelTwo
        setAccountLevel(3);
        setSecondAccountId(e.target.value);
        

        const selectedOptionCode = e.target.options[e.target.selectedIndex].dataset.code;
        console.log(selectedOptionCode);
        if(selectedOptionCode == 0)
        {
            setSecondLevelCode(0);
        }
        else 
        {
            setSecondLevelCode(selectedOptionCode.toString().padStart(2, '0'));
        }
        

        let level3 = Accounts.filter(item => item.iLevel == 3 && item.pAccountID == e.target.value);

        let third_code = parseInt(level3.length) +  parseInt(1);
        let code = selectedOptionCode + "-" + third_code.toString().padStart(2, '0');
        setThirdLevelCode(code);
        setAccountCode(code);

        setLevel1Status(true);
        setLevel2Status(true);
        setLevel3Status(false);
        setAccountDetail("");
        setAccountRemarks("");

    }

    const handleThirdChange = (e) => {
        //levelTwo
        setThirdLevelCode(e.target.value);
        const dropdown = document.getElementById('levelThree');
        const numberOfItems = dropdown.options.length;
        const selectedOptionCode = e.target.options[e.target.selectedIndex].dataset.code;
        setThirdLevelCode(selectedOptionCode.toString().padStart(2, '0'));

    }


    const handleSaveAccount = () => {


        if(AccountDetail == "") {
            toast.error('Please enter account details', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                FirstLevelID: FirstAccountId,
                SecondLevelID: SecondAccountId,
                Account_code: AccountCode,
                Account_desc: AccountDetail,
                Remarks: AccountRemarks,
                Year: FYear
            }

            console.log(data);
    
            var api_config = {
                method: 'post',
                url: config.base_url + 'Accounts/AddNewAccount',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                  
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });


                    

                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
        }
        
    }

    return (
        <>
            <AccountsMenu />
            <ToastContainer />
            <div className="container body-content">

                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/Accounts/AccountsDashboard">Accounts</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add New Account</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Add / Update Account</div>
                            </div>
                            <div className="col-md-6">
                                <div className="page-right-actions">
                                    <NavLink to="/Accounts/ManageAccounts" className="button button-white button-icon">
                                        Manage Account
                                        <i className="ri-add-line"></i>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body p-4">

                                {
                                    loading ?
                                        <div className='text-center'>
                                            <Spinner animation="border" role="status"></Spinner>
                                            
                                        </div>
                                        :
                                        <>
                                        <div className="row align-items-center mb-3">
                                    <div className="col-md-2">
                                        <h5>1<sup>st</sup> Level</h5>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="row align-items-center">
                                            <div className="col-md-3">
                                                <div className="form-group mb-2">
                                                    <label>Select First Level</label>
                                                    <select id="levelOne" className="form-select" required onChange={(e) => handleFirstChange(e)}>
                                                        <option value="0" data-code="0">1st Level</option>
                                                        {renderAccountsFirst()}

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-2">
                                                    <label>Account Detail</label>
                                                    <input type="text" className="form-control" onChange={(e) => setAccountDetail(e.target.value)} disabled={Level1Status} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Remarks</label>
                                                    <input type="text" className="form-control" onChange={(e) => setAccountRemarks(e.target.value)}  disabled={Level1Status} />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                {FirstLevelCode}
                                            </div>
                                        </div>


                                    </div>

                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-md-2">
                                        <h5>2<sup>nd</sup> Level</h5>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="row align-items-center">
                                            <div className="col-md-3">
                                                <div className="form-group mb-2">
                                                    <label>Select First Level</label>
                                                    <select id="levelTwo" className="form-select" required onChange={(e) => handleSecondChange(e)}>
                                                        <option value="0" data-code="0">2nd Level</option>
                                                        {renderAccountsSecond()}

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-2">
                                                    <label>Account Detail</label>
                                                    <input type="text" className="form-control" onChange={(e) => setAccountDetail(e.target.value)} disabled={Level2Status} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Remarks</label>
                                                    <input type="text" className="form-control" onChange={(e) => setAccountRemarks(e.target.value)} disabled={Level2Status} />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                {SecondLevelCode}
                                            </div>
                                        </div>


                                    </div>

                                </div>
                                <div className="row align-items-center">
                                    <div className="col-md-2">
                                        <h5>3<sup>rd</sup> Level</h5>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="row align-items-center">
                                            <div className="col-md-3">
                                                <div className="form-group mb-2">
                                                    <label>Select First Level</label>
                                                    <select id="levelThree" className="form-select" required onChange={(e) => handleThirdChange(e)}>
                                                        <option value="0" data-code="0">3rd Level</option>
                                                        {
                                                            renderAccountsThird()

                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-2">
                                                    <label>Account Detail</label>
                                                    <input type="text" className="form-control" onChange={(e) => setAccountDetail(e.target.value)} disabled={Level3Status} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Remarks</label>
                                                    <input type="text" className="form-control" onChange={(e) => setAccountRemarks(e.target.value)}  disabled={Level3Status} />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                {ThirdLevelCode}
                                            </div>
                                        </div>


                                    </div>

                                </div>
                                <div className="mt-3">
                                     <button className="button button-primary" onClick={handleSaveAccount}>Save Account</button>
                                </div>
                                        </>
                                }

                                

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default AddNewAccount;