import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import AccountsMenu from './AccountsMenu';
import { NavLink } from 'react-router-dom';
import YearsDropDown from '../Shared/YearsDropDown';
import VoucherTypeDropDown from '../Shared/VoucherTypesDropDown';
import Pagination from "../General/Pagination";
import moment from "moment";
import { toast, ToastContainer } from 'react-toastify';
import { scrollToTop } from '../Shared/ScrollOnTop';

function ManageVouchers(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [DefaultFYear, setDefaultFYear] = useState(localStorage.getItem("DefaultFYear"));
    const [VoucherType, setVoucherType] = useState(0);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [listOfLedger, setLedger] = useState([]);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [loadingDelete, setLoadingDelete] = useState(false);
    const [IsDeleted, setIsDeleted] = useState(false);

    useEffect(() => {

        setIsDeleted(false);
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            PageNo: pageNo,
            PageSize: pageSize,
            FYear: DefaultFYear,
            VoucherTypeID: VoucherType,

        };

        //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Voucher/GetVouchersByYear',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code != 0) {
                    setLedger(response.data.ListofRecords);
                    setTotalRecords(response.data.totalRecords);
                    setLoading(false);
                }
                else {
                    setLedger(null);
                    setTotalRecords(0);
                    setLoading(false);
                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }, [pageNo, pageSize, DefaultFYear, VoucherType, IsDeleted]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.ID}</td>
                            <td>{item.Voucher_code}</td>
                            <td>{item.Voucher_number}</td>
                            <td> {moment(item.Voucher_date).format('DD/MM/YYYY')}</td>
                            <td>{item.FYear}</td>
                            <td>{item.Remarks}</td>
                            <td>
                                <ul className="inline-action">
                                    <li>
                                        <NavLink to={`/Accounts/EditVoucher/${item.ID}`} className="green" ><i className="ri-edit-line"></i></NavLink>
                                    </li>
                                    <li>
                                        <button type="button" onClick={(e) => DeleteVoucher(item.ID)} className="red" ><i className="ri-delete-bin-line"></i></button>
                                    </li>
                                </ul>

                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="7"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    const DeleteVoucher = (ID) => {

        const result = window.confirm('Are you sure you want to proceed?');

        if (result) {
            setLoadingDelete(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                ID: ID

            };

            //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Voucher/DeleteVoucher',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code = 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoadingDelete(false);
                        setIsDeleted(true);
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoadingDelete(false);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }


    return (
        <>
            <ToastContainer />
            <AccountsMenu />

            <div className="container body-content">

                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/Accounts/AccountsDashboard">Accounts</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Manage Vouchers</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Manage Vouchers</div>
                            </div>
                            <div className="col-md-6">
                                <div className="page-right-actions">
                                    <NavLink to="/Accounts/AddVoucher" className="button button-white button-icon">
                                        Add Voucher
                                        <i className="ri-add-line"></i>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body p-md-4">
                                <div className="row gy-3">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Select Year:</label>
                                            <select className="form-select" required name="ddlYears" onChange={(e) => setDefaultFYear(e.target.value)}  >
                                                <option value="">Please Select</option>
                                                <YearsDropDown selected={DefaultFYear} />
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Select Type:</label>
                                            <select className="form-select" required name="ddlVoucherType" onChange={(e) => setVoucherType(e.target.value)}  >
                                                <option value="">Please Select</option>
                                                <VoucherTypeDropDown />

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2 offset-md-4">
                                        <input type="button" value="Search" className="button button-primary mt-4 w-100"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mt-3">
                            <div className="card-body table_card p-md-4">
                                <table className="table table-theme table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                                ID
                                            </th>
                                            <th>
                                                Code
                                            </th>
                                            <th>
                                                Voucher No
                                            </th>
                                            <th>
                                                Dated
                                            </th>
                                            <th className="text-center">
                                                Year
                                            </th>
                                            <th>Details</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loading ?
                                                <tr>
                                                    <td className="text-center" colspan="7">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </td>
                                                </tr>
                                                :
                                                <>
                                                    {renderLedger()}
                                                </>

                                        }
                                    </tbody>
                                </table>

                                {
                                    totalRecords > pageSize &&
                                    <div className="pagination-wrap">

                                        <div className="row align-items-center">
                                            <div className="col-md-2 form_default">
                                                <div className="d-flex align-items-center">
                                                    <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                    <div className="form-group">
                                                        <select className="form-select"
                                                            style={{ width: "100px" }}
                                                            onChange={(e) => {
                                                                setPageNo(1);
                                                                setPageSize(parseInt(e.target.value));


                                                            }}>
                                                            <option value="10" selected>10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="500">500</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <Pagination
                                                pageNo={pageNo}
                                                pageSize={pageSize}
                                                totalRecords={totalRecords}
                                                onPageChange={handlePageChange}
                                            />
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageVouchers;