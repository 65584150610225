import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import Examination_navbar from './Examination_navbar';
import SubjectTypesDropDown from "../Shared/SubjectTypesDropDown";
import { scrollToTop } from '../Shared/ScrollOnTop';

function AddSubject() {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
   // const [SubjectType, setSubjectType] = useState(0);


    let navigate = useNavigate();

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
MemberType: MemberType,
                Name: event.target.Name.value,
                subject_type_id: event.target.SubjectType.value,
                School_ID: 1,
            };

             console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Exam/AddSubject',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });



        }

        setValidated(true);
    };
    return (
        <>
            <Examination_navbar />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/ExaminationSystem/ExaminationDashboard">Examination</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add Subject</li>
                                </ol>
                            </nav>
                            <div className="page-title">Add New Subject</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/ExaminationSystem/ManageSubjects" className="button button-white button-icon">
                                    Manage Subjects
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="card-body p-md-4">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <div className="row align-items-center">
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="">Subject Name:</label>
                                                    <input type="text" className="form-control" required name="Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Subject Type:</label>
                                                    <select className="form-select" name="SubjectType" required >
                                                    <option value="">Please Select</option>
                                                        <SubjectTypesDropDown />
                                                    </select>

                                                    
                                                </div>
                                            </div>


                                            <div className="col-md-2 offset-md-4 text-end">
                                                <div className="form-group ">
                                                    {
                                                        loading ?
                                                            <Spinner animation="border" role="status"></Spinner>

                                                            :
                                                            <button type="submit" value="Save" className="button button-primary w-100 ">SAVE</button>

                                                    }

                                                </div>
                                            </div>

                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddSubject;
