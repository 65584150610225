import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import LibraryMenu from "./LibraryMenu";
import { Dropdown } from "react-bootstrap";
import Pagination from "../General/Pagination";
import { scrollToTop } from '../Shared/ScrollOnTop';
import { Form, PageItem } from 'react-bootstrap';

function ViewLibrary(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [designationID, setDesignationId] = useState(0);
    const [empTypeID, seEmployeeTypeId] = useState(0);
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [showFilters, setShowFilters] = useState(true);


    const [validated, setValidated] = useState(false);

    // pagination 
    const [pageno, setPageNo] = useState(1);
    const [pagesize, setPageSize] = useState(10);
    const [totalrecords, setTotalRecords] = useState(0);

    useEffect(() => {

        document.title = "Manage Library";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageno,
            pageSize: pagesize,
            Book_Name: "",
            Writer_Name: "",
            Publisher_Name: "",
            Category: "",
            Book_No: "",
            Status: "",
            OrderBy: ""
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Library/GetAllBooks',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
                //console.log(response.data.totalRecords);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [pageno, pagesize, isUpdated]);


    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        }
        else {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                pageNo: pageno,
                pageSize: pagesize,
                Book_Name: "",
                Writer_Name: "",
                Publisher_Name: "",
                Category: "",
                Book_No: "",
                Status: "",
                OrderBy: ""
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Library/GetAllBooks',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    setLedger(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    //console.log(response.data.totalRecords);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        setValidated(true);
    }


    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.ID}</td>
                            <td>{item.Book_No}</td>
                            <td>{item.Writer_Name}</td>
                            <td>{item.Book_Name}</td>
                            <td>{item.Publisher_Name}</td>
                            <td>{item.Current_Status}</td>
                            <td>{item.Remarks}</td>
                            <td>
                                <div class="dropdown dropdown-table-actions">
                                    <Link href="javascript:;" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" class="">
                                        <i class="ri-more-fill"></i>
                                    </Link>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                        <li><NavLink to="/" className="dropdown-item" ><i className="ri-edit-line"></i>Edit</NavLink></li>
                                        <li><NavLink to="/" className="dropdown-item" ><i className="ri-pages-line"></i>Issue Book</NavLink></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colSpan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }
    const deleteBook = (ID) => {

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ID: ID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + '/Library/DeleteBook',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsUpdated(true);
                }
                // setIsUpdated(false);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <LibraryMenu />
            <ToastContainer />

            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/Dashboard">Dashboard</a></li>
                                    <li className="breadcrumb-item"><a href="/Library/LibraryDashboard">Library</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">MANAGE BOOKS LIBRARY</li>
                                </ol>
                            </nav>
                            <div className="page-title">MANAGE BOOKS LIBRARY</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <a href="/Library/AddBook" className="button button-white button-icon">
                                    Add Book
                                    <i className="ri-add-fill"></i>
                                </a>
                                <button
                                    onClick={() => {
                                        setShowFilters((prevIsActive) => !prevIsActive);
                                    }}
                                    className="button button-white button-icon">
                                    {showFilters ? 'Hide Filters' : 'Show Filters'}
                                    <i className="ri-filter-3-line"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`collapse ${showFilters ? 'show' : ''} `} id="collapseFilteres">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="page-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-md-4">
                                        <div className="card-body card-form-wrap">
                                            <div className="">
                                                <div className="row align-items-center">
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label for="" className="">Book Name:</label>
                                                            <input type="text" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label for="" className="">Writer Name:</label>
                                                            <input type="text" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label for="" className="">Publisher:</label>
                                                            <input type="text" className="form-control"
                                                                required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label for="" className="">Book No:</label>
                                                            <input type="text" className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label for="" className="">Status:</label>
                                                            <select ng-model="dataD.Current_Status" className="form-select" >
                                                                <option Value="">--All--</option>
                                                                <option Value="Available">Available</option>
                                                                <option Value="Issued">Issued</option>
                                                                <option Value="Lost">Lost</option>
                                                                <option Value="Disposed">Disposed</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label for="" className="">Order By:</label>
                                                            <select className="form-select">
                                                                <option Value="Book_No">BookNo</option>
                                                                <option Value="Book_Name">Book Name</option>
                                                                <option Value="Writer_Name">Writer Name</option>
                                                                <option Value="Publisher_Name">Publisher Name</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 offset-md-4">
                                                        <div className="form-group mt-3">
                                                            <input type="button" value="Search" className="button button-primary w-100"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>

            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <table className="table table-theme">
                                <thead>
                                    <tr>
                                        <th colSpan="8">
                                            TotalRecords  &nbsp; Records Found!
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>ID</th>
                                        <th>BookNo</th>
                                        <th>Writer Name</th>
                                        <th>Book Name</th>
                                        <th>Publisher</th>
                                        <th>Status</th>
                                        <th>Remarks</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td className="text-center" colSpan="8">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {renderLedger()}
                                            </>

                                    }
                                </tbody>
                            </table>
                            {
                                totalrecords > 10 &&
                                <div className="pagination-wrap">
                                    <div className="row align-items-center">
                                        <div className="col-md-2 form_default">
                                            <div className="d-flex align-items-center">
                                                <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                <div className="form-group">
                                                    <select className="form-select"
                                                        style={{ width: "100px" }}
                                                        onChange={(e) => {
                                                            setPageNo(1);
                                                            setPageSize(parseInt(e.target.value));
                                                        }}>
                                                        <option value="10" selected>10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <Pagination
                                            pageNo={pageno}
                                            pageSize={pagesize}
                                            totalRecords={totalrecords}
                                            onPageChange={handlePageChange}
                                        />

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewLibrary;