import React from 'react';
import StudentATMenu from './StudentATMenu';
import { scrollToTop } from '../Shared/ScrollOnTop';

function StudentAttendanceDashboard(props) {
    return (
        <>
            <StudentATMenu />
        </>
    );
}

export default StudentAttendanceDashboard;