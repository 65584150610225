import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { NavLink } from "react-router-dom";
import EmployeeTypeDropDown from "../Shared/EmployeeTypeDropDown";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SessionsDropDown from "../Shared/SessionsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import TeacherMenu from "./TeacherMenu";
import SectionByTeacherDropDown from "../Shared/SectionByTeacherDropDown";
import { scrollToTop } from '../Shared/ScrollOnTop';
import moment from "moment";

function MarkStudentAttendance(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [sectionId, setsectionId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [AttendanceDate, setAttendanceDate] = useState();
    //const imagePath = 'https://api.nscholar.com/staff_images/';

    const [WingId, setWingId] = useState(0);
    const [SessionId, setSessioinId] = useState(
        localStorage.getItem("DefaultSession")
    );
    const [ClassId, setClassId] = useState(0);
    const [SectionID, setSectionID] = useState(0);

    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const sectionid = urlParams.get("Section");
        const adate = urlParams.get("Dated");
        const sessionID = urlParams.get("SessionID");

        if (adate) {
            setAttendanceDate(adate);
        }
        if (sectionid) {
            setSectionID(sectionid);
        }
        if (sessionID) {
            setSessioinId(sessionID);
        }

        if (sectionid && adate && sessionID) {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                Section_ID: sectionid,
                AttendanceDate: AttendanceDate,
            };

            var api_config = {
                method: "post",
                url: config.base_url + "StudentAttendance/GetStudentAttendance",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(api_config)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status_code == 1) {
                        setLedger(response.data.ListofRecords);
                        setLoading(false);
                        setTotalRecords(response.data.totalRecords);
                    } else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }

                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error("Network Error..", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
        }
    }, [SessionId, WingId, SectionID, ClassId]);

    const handleSubmit = (event) => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Section_Id: SectionID,
            AttendanceDate: AttendanceDate,
        };

        console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "StudentAttendance/GetStudentAttendance",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }

                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                toast.error("Network Error..", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });

        setValidated(true);
    };

    const handleAttendanceChange = (recordIndex, newStatus) => {
        // Update attendance status in the component's state
        const updatedRecords = [...listOfLedger];
        updatedRecords[recordIndex].Absent_Leave = newStatus;
        setLedger(updatedRecords);
    };

    const handleSubmitAttendance = (event) => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Section_Id: SectionID,
            AttendanceDate: AttendanceDate,
            ListofStudentRecords: listOfLedger,
        };

        console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "StudentAttendance/UpdateAttendance",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }

                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                toast.error("Network Error..", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });

        setValidated(true);
    };

    const renderLedger = () => {
        if (listOfLedger.length > 0) {
            return listOfLedger.map((item, index) => {
                return (
                    <div key={index} className="col-md-2">
                        <div className="card attendence" style={{ minHeight: "" }}>
                            <div className="card-body d-flex flex-column justify-content-between">
                                <div>
                                    <img
                                        src={
                                            config.StudentImagePath + item.Registration_No + ".jpg"
                                        }
                                    />
                                    <h4>{item.Student_Name}</h4>
                                    <span>({item.Registration_No})</span>
                                </div>
                                <div className="radio-button">
                                    <div className="radio">
                                        <input
                                            type="radio"
                                            id="one"
                                            label="P"
                                            name={"attendance_" + item.Student_ID}
                                            value="P"
                                            checked={"P" === item.Absent_Leave}
                                            onChange={() => handleAttendanceChange(index, "P")}
                                            className="rabio-btn btn-1"
                                        />
                                        <input
                                            type="radio"
                                            id="two"
                                            label="CL"
                                            name={"attendance_" + item.Student_ID}
                                            value="CL"
                                            checked={"CL" === item.Absent_Leave}
                                            onChange={() => handleAttendanceChange(index, "CL")}
                                            className="rabio-btn btn-2"
                                        />
                                        <input
                                            type="radio"
                                            id="three"
                                            label="AB"
                                            name={"attendance_" + item.Student_ID}
                                            value="AB"
                                            checked={"AB" === item.Absent_Leave}
                                            onChange={() => handleAttendanceChange(index, "AB")}
                                            className="rabio-btn btn-3"
                                        />

                                        <input
                                            type="radio"
                                            id="four"
                                            label="LC"
                                            name={"attendance_" + item.Student_ID}
                                            value="LC"
                                            checked={"LC" === item.Absent_Leave}
                                            onChange={() => handleAttendanceChange(index, "LC")}
                                            className="rabio-btn btn-4"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        } else {
            return (
                    <div className="col-md-12 m-md-0">
                        <div className="card">
                            <div className="card-body">
                                <h4
                                    className=""
                                    style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                                >
                                    No Data Found
                                </h4>
                            </div>
                        </div>
                    </div>
            );
        }
    };

    return (
        <>
            <TeacherMenu />
            <ToastContainer />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <NavLink to="/teacher/Dashboard">Dashboard</NavLink>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <NavLink to="">
                                                Student Attendance
                                            </NavLink>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Mark Attendance
                                        </li>
                                    </ol>
                                </nav>
                                <div className="page-title">Mark Student Attendance</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body card-form-wrap">
                                        <div className="">
                                            <div className="row align-items-center">
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Select Session</label>
                                                        <select
                                                            className="form-select"
                                                            onChange={(e) => setSessioinId(e.target.value)}
                                                            name="SessionID"
                                                        >
                                                            <option value="0">---All---</option>
                                                            <SessionsDropDown selectedSession={SessionId} />
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label for="" className="">
                                                            Select Section:
                                                        </label>
                                                        <select
                                                            className="form-select"
                                                            onChange={(e) => setSectionID(e.target.value)}
                                                            value={SectionID}
                                                            name="Section_Id"
                                                            required
                                                        >
                                                            <option value="0">Please Select</option>
                                                            {SessionId !== 0 && (

                                                                <SectionByTeacherDropDown Sessionid={SessionId} />
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Select Date:</label>
                                                        <input
                                                            className="form-control"
                                                            value={AttendanceDate}
                                                            max={moment().format(config.date_format_input)}
                                                            onChange={(e) =>
                                                                setAttendanceDate(e.target.value)
                                                            }
                                                            type="date"
                                                            name="date"
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2 offset-md-1">
                                                    <div className="form-group">
                                                        {loading ? (
                                                            <Spinner
                                                                animation="border"
                                                                role="status"
                                                            ></Spinner>
                                                        ) : (
                                                            <button
                                                                type="submit"
                                                                onClick={handleSubmit}
                                                                value="Load Students"
                                                                className="button button-primary w-100"
                                                            >
                                                                Load Students
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row align-items-center my-2 g-3">
                            {renderLedger()}
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="card p-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <NavLink
                                        to="/Reports/AttendenceSummary"
                                        className="button button-primary"
                                    >
                                        Print
                                    </NavLink>
                                </div>
                                <div className="col-md-6 text-end">
                                    {loading ? (
                                        <Spinner animation="border" role="status"></Spinner>
                                    ) : (
                                        <NavLink
                                            type="submit"
                                            onClick={handleSubmitAttendance}
                                            value="Save Attendence"
                                            className="button button-primary"
                                        >
                                            Save Attendence
                                        </NavLink>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ >
    );
}

export default MarkStudentAttendance;
