import React, { useState, useEffect } from "react";
import StaffAtMenu from './StaffAtMenu';
import { NavLink } from 'react-router-dom';
import MonthsDropDown from '../Shared/MonthsDropDown';
import YearsDropDown from '../Shared/YearsDropDown';
import EmployeeTypeDropDown from '../Shared/EmployeeTypeDropDown';
import { Form } from 'react-bootstrap';
import config from '../../Config';
import axios from 'axios';
import { RenderHTMLString } from "../General/Utility";
import { scrollToTop } from '../Shared/ScrollOnTop';

function AttendanceSummary(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);

    const [htmlstring, setHtmlString] = useState("");


    const [WingId, setWingID] = useState(0);
    const [SelectedMonth, seSelectedMonth] = useState(0);
    const [FYear, setFYear] = useState(0);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        }
        else {
            //setLoading(true);


            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                Wing_Id: WingId,
                SelectedMonth: SelectedMonth,
                FYear: FYear
            }

            //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'StaffAttendance/GetAttendanceSummary',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    setHtmlString(response.data.attendenceTable);

                })
                .catch(function (error) {
                    console.log(error);
                });

        }

        setValidated(true);
    }



    return (
        <>
            <StaffAtMenu />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/StaffAttendance/StaffAttendanceDashboard">Staff Attendance</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Attendence Summary</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Attendence Summary</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className="card">
                                <div className="card-body card-form-wrap">
                                    <div className="">
                                        <div className="row align-items-center">
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Department</label>
                                                    <select className="form-select" name='Wing_Id' onChange={(e) => setWingID(e.target.value)} required>
                                                        <option Value="0">--All--</option>
                                                        <EmployeeTypeDropDown />
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label> Select Date:</label>
                                                    <select className="form-select" name='SelectedMonth' onChange={(e) => seSelectedMonth(e.target.value)} required>
                                                        <option value="">Select</option>
                                                        <MonthsDropDown />
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label> Select Year:</label>
                                                    <select className="form-select" name='FYear' onChange={(e) => setFYear(e.target.value)} required>
                                                        <YearsDropDown />
                                                    </select>

                                                </div>
                                            </div>


                                            <div className="col-md-2 offset-md-1 text-end mt-md-3">
                                                <div className="form-group">
                                                    <button type="submit"
                                                        className="button button-primary w-100">Get Report</button>
                                                </div>
                                            </div>
                                            <div className="col-md-2 offset-md-8">
                                                <NavLink target="_blank"
                                                    to={`/StaffAttendance/PrintAttendanceSummary/?employeetypeid=${WingId}&month=${SelectedMonth}&year=${FYear}`}
                                                    className="button button-outline-primary w-100">Print Summary</NavLink>

                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <input type="submit" value="Salary Deduction"
                                                        className="button button-primary w-100"
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>

                    <div className='container mt-2'>
                        <div className="card p-3 table-reset">
                            <div className="table-responsive table-attendence">
                                {RenderHTMLString(htmlstring)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AttendanceSummary;