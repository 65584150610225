import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import PayrollMenu from './PayrollMenu';
import DesignationDropDown from "../Shared/DesignationDropDown";
import PayScaleBasic from "../Shared/PayScaleBasic";
import PayScaleGps from "../Shared/PayScaleGps";
import EmployeeTypeDropDown from "../Shared/EmployeeTypeDropDown";
import { scrollToTop } from '../Shared/ScrollOnTop';

function AddNewEmployee(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [Code, setCode] = useState("");

    const [loading, setLoading] = useState(false);
    const [loadingCode, setLoadingCode] = useState(false);


    useEffect(() => {
        document.title = "Add New Employee";

        setLoadingCode(true);
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
        }

        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/GetNextStaffCode',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                setCode(response.data.Code);

                setLoadingCode(false);

            })
            .catch(function (error) {

            });


    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            //alert();
            setLoading(true);

            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
MemberType: MemberType,
                Code: Code,
                Name: event.target.Name.value,
                Father_Name: event.target.Father_Name.value,
                Husband_Name: event.target.Husband_Name.value,
                Password: event.target.Password.value,
                EmployeeTypeID: event.target.EmployeeTypeID.value,
                Marital_Status: event.target.Marital_Status.value,
                Gender: event.target.Gender.value,
                DesignationID: event.target.DesignationID.value,
                Date_Of_Joining: event.target.Date_Of_Joining.value,
                Date_Of_Birth: event.target.Date_Of_Birth.value,
                CNIC_NO: event.target.CNIC_NO.value,
                Account_No: event.target.Account_No.value,
                Home_Address: event.target.Home_Address.value,
                Phone_No: event.target.Phone_No.value,
                Mobile_No: event.target.Mobile_No.value,
                Qualification: event.target.Qualification.value,
                Blood_Group: event.target.Blood_Group.value,
                NTN_NO: event.target.NTN_NO.value,
                EOBI_NO: event.target.EOBI_NO.value,
                Basic_Pay_Scale: event.target.Basic_Pay_Scale.value,
                Email_Address: event.target.Email_Address.value,
                Remarks: event.target.Remarks.value,
                GPS_Scale: event.target.GPS_Scale.value

            };



            console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Payroll/AddEmployee',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });



        }

        setValidated(true);
    };


    return (
        <>
            <PayrollMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/PayRoll/PayRollDashboard">Pay Roll</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Add New Employee</li>
                                </ol>
                            </nav>
                            <div className="page-title">Add New Employee</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/PayRoll/ManageEmployee" className="button button-white button-icon">
                                    Manage Employees
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body p-md-4">
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div className="form-wrap">
                                    <div className="form-legend" style={{ left: "64px" }}>Basic Information</div>
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="row align-item-center">
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Employee Code:</label>
                                                        <div className="label-gray">
                                                            {loadingCode ? <Spinner animation="border" size="sm" role="status"></Spinner> : Code}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Employee Name:</label>
                                                        <input type="text" className="form-control" required name="Name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Father Name:</label>
                                                        <input type="text" className="form-control" required name="Father_Name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Husband Name:</label>
                                                        <input type="text" className="form-control" name="Husband_Name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Marital Status:</label>
                                                        <select className="form-select" name="Marital_Status" required="">
                                                            <option value="NotAvailable">Not Available</option>
                                                            <option value="Single">Single</option>
                                                            <option value="Married">Married</option>
                                                            <option value="Divorced">Divorced</option>
                                                            <option value="Widowed">Widowed</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Gender:</label>
                                                        <select className="form-select" name="Gender" required>
                                                            <option value="">Select Gender</option>
                                                            <option value={true}>Male</option>
                                                            <option value={false}>Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="" className="">Date of Birth:</label>
                                                        <input type="date" className="form-control" required name="Date_Of_Birth" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Blood Group:</label>
                                                        <input type="text" className="form-control" name="Blood_Group" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="" className="">CNIC No:</label>
                                                        <input type="text" className="form-control" required name="CNIC_NO" />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="" className="">Qualification:</label>
                                                        <input type="text" className="form-control" name="Qualification" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="form-wrap">
                                    <div className="form-legend" style={{ left: "64px" }}>Account Information</div>
                                    <div className="row align-items-center">
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Bank Account No:</label>
                                                <input type="text" className="form-control" name="Account_No" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>EOBI No:</label>
                                                <input type="text" className="form-control" name="EOBI_NO" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>NTN NO:</label>
                                                <input type="text" className="form-control" name="NTN_NO" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Designation:</label>
                                                <select className="form-select" required name="DesignationID">
                                                    <option value="">Select Designation</option>
                                                    <DesignationDropDown />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="">Date of Joining:</label>
                                                <input type="date" className="form-control" required name="Date_Of_Joining" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="">Password:</label>
                                                <input type="password" className="form-control" name="Password" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="form-wrap">
                                    <div className="form-legend" style={{ left: "64px" }}>Contact Information</div>
                                    <div className="row align-items-center">
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Phone No:</label>
                                                <input type="text" className="form-control" name="Phone_No" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Mobile No:</label>
                                                <input type="text" className="form-control" required name="Mobile_No" />
                                            </div>
                                        </div>


                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="">Email Address:</label>
                                                <input type="text" className="form-control" name="Email_Address" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="">Home Address:</label>
                                                <input type="text" className="form-control" required name="Home_Address" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-wrap">
                                    <div className="form-legend" style={{ left: "64px" }}>Pay Information</div>
                                    <div className="row align-items-center">
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Basic Pay Scale</label>
                                                <select className="form-select" required name="Basic_Pay_Scale">
                                                    <option value="">Please Select</option>
                                                    <PayScaleBasic />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Pay Scale GPS:</label>
                                                <select className="form-select" required name="GPS_Scale">
                                                    <option value="">Please Select</option>
                                                    <PayScaleGps />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Group/Depart:</label>
                                                <select className="form-select" required name="EmployeeTypeID">
                                                    <option value="">Please Select</option>
                                                    <EmployeeTypeDropDown />
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-10">
                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="">Remarks:</label>
                                                <input type="text" className="form-control" name="Remarks" />
                                            </div>
                                        </div>
                                        <div className="col-md-2 mt-md-2 text-end">
                                            <div className="form-group ">
                                                {
                                                    loading ?
                                                        <Spinner animation="border" role="status"></Spinner>

                                                        :
                                                        <button type="submit" className="button button-primary w-100 ">SAVE</button>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddNewEmployee;