import React, { useState, useEffect } from 'react';
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import AccountsMenu from './AccountsMenu';
import { NavLink } from 'react-router-dom';
import YearsDropDown from '../Shared/YearsDropDown';
import Pagination from "../General/Pagination";
import moment from "moment";
import { scrollToTop } from '../Shared/ScrollOnTop';

function TrialBalanceLevelWise(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [DefaultFYear, setDefaultFYear] = useState(localStorage.getItem("DefaultFYear"));
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [OpeningDebitTotal, setOpeningDebitTotal] = useState("");
    const [OpeningCreditTotal, setOpeningCreditTotal] = useState("");
    const [CurrentDebitTotal, setCurrentDebitTotal] = useState("");
    const [CurrentCreditTotal, setCurrentCreditTotal] = useState("");
    const [ClosingDebitTotal, setClosingDebitTotal] = useState("");
    const [ClosingCreditTotal, setClosingCreditTotal] = useState("");
    const [AccountLevel, setAccountLevel] = useState("0");
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const [zeroAccounts, setZeroAccounts] = useState(true);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            PageNo: pageNo,
            PageSize: 1000,
            FYear: DefaultFYear,
            StartDate: StartDate,
            EndDate: EndDate,
            AccountLevel: AccountLevel,
            ShowZeroAccounts: zeroAccounts

        };

        console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Accounts/GetTrialBalance',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code != 0) {
                    setLedger(response.data.ListofRecords);
                    setTotalRecords(response.data.totalRecords)
                    setOpeningDebitTotal(response.data.OpeningDebitTotal);
                    setOpeningCreditTotal(response.data.OpeningCreditTotal);
                    setCurrentDebitTotal(response.data.CurrentDebitTotal);
                    setCurrentCreditTotal(response.data.CurrentCreditTotal);
                    setClosingDebitTotal(response.data.ClosingDebitTotal);
                    setClosingCreditTotal(response.data.ClosingCreditTotal);

                }
                else {
                    setLedger(null);
                    setTotalRecords(0)
                }

                setLoading(false);

            })
            .catch(function (error) {
                console.log(error);
            });



    }, [pageNo, pageSize, DefaultFYear, AccountLevel, StartDate, EndDate, zeroAccounts]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.Selected_Account.Account_code}</td>
                            <td>{item.Selected_Account.Account_desc}</td>
                            <td className='bg-green text-right'>{item.OpeningDebit}</td>
                            <td className='bg-green text-right'>{item.OpeningCredit}</td>
                            <td className='bg-blue text-right'>{item.CurrentDebit}</td>
                            <td className='bg-blue text-right'>{item.CurrentCredit}</td>
                            <td className='bg-red text-right'>{item.ClosingDebit}</td>
                            <td className='bg-red text-right'>{item.ClosingCredit}</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    return (
        <>
            <AccountsMenu />
            <div className="container body-content">

                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/Accounts/AccountsDashboard">Accounts</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Trial Balance Level Wise</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Trial Balance Level Wise</div>
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body p-md-4">
                                <div className="row align-items-center gy-3">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Select Level:</label>
                                            <select className="form-select" onChange={(e) => setAccountLevel(e.target.value)} >
                                                <option selected value="0">Select Level</option>
                                                <option selected value="1">1st Level</option>
                                                <option selected value="2">2st Level</option>
                                                <option selected value="3">3st Level</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="">Start Date:</label>
                                            <input type="date" className="form-control" onChange={(e) => setStartDate(e.target.value)} required 
                                            
                                            max={moment().format(config.date_format_input)} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="">End Date:</label>
                                            <input type="date" className="form-control" onChange={(e) => setEndDate(e.target.value)} required
                                            max={moment().format(config.date_format_input)}  />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <div class="form-check mt-md-4">
                                                <input class="form-check-input" type="checkbox" checked={zeroAccounts} onChange={(e) => setZeroAccounts(e.target.checked)} id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    Show Zero Accounts:
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 text-end">
                                        <div className="form-group">
                                            <input type="button" value="Print" className="mt-md-4 button button-primary w-100"
                                            />
                                            {/* <input type="button" value="Search" className="button button-primary"
                                                        /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card mt-2'>
                            <div className='card-body responsive-table'>
                                <table className="table table-theme table-hover">
                                    <tbody>
                                        <tr>
                                            <th>
                                                Account Code
                                            </th>
                                            <th>
                                                Account Name
                                            </th>
                                            <th colspan="2" className="text-center bg-green">
                                                Opening
                                            </th>
                                            <th colspan="2" className="text-center bg-blue">
                                                Current
                                            </th>
                                            <th colspan="2" className="text-center bg-red">
                                                Closing
                                            </th>
                                        </tr>

                                        <tr>
                                            <th>

                                            </th>
                                            <th>

                                            </th>
                                            <th className="text-center bg-green">
                                                Debit
                                            </th>
                                            <th className="text-center bg-green">
                                                Credit
                                            </th>
                                            <th className="text-center bg-blue">
                                                Debit
                                            </th>
                                            <th className="text-center bg-blue">
                                                Credit
                                            </th>
                                            <th className="text-center bg-red">
                                                Debit
                                            </th>
                                            <th className="text-center bg-red">
                                                Credit
                                            </th>
                                        </tr>

                                        {
                                            loading ?

                                                <tr>
                                                    <td className="text-center" colspan="8">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </td>
                                                </tr>

                                                :
                                                <>

                                                    {renderLedger()}

                                                </>

                                        }


                                        <tr>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td className="text-right">
                                                {OpeningDebitTotal}
                                            </td>
                                            <td className="text-right">
                                                {OpeningCreditTotal}
                                            </td>
                                            <td className="text-right">
                                                {CurrentDebitTotal}
                                            </td>
                                            <td className="text-right">
                                                {CurrentCreditTotal}
                                            </td>
                                            <td className="text-right">
                                                {ClosingDebitTotal}
                                            </td>
                                            <td className="text-right">
                                                {ClosingCreditTotal}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TrialBalanceLevelWise;