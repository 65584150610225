import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import AccountsMenu from './AccountsMenu';
import Pagination from "../General/Pagination";
import { scrollToTop } from '../Shared/ScrollOnTop';

function ManageAccount(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [Level, setLevel] = useState("0");
    const [FYear, setFYear] = useState(localStorage.getItem("DefaultFYear"));
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {

        document.title = "Manage Accounts";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            FYear: FYear,
            pageNo: pageNo,
            pageSize: pageSize,
            AccountLevel: Level,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Accounts/GetAccountsByYear',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                console.log(error);
            });

    }, [pageNo, pageSize, isUpdated, Level]);


    const handleSubmit = (event) => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            FYear: FYear,
            AccountLevel: Level,
            pageNo: pageNo,
            pageSize: pageSize,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Accounts/GetAccountsByYear',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);

                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });

    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td></td>
                            <td>{item.Account_code}</td>
                            <td>{item.Account_desc}</td>
                            <td>{item.iLevel}</td>
                            <td>
                                <ul className="inline-action">
                                    <li>
                                        {
                                            item.iLevel == 3 &&
                                            <NavLink to={'/Accounts/EditOpeningBalance/' + item.ID} className="green" ><i className="ri-edit-line"></i></NavLink>
                                        }

                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul className="inline-action">
                                    <li>
                                        {
                                            item.iLevel == 3 &&
                                            <a className="red"><i className="ri-delete-bin-line"></i></a>
                                        }

                                    </li>
                                </ul>
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="5"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }



    return (
        <>
            <AccountsMenu />
            <ToastContainer />
            <div className="container body-content">

                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/Accounts/AccountsDashboard">Accounts</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Manage Accounts</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Manage Accounts</div>
                            </div>
                            <div className="col-md-6">
                                <div className="page-right-actions">
                                    <NavLink to="/Accounts/AddNewAccount" className="button button-white button-icon">
                                        Add Account
                                        <i className="ri-add-line"></i>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body p-md-4">
                                <div className="row gy-3">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Select Level:</label>
                                            <select className="form-select" required onChange={(e) => setLevel(e.target.value)}>
                                                <option value="" disabled selected>Please Select</option>
                                                <option value="0">All Levels</option>
                                                <option value="1">Level 1</option>
                                                <option value="2">Level 2</option>
                                                <option value="3">Level 3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Account No:</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-2 offset-md-4 text-end">
                                        <div className="form-group ">
                                            {
                                                loading ?
                                                    <Spinner animation="border" role="status"></Spinner>
                                                    :
                                                    <button type="submit" onClick={handleSubmit} value="SEARCH" className="mt-md-4 w-100 button button-primary">Search</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mt-3">
                            <div className="card-body table_card">
                                <table className="table table-theme table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                                Sr#
                                            </th>
                                            <th>
                                                Account Code
                                            </th>
                                            <th>
                                                Account Desc.
                                            </th>
                                            <th>
                                                Level
                                            </th>
                                            <th className="text-center">
                                                View Ledger
                                            </th>
                                            <th className="text-center">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loading ?
                                                <tr>
                                                    <td className="text-center" colspan="5">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </td>
                                                </tr>
                                                :
                                                <>
                                                    {renderLedger()}
                                                </>

                                        }
                                    </tbody>
                                </table>
                                {
                                    totalRecords > 10 &&
                                    <div className="pagination-wrap">

                                        <div className="row align-items-center">
                                            <div className="col-md-2 form_default">
                                                <div className="d-flex align-items-center">
                                                    <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                    <div className="form-group">
                                                        <select className="form-select"
                                                            style={{ width: "100px" }}
                                                            onChange={(e) => {
                                                                setPageNo(1);
                                                                setPageSize(parseInt(e.target.value));


                                                            }}>
                                                            <option value="10" selected>10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <Pagination
                                                pageNo={pageNo}
                                                pageSize={pageSize}
                                                totalRecords={totalRecords}
                                                onPageChange={handlePageChange}
                                            />
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default ManageAccount;