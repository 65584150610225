import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import SetupMenu from './SetupMenu';
import { scrollToTop } from '../Shared/ScrollOnTop';

const AddBankAcc = () => {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);

    let navigate = useNavigate();

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                Bank_Name: event.target.Bank_Name.value,
                Bank_Address: event.target.Bank_Address.value,
                Account_No: event.target.Account_No.value,
                Fee_Voucher_Statement: event.target.Fee_Voucher_Statement.value,
                School_ID: 1,
            };

            // console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Setup/AddNewBank',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });



        }

        setValidated(true);
    };

    return (
        <>
            <SetupMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"> <Link to="/Setup/SetupDashboard">Setup</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage BusRoutes</li>
                                </ol>
                            </nav>
                            <div className="page-title">Add Bank Account</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/SetUp/ManageBankAccounts" className="button button-white button-icon">
                                    Manage Bank Accounts
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-md-4">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <div className="row align-items-center">
                                            <div className="col-md-4">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="">Bank Name:</label>
                                                    <input type="text" className="form-control" required name="Bank_Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Bank Address:</label>
                                                    <input type="text" className="form-control" required name="Bank_Address" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Bank Account No. :</label>
                                                    <input type="text" className="form-control" required name="Account_No" />
                                                </div>
                                            </div>

                                            <div className="col-md-8">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="">Bank Statement:</label>
                                                    <input type="text" className="form-control" required name="Fee_Voucher_Statement" />
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group pt-md-3 d-flex">
                                                    <div class="form-check">
                                                        <input type="checkbox" id="checkStatus" required name="Is_Default_Bank" class="" />
                                                        <label class="form-check-label ms-md-3 mb-md-0" for="checkStatus">Status</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 text-end">
                                                <div className="form-group ">
                                                    {
                                                        loading ?
                                                            <Spinner animation="border" role="status"></Spinner>

                                                            :
                                                            <button type="submit" value="Save" className="mt-2 button button-primary w-100 ">SAVE</button>

                                                    }

                                                </div>
                                            </div>

                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddBankAcc;
