import React, { useState, useEffect } from "react";
import { NavLink, Link, Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import imgLogo from "../assets/images/logo.png"
import logo_1 from "../assets/images/logo/logo_1.svg"
import imgUser from "../assets/images/user.png"
import Offcanvas from 'react-bootstrap/Offcanvas';
import imgStudent from "../assets/images/icons/student.svg";
import Setup from "../assets/images/icons/Setup.svg";
import imgFee from "../assets/images/icons/fee.svg";
import imgmonthlytask from "../assets/images/icons/monthly-task.svg";
import imgAnnualtask from "../assets/images/icons/annual-task.svg";
import imgCertificate from "../assets/images/icons/certificate.svg";
import imgSMS from "../assets/images/icons/sms.svg";
import imgLibrary from "../assets/images/icons/library.svg";
import imgReports from "../assets/images/icons/report.svg"
import reports from "../assets/images/icons/reports.svg"
import imgPayroll from "../assets/images/icons/payroll.svg"
import imgExamination from "../assets/images/icons/exam.svg"
import imgSecurity from "../assets/images/icons/security.svg"
import imgAccounts from "../assets/images/icons/accounts.svg"
import imgStore from "../assets/images/icons/store.svg"
import imgStaff from "../assets/images/icons/staff.svg"
import Dropdown from 'react-bootstrap/Dropdown';
import setup from '../assets/images/dashboard_icon/setup.svg';
import axios from "axios";
import config from "../Config";
import Footer from "./Shared/Footer";
import { scrollToTop } from './Shared/ScrollOnTop';

function Main(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [UserName, setUserName] = useState(localStorage.getItem("UserName"));
    const [GroupName, setGroupName] = useState(localStorage.getItem("Group_Name"));
    const [FYear, setFYear] = useState(localStorage.getItem("DefaultFYear"));
    const [show, setShow] = useState(false);
    const [school, setSchool] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let navigate = useNavigate();

    const handleSignOut = () => {
        localStorage.clear();
        navigate("/login");
    }

    useEffect(() => {

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'School/GetSchoolInfo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                setSchool(response.data);

            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);

    useEffect(() => {

        if (AccessKey == null) {
            navigate("/login");
        }
        else {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                //URL: window.location.pathname,
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/ValidateAccessKey',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code == 0) {
                        localStorage.clear();
                        navigate("/login");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, []);

    
    return (
        <>
            <header className={`desktop-header sticky`}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-2">
                            <NavLink onClick={scrollToTop}  to="/Dashboard">
                                <img src={config.images + school.School_logo_large} className="img-fluid" alt="" />
                            </NavLink>
                        </div>
                        <div className="col-md-10">
                            <ul className="navbar-general">
                                <li>
                                    <ul className="nav align-items-center">
                                        <li className="nav-item">
                                            <NavLink onClick={scrollToTop}  className="nav-link" to="/Dashboard">Dashboard</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink onClick={scrollToTop}  className="nav-link" to="/Setup/SetupDashboard">
                                                Setup
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="toggle-side-menu">
                                    <a onClick={handleShow} style={{ cursor: "pointer" }}>
                                        <i className="ri-menu-3-fill"></i>
                                    </a>
                                </li>
                                <li className="user-logged-in">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="user-dropdown text-start" id="dropdownUserLoggedIn">
                                            <div className="thumb">
                                                <img src={imgUser} />
                                            </div>
                                            <div className="info">
                                                <span className="user_name">
                                                    {UserName}
                                                </span>
                                                <span className="role">{GroupName}</span>
                                            </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <NavLink onClick={scrollToTop}  className="dropdown-item" to="/Security/ChangePassword"><i className="ri-lock-2-line me-2"></i>Change Password</NavLink>
                                            <button onClick={handleSignOut} className="dropdown-item"><i className="ri-logout-box-line me-2"></i>Logout</button>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <header className={`mobile-header sticky `}>
                <div>
                    <NavLink onClick={scrollToTop}  to="/Dashboard">
                        <img src={logo_1} className="img-fluid mobile-logo" alt="" />
                    </NavLink>
                </div>
                <div>
                    <ul className="navbar-general">
                        <li className="user-logged-in">
                            <Dropdown>
                                <Dropdown.Toggle variant="user-dropdown text-start" id="dropdownUserLoggedIn">
                                    <div className="thumb">
                                        <img src={imgUser} />
                                    </div>
                                    <div className="info">
                                        <span className="user_name">
                                            {UserName}
                                        </span>
                                        <span className="role">{GroupName}</span>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <NavLink onClick={scrollToTop}  className="dropdown-item" to="/Security/ChangePassword"><i className="ri-lock-2-line me-2"></i>Change Password</NavLink>
                                    <button onClick={handleSignOut} className="dropdown-item"><i className="ri-logout-box-line me-2"></i>Logout</button>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>

                    </ul>
                </div>
                <div>
                    <ul className="navbar-general">
                        <li className="user-logged-in">
                            <li className="toggle-side-menu">
                                <a onClick={handleShow} style={{ cursor: "pointer" }}>
                                    <i className="ri-menu-3-fill"></i>
                                </a>
                            </li>
                        </li>
                    </ul>
                </div>
            </header >
            <Offcanvas show={show} style={{ width: "300px" }} onHide={handleClose} placement='end'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul className="offcanvas-main-menu">
                        <li>
                            <NavLink onClick={scrollToTop}  to="/Setup/SetupDashboard">
                                <img src={Setup} alt="" />
                                <span>Setup</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/students/StudentDashboard">
                                <img src={imgStudent} alt="" />
                                <span>Students</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/feesystem/feesystemdashboard">
                                <img src={imgFee} alt="" />
                                <span>Fee System</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/MonthlyTasks/MonthlyTasksDashboard">
                                <img src={imgmonthlytask} alt="" />
                                <span>Monthly Tasks</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/AnnualTasks/AnnualTasksDashboard">
                                <img src={imgAnnualtask} alt="" />
                                <span>Annual Tasks</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/Certificate/CertificateDashboard">
                                <img src={imgCertificate} alt="" />
                                <span>Certificate Printing</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/SMSAlert/SMSAlertDashboard">
                                <img src={imgSMS} alt="" />
                                <span>Send SMS Alert</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/Library/LibraryDashboard">
                                <img src={imgLibrary} alt="" />
                                <span>Library</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/Reports/ReportsDashboard">
                                <img src={reports} alt="" />
                                <span>Reports</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/PayRoll/PayRollDashboard">
                                <img src={imgPayroll} alt="" />
                                <span>Payroll</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/ExaminationSystem/ExaminationDashboard">
                                <img src={imgExamination} alt="" />
                                <span>Examination</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/Security/SecurityDashboard">
                                <img src={imgSecurity} alt="" />
                                <span>Security</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/Accounts/AccountsDashboard">
                                <img src={imgAccounts} alt="" />
                                <span>Accounts</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/StockManagement/StocksDashboard">
                                <img src={imgStore} alt="" />
                                <span>Stocks</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/StaffAttendance/StaffAttendanceDashboard">
                                <img src={imgStaff} alt="" />
                                <span>Staff Attendance</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={scrollToTop}  to="/StudentAttendance/StudentAttendanceDashboard">
                                <img src={imgStudent} alt="" />
                                <span>Student Attendance</span>
                            </NavLink>
                        </li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
            <Outlet></Outlet>
            <Footer />
        </>
    );
}

export default Main;