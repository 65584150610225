import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import StudentStatusDropDown from "../Shared/StudentStatusDropDown";
import { toast, ToastContainer } from 'react-toastify';
import { NavLink } from "react-router-dom";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from 'react-bootstrap/Dropdown';
import { Form } from 'react-bootstrap';
import FeeSystemMenu from "./FeeSystemMenu";
import FeeTypesDropDown from "../Shared/FeeTypesDropDown";
import { scrollToTop } from '../Shared/ScrollOnTop';

function AddOtherDuesFine(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

    const [showFilters, setShowFilters] = useState(true);

    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [validated, setValidated] = useState(false);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    const [NewWingId, setNewWingId] = useState(0);
    const [NewSessionId, setNewSessioinId] = useState(0);
    const [NewClassId, setNewClassId] = useState(0);
    const [NewSectionID, setNewSectionID] = useState(0);


    const [WingId, setWingId] = useState(0);
    const [SessionId, setSessioinId] = useState(localStorage.getItem("DefaultSession"));
    const [ClassId, setClassId] = useState(0);
    const [SectionID, setSectionID] = useState(0);
    const [GroupId, setGroupId] = useState(0);
    const [StudentStatusType, setStudentStatusType] = useState(0);
    const [isUpdated, setIsUpdated] = useState(false);

    const [Registration_No, SetRegistrationNo] = useState("");
    const [StudentID, setStudentID] = useState(0);
    const [Keywords, setKeywords] = useState("");
    const [Status_Type_Id, SetStatusTypeId] = useState(0);
    const [OrderBy, setOrderBy] = useState(0);

    const [DuesType, setDuesType] = useState(0);

    const [selectedStudents, setselectedStudents] = useState([]);


    // variables for promote section 
    const [promoteSessionID, setPromoteSessionID] = useState(0);
    const [promoteWingID, setpromoteWingID] = useState(0);
    const [promoteClassID, setPromoteClassID] = useState(0);
    const [promoteSectionID, setPromoteSectionID] = useState(0);
    const [validatedPromote, setValidatedPromote] = useState(false);

    useEffect(() => {

        document.title = "Attach Other Dues / Fine";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            SessionID: SessionId,
            Wing_Id: WingId,
            Class_Id: ClassId,
            Section_Id: SectionID,
            Registration_No: Registration_No,
            Student_ID: StudentID,
            Keywords: Keywords,
            Status_Type_Id: Status_Type_Id,
            Group_Id: GroupId,
            OrderBy: OrderBy,
        };

        // console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Student/SearchStudents',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                console.log(error);
            });

    }, [pageNo, pageSize]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <div className="form-check">
                                    <input key={item.Student_ID} data-type-id={item.Student_ID} className="form-check-input checkStudent" type="checkbox" value={item.Student_ID} />
                                </div>
                            </td>
                            <td>{item.Registration_No}</td>
                            <td>{item.Student_Name}</td>
                            <td>{item.Father_Name}</td>
                            <td>{moment(item.Date_Of_Birth).format('DD/MM/YYYY')}</td>
                            <td>{moment(item.Date_Of_Joining).format('DD/MM/YYYY')}</td>
                            <td>{item.Contact_Mobile}</td>
                            <td>{item.SelectedClass.SelectedWing.Wing_Name}</td>
                            <td>{item.SelectedClass.Class_Name}</td>
                            <td>{item.SelectedSection.Section_Name}</td>
                            <td>
                                <div className="status">
                                    {
                                        item.Concession_Rule_ID > 1 &&
                                        <span className="green"><i className="ri-percent-fill"></i></span>
                                    }
                                    {
                                        item.Current_Route_Id > 0 &&
                                        <span className="red"><i className="ri-bus-2-fill"></i></span>
                                    }

                                    <span className="blue"><i className="ri-emotion-normal-line"></i></span>

                                </div>
                            </td>
                            <td>
                                <ul className="inline-action">
                                    <li><NavLink to={'/Students/EditStudent/' + item.Student_ID} className=" green"><i className="ri-edit-line"></i></NavLink></li>
                                    <li><NavLink target="_blank" to={'/Students/PrintVoucher/?Class_Id=0&ConcessionRuleID=0&OrderBy=Section_Name&Section_Id=0&SessionID=0&Wing_Id=0&Description=&AdvanceMonths=0&ShowHolidayImage=false&noRedCresent=false&amount=0&Registration_No=' + item.Registration_No} className="blue"><i className="ri-printer-line"></i></NavLink></li>
                                    <li><NavLink to={'/Students/StudentLedger/' + item.Student_ID} className="red"><i className="ri-pages-line"></i></NavLink></li>
                                </ul>
                            </td>


                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="12"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        }
        else {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                pageNo: pageNo,
                pageSize: pageSize,
                SessionID: SessionId,
                Wing_Id: WingId,
                Class_Id: ClassId,
                Section_Id: SectionID,
                Registration_No: Registration_No,
                Student_ID: StudentID,
                Keywords: Keywords,
                Status_Type_Id: Status_Type_Id,
                Group_Id: GroupId,
                OrderBy: OrderBy,
            };

            // console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Student/SearchStudents',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    setLedger(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);

                })
                .catch(function (error) {
                    console.log(error);
                });


        }

        setValidated(true);
    }


    //handlePromote
    const handlePromote = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        }
        else {

            const inputsStudents = document.querySelectorAll('input.checkStudent');
            const valuesSelected = [];
            inputsStudents.forEach(input => {
                if (input.checked == true) {
                    const value = parseInt(input.value);
                    if (value > 0) {
                        valuesSelected.push(value);
                    }
                }

            });

            //console.log(valuesSelected);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                SelectedStudents: valuesSelected,
                DuesType: event.target.DuesType.value,
                FeeTypeId: event.target.FeeTypeId.value,
                Amount: event.target.amount.value,
                Description: event.target.description.value,
                Class_Id: NewClassId,
                Section_Id: SectionID
            };

            // console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'FeeSystem/AttachOtherDues',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            //console.log(data);

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setIsUpdated(true);
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    console.log(error);
                });


        }

        setValidatedPromote(true);
    }

    return (
        <>
            <FeeSystemMenu />
            <ToastContainer />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/FeeSystem/FeeSystemDashboard">Fee System</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Attach Dues</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Attach Other Dues / Fines</div>
                            </div>

                            <div className="col-md-6">
                                <div className="page-right-actions">
                                    <NavLink to="/students/AddStudent" className="button button-white button-icon">
                                        Add Students
                                        <i className="ri-add-fill"></i>
                                    </NavLink>
                                    <button
                                        onClick={() => {
                                            setShowFilters((prevIsActive) => !prevIsActive);
                                        }}
                                        className="button button-white button-icon">
                                        {showFilters ? 'Hide Filters' : 'Show Filters'}
                                        <i className="ri-filter-3-line"></i>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={`collapse ${showFilters ? 'show' : ''} `} id="collapseFilteres">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="page-content">
                            <div className="container">
                                <div className="card mb-2">
                                    <div className="card-body p-md-4">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Select Session:</label>
                                                    <select className="form-select" onChange={(e) => setSessioinId(e.target.value)} name="SessionID">
                                                        <option value="0">---All---</option>
                                                        <SessionsDropDown selectedSession={SessionId} />
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Select Wing:</label>
                                                    <select name="Wing_Id" onChange={(e) => setNewWingId(e.target.value)} className="form-select">
                                                        <option value="0">---All---</option>
                                                        <WingsDropDown />
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Select Class:</label>
                                                    <select className="form-select" onChange={(e) => setNewClassId(e.target.value)} name="Class_ID">
                                                        <option value="0">Please Select</option>
                                                        {
                                                            SessionId != 0 && NewWingId != 0 &&
                                                            <ClassesDropDown SessionID={SessionId} WingID={NewWingId} />
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Select Section:</label>
                                                    <select className="form-select" onChange={(e) => setSectionID(e.target.value)} name="Section_Id">
                                                        <option value="0">Please Select</option>
                                                        {
                                                            NewClassId !== 0 &&
                                                            <SectionDropDown ClassID={NewClassId} />
                                                        }

                                                    </select>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Registration No:</label>
                                                    <input type="text" name="Reg_No" onChange={(e) => SetRegistrationNo(e.target.value)} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Student Id:</label>
                                                    <input type="text" name="Student_ID" onChange={(e) => setStudentID(e.target.value)} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Keywords:</label>
                                                    <input type="text" name="Keywords" onChange={(e) => setKeywords(e.target.value)} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Student Status:</label>
                                                    <select className="form-select" onChange={(e) => SetStatusTypeId(e.target.value)} name="statustype">
                                                        <option value="">---All---</option>
                                                        {
                                                            <StudentStatusDropDown />
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group mb-3">
                                                    <label>Select Group:</label>
                                                    <select className="form-select" onChange={(e) => setGroupId(e.target.value)} name="groupName">
                                                        <option value="">---All---</option>
                                                        {
                                                            <StudentGroupDropDown />

                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Order by:</label>
                                                    <select name="OrderBy" onChange={(e) => setOrderBy(e.target.value)} className="form-select">
                                                        <option Selected Value="Student_Name">Student Name</option>
                                                        <option Value="Registration_No">Registration No</option>
                                                        <option Value="Wing_Name">Wing Name</option>
                                                        <option Value="Class_Name">Class</option>
                                                        <option Value="Student_ID">Student ID Asc</option>
                                                        <option Value="Student_ID desc">Student ID Desc</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-2 offset-md-4">
                                                <div className="form-group">
                                                    <button type="submit" value="Search" onClick={handleSubmit} className="button button-primary mt-4 w-100">Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body">
                                {/* <div className="text-end mb-2 me-3">
                                    <b>{{totalRecords}} &nbsp; Records Found!</b>

                                </div> */}
                                <table className="table table-theme">
                                    <thead>
                                        <tr>
                                            <th></th>

                                            <th>Reg No</th>
                                            <th>Name</th>
                                            <th>Father Name</th>
                                            <th>DOB</th>
                                            <th>DOJ</th>
                                            <th>Mobile</th>
                                            <th>Wing</th>
                                            <th>Class</th>
                                            <th>Section</th>
                                            <th>Tags</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loading ?
                                                <tr>
                                                    <td className="text-center" colspan="12">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </td>
                                                </tr>
                                                :
                                                <>
                                                    {renderLedger()}
                                                </>

                                        }

                                    </tbody>
                                </table>
                                {
                                    totalRecords > 10 &&
                                    <div className="pagination-wrap">

                                        <div className="row align-items-center">
                                            <div className="col-md-2 form_default">
                                                <div className="d-flex align-items-center">
                                                    <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                    <div className="form-group">
                                                        <select className="form-select"
                                                            style={{ width: "100px" }}
                                                            onChange={(e) => {
                                                                setPageNo(1);
                                                                setPageSize(parseInt(e.target.value));


                                                            }}>
                                                            <option value="10" selected>10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <Pagination
                                                pageNo={pageNo}
                                                pageSize={pageSize}
                                                totalRecords={totalRecords}
                                                onPageChange={handlePageChange}
                                            />
                                        </div>
                                    </div>

                                }

                            </div>
                        </div>
                    </div>
                    <div className="container">

                        <Form noValidate validated={validatedPromote} onSubmit={handlePromote}>
                            <div className="card mt-2">
                                <div className="card-body p-4">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div class="form-check">
                                                <input class="form-check-input" value="1" type="radio" name="DuesType" id="flexRadioDefault1" required checked />
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                    Attach Dues to Selected Students
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div class="form-check">
                                                <input class="form-check-input" value="2" type="radio" name="DuesType" id="flexRadioDefault2" required />
                                                <label class="form-check-label" for="flexRadioDefault2">
                                                    Attach Dues to Selected Sesction Only
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-4">
                                            <div class="form-check">
                                                <input class="form-check-input" value="3" type="radio" name="DuesType" id="flexRadioDefault3" required />
                                                <label class="form-check-label" for="flexRadioDefault3">
                                                    Attach Dues to Selected Class Only
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div class="form-check">
                                                <input class="form-check-input" value="4" type="radio" name="DuesType" id="flexRadioDefault4" required />
                                                <label class="form-check-label" for="flexRadioDefault4">
                                                    Attach Dues to All Students
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Dues Type:</label>
                                                <select className="form-select" name="FeeTypeId" required>
                                                    <option value="">Please Select</option>
                                                    <FeeTypesDropDown />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Amount:</label>
                                                <input type="text" name="amount" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Description:</label>
                                                <input type="text" name="description" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-2 offset-md-1">
                                            <button type="submit" className="button button-primary w-100 mt-4">Attach Dues</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Form>
                    </div>

                </div>
            </div>
        </>
    );
}

export default AddOtherDuesFine;