import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import Stock_navbar from './StockNavbar';
import { scrollToTop } from '../Shared/ScrollOnTop';

function EditStockItems(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [StockItem, setStockItem] = useState({});
    const [RegisterType, setRegisterType] = useState("");



    let params = useParams();
    const [StockID, setStockID] = useState(params.id);

    let navigate = useNavigate();

    useEffect(() => {
        
        document.title = "Add/Update Stock Items";
        setLoading(true);
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            ID: StockID

        };
        console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Stock/GetStockItemByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        console.log(StockItem)

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setStockItem(response.data.StockItem);

                }


                setLoading(false);


            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
MemberType: MemberType,
                Id: StockID,
                Stock_Code: event.target.txtStock_Code.value,
                Item_Name: event.target.txtItem_Name.value,
                Register_Type: event.target.txtRegister_Type.value,
                Current_Balance: "0",
                Price_Per_Unit: event.target.txtPrice_Per_Unit.value,
                Item_Description: event.target.txtItem_Description.value,
                Main_Custodian: event.target.txtMain_Custodian.value,
                Register_Ref: event.target.txtRooRegister_Ref.value,
            };

            console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'Stock/UpdateStockItem',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setValidated(true);
    };

    return (
        <>
            <Stock_navbar />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/StockManagement/StocksDashboard">Stocks</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Edit Stock Items</li>
                                </ol>
                            </nav>
                            <div className="page-title">Edit Stock Items</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/StockManagement/ManageStockCategory" className="button button-white button-icon">
                                    Manage Stock Items
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="card-body p-md-4">
                                    {
                                        loading ?
                                            <Spinner animation="border" role="status"></Spinner>

                                            :
                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                <div className="row align-items-center">
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="">Stock Code:</label>
                                                            <input type="text" defaultValue={StockItem.Stock_Code} className="form-control" required name="txtStock_Code" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Item Name:</label>
                                                            <input type="text" defaultValue={StockItem.Item_Name} className="form-control" required name="txtItem_Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Register Type:</label>

                                                            <select className="form-select" defaultValue={StockItem.Register_Type} name="txtRegister_Type" required="">
                                                                <option value="Fixed Assets">Fixed Assets</option>
                                                                <option value="Current Assets">Current Assets</option>
                                                                <option value="Consumeable / General">Consumable/General</option>
                                                            </select>
                                                            {/* <input type="text" defaultValue={StockItem.Register_Type} className="form-control" required name="txtRegister_Type" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Price Per Unit:</label>
                                                            <input type="text" defaultValue={StockItem.Price_Per_Unit} className="form-control" required name="txtPrice_Per_Unit" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Register Ref:</label>
                                                            <input type="text" defaultValue={StockItem.Register_Ref} className="form-control" required name="txtRooRegister_Ref" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Main Custodian:</label>
                                                            <input type="text" defaultValue={StockItem.Main_Custodian} className="form-control" required name="txtMain_Custodian" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Item Detail:</label>
                                                            <input type="text" defaultValue={StockItem.Item_Description} className="form-control" required name="txtItem_Description" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2 mt-md-2">
                                                        <div className="form-group ">
                                                            {
                                                                loading ?
                                                                    <Spinner animation="border" role="status"></Spinner>

                                                                    :
                                                                    <button type="submit" value="Save" className="button button-primary w-100 ">SAVE</button>

                                                            }

                                                        </div>
                                                    </div>

                                                </div>
                                            </Form>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditStockItems;
