import React from 'react';
import AnnualMenu from './AnnualMenu';
import { NavLink } from 'react-router-dom';
import { scrollToTop } from '../Shared/ScrollOnTop';

function AddClassesInSessions(props) {
    return (
        <>
            <AnnualMenu />
            <div className="container body-content">

                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-8">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Home/Index">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/AnnualTasks/AnnualTasksDashboard">Annual Tasks </NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Classes In Sessions</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Generate Classes And Sections In New Academic Session</div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body p-md-4">
                                <div className="bg-light p-md-4 rounded-2">
                                    <p>This Module will generate new Classes and Sections for the New Academic Year.</p>
                                    <b>Pre-Requirements:</b>
                                    <p>
                                        Add a new session in the tblacademicsessions table and set its id in web.config file as a currect session.
                                        Click on the button below to generate new classes in current session based on previous classes.
                                    </p>
                                    <div className="row">
                                        <div className="col-md-12 text-end">
                                            <input type="button" value="Generate Classes" className="button button-primary"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default AddClassesInSessions;