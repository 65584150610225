import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import StaffAtMenu from "./StaffAtMenu";
import { NavLink } from "react-router-dom";
import EmployeeTypeDropDown from "../Shared/EmployeeTypeDropDown";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { scrollToTop } from '../Shared/ScrollOnTop';
import maleprofile from "../../assets/images/maleprofile.png"
import femaleprofile from "../../assets/images/femaleprofile.png"
import moment from 'moment';

function MarkAttendance(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [validated, setValidated] = useState(false);
  const [EmployeeTypeId, setEmployeeTypeId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [AttendanceDate, setAttendanceDate] = useState();
  //const imagePath = 'https://api.nscholar.com/staff_images/';

  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const employeetypeid = urlParams.get("employeetypeid");
    const adate = urlParams.get("adate");

    setAttendanceDate(adate);
    setEmployeeTypeId(employeetypeid);

    if (employeetypeid && adate) {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        StaffTypeID: employeetypeid,
        AttendanceDate: adate,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "StaffAttendance/GetStaffAttendance",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          console.log(response);
          if (response.data.status_code == 1) {
            setLedger(response.data.ListofRecords);
            setLoading(false);
            setTotalRecords(response.data.totalRecords);
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  }, []);

  const handleSubmit = (event) => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      StaffTypeID: EmployeeTypeId,
      AttendanceDate: AttendanceDate,
    };

    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "StaffAttendance/GetStaffAttendance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    console.log(data);
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    setValidated(true);
  };

  const handleAttendanceChange = (recordIndex, newStatus) => {
    // Update attendance status in the component's state
    const updatedRecords = [...listOfLedger];
    updatedRecords[recordIndex].Absent_Leave = newStatus;
    setLedger(updatedRecords);
  };

  const handleSubmitAttendance = (event) => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      StaffTypeID: EmployeeTypeId,
      AttendanceDate: AttendanceDate,
      ListofRecords: listOfLedger,
    };

    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "StaffAttendance/UpdateAttendance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    setValidated(true);
  };

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        console.log(item);
        return (
          <div key={index} className="col-md-2">
            <div className="card attendence">
              <div className="card-body d-flex flex-column justify-content-between">
                <div>
                  <img src={config.StaffImagePath + item.ID + ".jpg"}

                    onError={(e) => {
                      if (item.Gender === true) {
                        e.target.src = maleprofile;

                      }
                      else
                        e.target.src = femaleprofile;
                    }}


                  />
                  <h4>{item.Name}</h4>
                  <span>({item.Code})</span>
                </div>
                <div className="radio-button">
                  <div className="radio staff-attendence">
                    <input
                      type="radio"
                      id="one"
                      label="P"
                      name={"attendance_" + item.ID}
                      value="P"
                      checked={"P" === item.Absent_Leave}
                      onChange={() => handleAttendanceChange(index, "P")}
                      className="rabio-btn btn-1"
                    />
                    <input
                      type="radio"
                      id="two"
                      label="CL"
                      name={"attendance_" + item.ID}
                      value="CL"
                      checked={"CL" === item.Absent_Leave}
                      onChange={() => handleAttendanceChange(index, "CL")}
                      className="rabio-btn btn-2"
                    />
                    <input
                      type="radio"
                      id="three"
                      label="AB"
                      name={"attendance_" + item.ID}
                      value="AB"
                      checked={"AB" === item.Absent_Leave}
                      onChange={() => handleAttendanceChange(index, "AB")}
                      className="rabio-btn btn-3"
                    />

                    <input
                      type="radio"
                      id="four"
                      label="ML"
                      name={"attendance_" + item.ID}
                      value="ML"
                      checked={"ML" === item.Absent_Leave}
                      onChange={() => handleAttendanceChange(index, "ML")}
                      className="rabio-btn btn-4"
                    />

                    <input
                      type="radio"
                      id="four"
                      label="MAT"
                      name={"attendance_" + item.ID}
                      value="MAT"
                      checked={"MAT" === item.Absent_Leave}
                      onChange={() => handleAttendanceChange(index, "MAT")}
                      className="rabio-btn btn-5"
                    />

                    <input
                      type="radio"
                      id="four"
                      label="LWOP"
                      name={"attendance_" + item.ID}
                      value="LWOP"
                      checked={"LWOP" === item.Absent_Leave}
                      onChange={() => handleAttendanceChange(index, "LWOP")}
                      className="rabio-btn btn-6"
                    />

                    <input
                      type="radio"
                      id="four"
                      label="ETC"
                      name={"attendance_" + item.ID}
                      value="ETC"
                      checked={"ETC" === item.Absent_Leave}
                      onChange={() => handleAttendanceChange(index, "ETC")}
                      className="rabio-btn btn-7"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="8">
            <h4
              className=""
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <StaffAtMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/StaffAttendance/StaffAttendanceDashboard">
                        Staff Attendance
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Mark Attendence
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Mark Staff Attendence</div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body card-form-wrap">
                <div className="row align-items-center gy-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Select Date:</label>
                      <input
                        className="form-control"
                        id="selectedDate"
                        max={moment().format(config.date_format_input)}
                        value={AttendanceDate}
                        onChange={(e) =>
                          setAttendanceDate(e.target.value)
                        }
                        type="date"
                        name="date"

                      />

                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Department</label>
                      <select
                        onChange={(e) =>
                          setEmployeeTypeId(e.target.value)
                        }
                        value={EmployeeTypeId}
                        className="form-select"
                        required
                      >
                        <option value="">Please Select</option>
                        <EmployeeTypeDropDown />
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2 offset-md-4 text-end">
                    <div className="form-group">
                      {loading ? (
                        <Spinner
                          animation="border"
                          role="status"
                        ></Spinner>
                      ) : (
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          value="Load Staff"
                          className="button button-primary w-100"
                        >
                          Load Staff
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="row align-items-center g-3 mt-2">
              {renderLedger()}
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="card p-3 mt-2">
              <div className="row align-items-center">
                <div className="col-md-2 offset-md-8">
                  <NavLink
                    target="_blank"
                    to={`/StaffAttendance/PrintMarkedAttendance/?date=${AttendanceDate}&Department=${EmployeeTypeId}`}
                    className="button button-primary w-100"
                  >
                    Print
                  </NavLink>
                </div>
                <div className="col-md-2">
                  {loading ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    <button
                      type="submit"
                      onClick={handleSubmitAttendance}
                      value="Save Attendence"
                      className="button button-primary w-100"
                    >
                      Save Attendence
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarkAttendance;
