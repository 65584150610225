import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import { toast, ToastContainer } from 'react-toastify';
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from 'react-router-dom';


function ShowExam(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Exam, setExam] = useState({});



    let params = useParams();
    // const [StudentID, setStudentID] = useState(params.id);

    let navigate = useNavigate();

    useEffect(() => {

        if ([props.ExamID] > 0) {

            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
MemberType: MemberType,
                ID: props.ExamID

            };
            setLoading(true);
            var api_config = {
                method: 'post',
                url: config.base_url + 'Exam/GetExamByID',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status_code == 1) {
                        setExam(response.data);

                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        }
        else {
            setLoading(false);
        }

    }, [props.ExamID]);


    return (
        <>
            <div className="row mb-md-2">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-md-4">
                            <div className="row align-items-center">
                                <div className="col-md-3">
                                    <div className="form-group mb-3">
                                        <label for="" className="">
                                            Exam ID:
                                        </label>
                                        <label className="label-gray"> {Exam.ID} </label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mb-3">
                                        <label for="" className="">Exam Code:</label>

                                        <label className="label-gray"> {Exam.Exam_Code} </label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mb-3">
                                        <label for="" className="">
                                            Exam Name:
                                        </label>

                                        <label className="label-gray"> {Exam.Name} </label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mb-3">
                                        <label for="" className="">
                                            Exam Title:
                                        </label>

                                        <label className="label-gray">  {Exam.Print_Title} </label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mb-3">
                                        <label for="" className="">
                                            Start Date:
                                        </label>

                                        <label className="label-gray"> {moment(Exam.Start_Date).format('DD/MM/YYYY')}  </label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mb-3">
                                        <label for="" className="">End Date:</label>

                                        <label className="label-gray">  {moment(Exam.End_Date).format('DD/MM/YYYY')}</label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mb-3">
                                        <label for="" className="">
                                            Current Status:
                                        </label>
                                        <label className="label-gray"> {Exam.Current_Status} </label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mb-3">
                                        <label for="" className="">
                                            Session:
                                        </label>
                                        <label className="label-gray"> {Exam.SessionName} </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ShowExam;