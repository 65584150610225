import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Monthly_navbar from './Monthlytasks_navbar';
import { Form } from "react-bootstrap";
import MonthsDropDown from '../Shared/MonthsDropDown';
import YearsDropDown from '../Shared/YearsDropDown';
import moment from "moment";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { scrollToTop } from '../Shared/ScrollOnTop';
import config from '../../Config';
const StartNewMonth = () => {
document.title="DPS - School Management System"
    const [Month, setMonth] = useState(null);
    const [Year, setYear] = useState(null);
    const [MonthForConcessions, setMonthForConcessions] = useState(null);
    const [YearForConcessions, setYearForConcessions] = useState(null);


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [SessionID, setSessionID] = useState(localStorage.getItem("DefaultSession"));

    const [TransportFee, setTransportFee] = useState(false);

    const [loading, setLoading] = useState(false);


    const handleMonth = () => {
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            SelectedMonth: Month,
            FYear: Year,
            SessionID: SessionID,
            AddTransportFee:TransportFee
        };
    
        setLoading(true);
        const api_config = {
            method: 'post',
            url: config.base_url + '/api/FeeSystem/StartNewMonth',
            // url:"http://192.168.1.99/api/FeeSystem/StartNewMonth",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
    
        axios(api_config)
            .then(function (response) {
                if(response.data.status_code==1){

                    setLoading(false);
                }
                else {
                    toast.error(response.data.status_Message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }



    const handleConcessions = ()=>{
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            SelectedMonth: MonthForConcessions,
            FYear: YearForConcessions,
            SessionID: SessionID
        };
        setLoading(true);
        const api_config = {
            method: 'post',
             url: config.base_url + 'FeeSystem/ApplyConcession',
            // url:"http://192.168.1.99/api/FeeSystem/StartNewMonth",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
    
        axios(api_config)
            .then(function (response) {
                
                console.log(" response ",response.data);
                if(response.data.status_code==1){

                    setLoading(false);
                }
                else {
                    toast.error(response.data.status_Message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }












return (
    <>
        <Monthly_navbar />
        <div className="page-head">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item"><Link to="/MonthlyTasks/MonthlyTasksDashboard">Monthly Task</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Start New Month</li>
                            </ol>
                        </nav>
                        <div className="page-title">Start New Month</div>
                    </div>
                    <div className="col-md-6">
                        <div className="page-right-actions">
                            <Link to="/MonthlyTasks/ManageMonth" className="button button-white button-icon">
                                Manage Month
                                <i className="ri-list-check"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="page-content" ng-controller="MonthlyTasksController">
            <div className="container">
                <div className="card mb-2">
                    <div className="card-body p-md-4">
                        <div className="bg-light p-md-4">
                            <p className="mb-1">
                                Start New Month is a monthly task and will be perform to add dues in the students ledger, just verify the date and click on the button below, this will update the ledgers with current feeses mentioned as monthly fee in classes defination.
                            </p>
                            <h5><b>Note:</b></h5>
                            <p className="mb-0">This Process may take a little time to post enteries in the system so do not press the button twice and wait for its completion.</p>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container">
                <div className="card">
                    <div className="card-body p-md-4">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <div className="row align-items-center">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Current Month</label>
                                            <select className="form-select"
                                             onChange={(e) => setMonth(e.target.value)}
                                            >
                                                <option value="">Please Select</option>
                                                <MonthsDropDown />
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>
                                                Current Year
                                            </label>
                                            <select className="form-select"
                                            onChange={(e) => setYear(e.target.value)}
                                            >
                                                <option value="">Please Select</option>
                                                <YearsDropDown />

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>
                                                Today
                                            </label>
                                            <input disabled className="form-control" value={moment().format('h:mm A')} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <Form.Group className="mt-4">
                                            <Form.Check
                                                required
                                                label="Add Transport Fee:"
                                                feedbackType="invalid"
                                                checked={TransportFee}
                                                onChange={(e)=>setTransportFee(e.target.checked)}

                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-3 text-end">
                                        <button type="button" className="button button-primary " onClick={handleMonth} style={{paddingLeft:"28px",paddingRight:"28px"}} >Start This Month </button>
                                    </div>
                                    {/* <div className="col-md-12 mt-2">
                                            <h6><b>Posting Details:</b></h6>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    Students With 500 Fine
                                                </label>
                                                <input disabled className="form-control" value=" postingDetails.StudentsWith500Fine" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    Students With 50 Fine
                                                </label>
                                                <input disabled className="form-control" value="postingDetails.StudentsWith50Fine" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    Unpaid in Last Month
                                                </label>
                                                <input disabled className="form-control" value="postingDetails.UnpaidInLastMonth" />
                                            </div>
                                        </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


















            <div className="container mt-2">
                <div className="card">
                    <div className="card-body p-md-4">
                        <div className="row align-items-center">
                            <div className="col-md-12 ">
                                <div className="row ">
                                    <div className="col-md-2">
                                        {/* <div className="form-group">
                                            <label>Current Month</label>
                                            <select className="form-select"
                                             onChange={(e) => setMonth(e.target.value)}
                                            >
                                                <option value="">Please Select</option>
                                                <MonthsDropDown />
                                            </select>
                                        </div> */}
                                    </div>
                                    <div className="col-md-2">
                                        {/* <div className="form-group">
                                            <label>
                                                Current Year
                                            </label>
                                            <select className="form-select"
                                            onChange={(e) => setYear(e.target.value)}
                                            >
                                                <option value="">Please Select</option>
                                                <YearsDropDown />

                                            </select>
                                        </div> */}
                                    </div>
                                    <div className="col-md-2">
                                        {/* <div className="form-group">
                                            <label>
                                                Today
                                            </label>
                                            <input disabled className="form-control" value={moment().format('h:mm A')} />
                                        </div> */}
                                    </div>
                                    <div className="col-md-3">
                                        {/* <Form.Group className="mt-4">
                                            <Form.Check
                                                required
                                                label="Add Transport Fee:"
                                                feedbackType="invalid"
                                                checked={TransportFee}
                                                onChange={(e)=>setTransportFee(e.target.checked)}

                                            />
                                        </Form.Group> */}
                                    </div>
                                    <div className="col-md-3 text-end ">
                                        <button type="button" className="button button-primary"  >Post Transport Fee </button>
                                    </div>
                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container mt-2">
                <div className="card">
                    <div className="card-body p-md-4">
                        <div className="row align-items-center">
                            <div className="col-md-12 ">
                                <div className="row ">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Current Month</label>
                                            <select className="form-select"
                                             onChange={(e) => setMonthForConcessions(e.target.value)}
                                            >
                                                <option value="">Please Select</option>
                                                <MonthsDropDown />
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>
                                                Current Year
                                            </label>
                                            <select className="form-select"
                                            onChange={(e) => setYearForConcessions(e.target.value)}
                                            >
                                                <option value="">Please Select</option>
                                                <YearsDropDown />

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        
                                    </div>
                                    <div className="col-md-3">
                                      
                                    </div>
                                    <div className="col-md-3 text-end  ">
                                        <button type="button" className="button button-primary"  onClick={handleConcessions} >Apply Concessions</button>
                                    </div>
                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container mt-2">
                <div className="card">
                    <div className="card-body p-md-4">
                        <div className="row align-items-center">
                            <div className="col-md-12 ">
                                <div className="row ">
                                    <div className="col-md-2">
                                        {/* <div className="form-group">
                                            <label>Current Month</label>
                                            <select className="form-select"
                                             onChange={(e) => setMonth(e.target.value)}
                                            >
                                                <option value="">Please Select</option>
                                                <MonthsDropDown />
                                            </select>
                                        </div> */}
                                    </div>
                                    <div className="col-md-2">
                                        {/* <div className="form-group">
                                            <label>
                                                Current Year
                                            </label>
                                            <select className="form-select"
                                            onChange={(e) => setYear(e.target.value)}
                                            >
                                                <option value="">Please Select</option>
                                                <YearsDropDown />

                                            </select>
                                        </div> */}
                                    </div>
                                    <div className="col-md-2">
                                        {/* <div className="form-group">
                                            <label>
                                                Today
                                            </label>
                                            <input disabled className="form-control" value={moment().format('h:mm A')} />
                                        </div> */}
                                    </div>
                                    <div className="col-md-3">
                                        {/* <Form.Group className="mt-4">
                                            <Form.Check
                                                required
                                                label="Add Transport Fee:"
                                                feedbackType="invalid"
                                                checked={TransportFee}
                                                onChange={(e)=>setTransportFee(e.target.checked)}

                                            />
                                        </Form.Group> */}
                                    </div>
                                    <div className="col-md-3 text-end ">
                                        <button type="button" className="button button-primary " style={{paddingLeft:"36px",paddingRight:"36px"}}>Delete Posting </button>
                                    </div>
                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>








        </div>


    </>
);
}

export default StartNewMonth;
