import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment, { months } from "moment";
import PrintHeader from '../../General/PrintHeader';
import Page from "../../General/Page";
import { calculateColumnTotal, getMonthName } from '../../General/Utility';

const PrintBankSalalryList = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);
    const [selectedYear, setselectedYear] = useState("");
    const [selectedMonth, setselectedMonth] = useState("");
    const [TotalDeductions, setTotalDeductions] = useState(0);
    const [TotalLoans, setTotalLoans] = useState(0);
    const [TotalGross, setTotalGross] = useState(0);
    const [TotalNet, setTotalNet] = useState(0);

    useEffect(() => {
        document.title="DPS School Management System"

        setLoading(true);
        // Get the URL parameters
        const urlParams = new URLSearchParams(window.location.search);

        // Retrieve individual parameter values
        const year = urlParams.get('year');
        const Month = urlParams.get('month');
        const Department = urlParams.get('Department');
        const loans = urlParams.get('loans');
        const Deductions = urlParams.get('Deductions');

        setselectedYear(year);
        setselectedMonth(Month);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: 1,
            pageSize: 1000,
            SchoolID: 1,
            SelectedMonth: Month,
            FYear: year,
            EmployeeTypeId: Department,
            OrderBy: "ID",
            Loans: loans,
            Deduction: Deductions,
        };



        // console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/GetBankSalaryList',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        console.log(data);

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setTotalRecords(response.data.totalRecords);
                setTotalDeductions(response.data.TotalDeductions);
                setTotalLoans(response.data.TotalLoans);
                setTotalGross(response.data.TotalGross);
                setTotalNet(response.data.TotalNet);
                setLoading(false);

            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);

    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            let count = 1;
            let countbreak = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index} className={index > 0 && index % 38 === 0 ? 'page-break' : ''}>
                            <td>
                                {count++}
                            </td>
                            <td>{item.Code}</td>
                            <td>{item.Name}</td>
                            <td>{item.Account_No}</td>
                            <td>{item.NetSalary}</td>

                        </tr>

                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="20"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }


    return (
        <>
            <Page orientation="potrait" size="a4">
                <table className="table table-sm table-bordered table-print">
                    <thead>
                        <tr>
                            <td colspan="5">
                                <PrintHeader title={`BANK SALARY LIST FOR THE MONTH OF ${getMonthName(selectedMonth)} ${selectedYear} `} />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Sr.#</th>
                            <th>Code</th>
                            <th>Staff Name </th>
                            <th>Account No. </th>
                            <th>Net Pay. </th>
                        </tr>
                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colspan="5">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                <>
                                    {renderLedger()}
                                    {
                                        <tr>
                                            <th className="text-end" colSpan={4}>Total Net Pay:</th>
                                            <th className="" colSpan={1}>{calculateColumnTotal(listOfLedger, 'NetSalary')}</th>
                                        </tr>
                                    }
                                </>

                        }

                    </tbody>
                </table>


            </Page>
        </>
    );
}

export default PrintBankSalalryList;
