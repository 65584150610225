import React from 'react';
import StaffAtMenu from './StaffAtMenu';
import { NavLink } from 'react-router-dom';
import staff from '../../assets/images/dashboard_icon/staff.svg'
import present from '../../assets/images/dashboard_icon/present.svg'
import not_present from '../../assets/images/dashboard_icon/not_present.svg'
import { scrollToTop } from '../Shared/ScrollOnTop';

function StaffAttendanceDashboard(props) {
    return (
        <>
            <StaffAtMenu />
            <div class="container body-content">
                <div class="page-head">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li class="breadcrumb-item active" aria-current="page">Staff Attendance</li>
                                    </ol>
                                </nav>
                                <div class="page-title">Staff Attendance</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row gy-3 align-items-center">
                        <div class="col-md-4 col-sm-6">
                            <div class="card m-card">
                                <div class="card-body text-center">
                                    <img src={staff} class="pb-3" alt="" />
                                    <p class="m-2">Total Staff</p>
                                    <h4 class="m-2">448</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="card m-card">
                                <div class="card-body text-center">
                                    <img src={present} class="pb-3" alt="" />
                                    <p class="m-2">Present</p>
                                    <h4 class="m-2">235</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="card">
                                <div class="card-body text-center">
                                    <img src={not_present} class="pb-3" alt="" />
                                    <p class="m-2">Not Present</p>
                                    <h4 class="m-2">15</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StaffAttendanceDashboard;