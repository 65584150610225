import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import WingsDropDown from '../Shared/WingsDropDown';
import SessionsDropDown from "../Shared/SessionsDropDown";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Pagination from "../General/Pagination";
import ClassesDropDown from "../Shared/ClassesDropDown";
import TeacherMenu from "./TeacherMenu";
import { scrollToTop } from '../Shared/ScrollOnTop';

const ManageSection = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));


    const [SessionID, setSessionId] = useState(localStorage.getItem("DefaultSession"));
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);


    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);



    useEffect(() => {

        document.title = "Manage Sections Allocated to Teacher";

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            PageNo: pageNo,
            PageSize: pageSize,
            SessionID: SessionID,
            TeacherID: UserID,
        };

        console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Sections/GetSectionsByTeacherID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code != 0) {
                    setLedger(response.data.ListofRecords);
                    setTotalRecords(response.data.totalRecords)
                }
                else {
                    setLedger(null);
                    setTotalRecords(0)
                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }, [pageNo, pageSize, SessionID]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.Section_ID}</td>
                            <td>{item.Section_Code}</td>
                            <td>{item.Section_Short_Name}</td>
                            <td>{item.Section_Name}</td>
                            <td>{item.Section_Strength_Limit}</td>
                            <td>{item.SelectedClass.Class_Name}</td>
                            <td>{item.Section_Teacher_Name}</td>
                            <td className="text-center">
                                <div class="dropdown dropdown-table-actions">
                                    <Link href="javascript:;" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" class="">
                                        <i class="ri-more-fill"></i>
                                    </Link>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                        <li><Link to={`/reports/SectionWiseList/${item.Section_ID}`} target="_blank" className="dropdown-item"><i class="ri-printer-line"></i>Print Student List</Link></li>
                                        <li><Link to={`/PrintReports/RPTAwardList/${item.Section_ID}`} target="_blank" className="dropdown-item"><i class="ri-printer-line"></i>Print Award List</Link></li>
                                        <li><Link class="dropdown-item" ><i class="ri-printer-line"></i>Print Attendence Sheet</Link></li>
                                        <li><Link class="dropdown-item"><i class="ri-printer-line"></i>Print Absentee List</Link></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    return (
        <>
            <ToastContainer />
            <TeacherMenu />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/teacher/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage Sections</li>
                                </ol>
                            </nav>
                            <div className="page-title">Manage Sections</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <table className="table table-theme table-hover">
                                <thead>
                                    <tr>
                                        <th className="tex-end" colspan={8}>{totalRecords} Records Found!</th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Section ID
                                        </th>
                                        <th>
                                            Section Code
                                        </th>
                                        <th>
                                            Section Short Name
                                        </th>
                                        <th>
                                            Section Name
                                        </th>
                                        <th>
                                            Strength Limit
                                        </th>
                                        <th>
                                            Class Name
                                        </th>
                                        <th>
                                            Teacher Name
                                        </th>
                                        <th className="">Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td className="text-center" colspan="5">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {renderLedger()}
                                            </>

                                    }
                                </tbody>
                            </table>
                            {
                                totalRecords > pageSize &&
                                <div className="pagination-wrap">

                                    <div className="row align-items-center">
                                        <div className="col-md-2 form_default">
                                            <div className="d-flex align-items-center">
                                                <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                <div className="form-group">
                                                    <select className="form-select"
                                                        style={{ width: "100px" }}
                                                        onChange={(e) => {
                                                            setPageNo(1);
                                                            setPageSize(parseInt(e.target.value));


                                                        }}>
                                                        <option value="10" selected>10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <Pagination
                                            pageNo={pageNo}
                                            pageSize={pageSize}
                                            totalRecords={totalRecords}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                </div>

                            }

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ManageSection;
