import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import PayrollMenu from './PayrollMenu';
import EmployeeDropDown from "../Shared/EmployeeDropDown";
import Select from 'react-select';
import { scrollToTop } from '../Shared/ScrollOnTop';

function EditOtherLoanDeduction(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [otherDeduction, setOtherDeduction] = useState({});
    const [LoanDeduction, setLoanDeduction] = useState({});
    const [EmployeeID, setEmployeeID] = useState(0);
    const [EmployeeName, setEmployeeName] = useState("");
    const [employes, setEmployees] = useState([]);


    let params = useParams();
    const [otherDeductionID, setOtherDeductionID] = useState(params.id);

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Add/Update Other Deduction";

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ID: otherDeductionID

        };
        console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'OtherDeduction/GetOtherDeductionByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        setLoading(true);
        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setOtherDeduction(response.data.OtherDeduction);
                    setEmployeeName(response.data.OtherDeduction.Selected_Staff?.Name)
                    setEmployeeID(response.data.OtherDeduction.Employee_ID);
                    console.log("id",response.data.OtherDeduction.Employee_ID);
                    setLoading(false);
                }





            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                ID:otherDeductionID,
                Employee_ID: EmployeeID,
                Dated: event.target.txtDated.value,
                Deduction: event.target.txtDeduction.value,
                Description: event.target.txtDescription.value,

            };

            console.log("object",data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'OtherDeduction/UpdateOtherDeduction',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status_code == 1) {

                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                    setLoading(false);


                })
                .catch(function (error) {
                    setLoading(false);
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });

        }

        setValidated(true);
    };

    useEffect(() => {
        setLoading(true);
        const apiData = {
            AccessKey: AccessKey,
            Is_Resigned: false,
            UserID: UserID,
            MemberType: MemberType,
            SchoolID: 1,
            PageNo: 1,
            PageSize: 10000
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/SearchEmployees',
            headers: {
                'Content-Type': 'application/json'
            },
            data: apiData
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                setEmployees(response.data.ListofRecords);
                setLoading(false);
            })
            .catch(function (error) {
                // console.log(error);
            });

    }, []);
    const dropdownOptions = employes.map(item => ({
        value: item.ID,
        label: item.Name + '(' + item.Code + ')',
        code: item.Code
        
    }));

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: '100%', // Set your desired width here
        }),
    };

    const handleDropdownChange = (selectedOption) => {

        //let label = selectedOption.label;
        setEmployeeID(selectedOption.value);


    };
    const defaultValue = {
        value: EmployeeID,
        label: EmployeeName,
    };

    return (
        <>
            <PayrollMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"> <Link to="/PayRoll/PayrollDashboard">Payroll</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Edit Other Deduction</li>
                                </ol>
                            </nav>
                            <div className="page-title">Edit Other Deduction</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <Link to="/PayRoll/ManageOtherLoanDeductions" className="button button-white button-icon">
                                    Manage Other Deductions
                                    <i className="ri-list-check"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-md-4">
                                    {
                                        loading ?
                                            'Loading...'
                                            :
                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                <div className="row align-items-center">
                                                    <div className="col-md-3">
                                                        {/* <div className="form-group mb-3">
                                                    <label htmlFor="">Select Employee:</label>
                                                    <select name="" className="form-control" id="" >
                                                        <option value=""></option>

                                                    </select>
                                                </div> */}
                                                        {/* <input type="text" className="form-control" required name="Employee_ID" /> */}
                                                        <div className="form-group mb-3">
                                                            <label>Select Employee:</label>
                                                            <Select
                                                                styles={customStyles}
                                                                onChange={handleDropdownChange}
                                                                options={dropdownOptions}
                                                                defaultValue={defaultValue}
                                                                selectedOption={otherDeduction.Employee_ID}
                                                            />
                                                            {/* {console.log(defaultValue)} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Dated:</label>

                                                            <input type="date" defaultValue={moment(otherDeduction.Dated).format(config.date_format_input)}
                                                                className="form-control" required name="txtDated" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Deduction :</label>
                                                            <input type="text" className="form-control" required name="txtDeduction" defaultValue={otherDeduction.Deduction} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="" className="">Description:</label>
                                                            <input type="text" className="form-control" required defaultValue={otherDeduction.Description} name="txtDescription" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 mt-md-2 text-end">
                                                        <div className="form-group ">
                                                            {
                                                                loading ?
                                                                    <Spinner animation="border" role="status"></Spinner>

                                                                    :
                                                                    <button type="submit" value="Update" className="button button-primary w-100 ">SAVE</button>

                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditOtherLoanDeduction;
