import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import QRCode from "react-qr-code";
import StudentInfo from '../FeeSystem/StudentInfo';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import { useNavigate, useParams } from 'react-router-dom';
import Page from "../General/Page";
import { RenderHTMLString } from "../General/Utility";
import { calculateColumnTotal, getMonthName } from '../General/Utility';

const PrintAttendenceSheetClassWise = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [selectedMonth, setselectedMonth] = useState('');


    const [WingName, setWingName] = useState("");
    const [ClassName, setClassName] = useState("");
    const [Section_Name, setSection_Name] = useState("");

    // Get the current month and number of days
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    // Generate an array of days in the month
    const monthDays = Array.from({ length: daysInMonth }, (_, index) => index + 1);

    // Generate an array of day names for the current month
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    
    useEffect(() => {
        document.title = "DPS - School Management System";


        setIsUpdated(false);
        setLoading(true);

        // Get the URL parameters
        const urlParams = new URLSearchParams(window.location.search);

        // Retrieve individual parameter values
        const WingName = urlParams.get('WingName');
        const ClassName = urlParams.get('ClassName');
        const Section_ID = urlParams.get('Section_ID');
        const Section_Name = urlParams.get('Section_Name');


        setWingName(urlParams.get('WingName'));
        setClassName(urlParams.get('ClassName'));
        setSection_Name(urlParams.get('Section_Name'));


        setselectedMonth(moment().format('MMMM'));

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: 1,
            pageSize: 2000,
            Section_Id: Section_ID,
        };

        console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Student/SearchStudents',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        console.log(data)
        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);


    const renderLedger = () => {

        if (listOfLedger.length > 0) {
            let count = 1;
            let countbreak = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (

                        <tr key={index}>
                            <td>
                                {count++}
                            </td>
                            <td>{item.Registration_No}</td>
                            <td>{item.Student_Name}</td>
                            {/* Render the table cells for each day */}
                            {monthDays.map((day) => (
                                <React.Fragment key={day}>
                                    <td></td>
                                    <td></td>
                                </React.Fragment>
                            ))}
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="7"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    return (
        <>
            <Page orientation="landscape" size="a4">
                <table className="table table-sm table-bordered table-print white-space-wrap">
                    <thead>
                        <tr>
                            <th>
                                <PrintHeader title={`Monthly Attendence Register For the Month of 
                                ${selectedMonth} ${WingName}-${ClassName} ${Section_Name}`} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="p-0">
                                <div>
                                    <table className="table table-sm table-bordered table-print white-space-wrap">
                                        <tr>
                                            <th>Sr#</th>
                                            <th>Code</th>
                                            <th>Student's Name</th>

                                            {/* Render the table headers for each day */}
                                            {monthDays.map((day) => (
                                                <th key={day} colSpan={2} style={{ width: '25px' }}>
                                                    {day} <br />
                                                    {dayNames[new Date(currentYear, currentMonth, day).getDay()]}
                                                </th>
                                            ))}

                                            <th>Total</th>
                                        </tr>
                                        <tbody>
                                            {
                                                loading ?
                                                    <tr>
                                                        <td className="text-center" colspan="70">
                                                            <Spinner animation="border" role="status"></Spinner>
                                                        </td>
                                                    </tr>
                                                    :
                                                    <>
                                                        {renderLedger()}
                                                    </>

                                            }
                                        </tbody>
                                    </table>
                                    <div >
                                        <table className="table table-sm table-bordered table-print" style={{ borderCollapse: "collapse", border: "transparent" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "450px%" }}>
                                                        No.of Students at the beginning of the month
                                                    </td>
                                                    <td style={{ width: "15%" }}>
                                                        __________________________________________________________
                                                    </td>
                                                    <td style={{ width: "450px%" }}>
                                                        No of school meetings since
                                                        <span>June</span>
                                                        1st.
                                                    </td>
                                                    <td style={{ width: "15%" }}>
                                                        __________________________________________________________
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        No. of Students struck off during the month
                                                    </td>
                                                    <td>
                                                        __________________________________________________________
                                                    </td>
                                                    <td>
                                                        No of school meetings during the month
                                                    </td>
                                                    <td>
                                                        __________________________________________________________
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        No. of Students admitted during the month
                                                    </td>
                                                    <td>
                                                        __________________________________________________________
                                                    </td>
                                                    <td>
                                                        Total No. of attendance during the month.
                                                    </td>
                                                    <td>
                                                        __________________________________________________________
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        No. of Students at the end of the month
                                                    </td>
                                                    <td>
                                                        __________________________________________________________
                                                    </td>
                                                    <td>
                                                        Average daily attendance during the month
                                                    </td>
                                                    <td>
                                                        __________________________________________________________
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-sm table-bordered table-print mt-5" style={{ borderCollapse: "collapse", border: "transparent" }}>
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "33.3333%" }} className="">
                                                        Teacher Incharge
                                                    </th>
                                                    <th style={{ width: "33.3333%" }} className="text-center">
                                                        Checker
                                                    </th>
                                                    <th style={{ width: "33.3333%" }} className="text-end">
                                                        Head of Wing
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Page>
        </>
    );
}

export default PrintAttendenceSheetClassWise;

