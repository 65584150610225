import React from 'react';
import { Link } from 'react-router-dom';
import Struck_Students from '../../assets/images/dashboard_icon/Struck_Students.svg';
import attendece_report from '../../assets/images/dashboard_icon/attendece_report.svg';
import head_wise_report from '../../assets/images/dashboard_icon/head_wise_report.svg';
import student_strength from '../../assets/images/dashboard_icon/student_strength.svg';
import new_student from '../../assets/images/dashboard_icon/new_student.svg';
import no_fee from '../../assets/images/dashboard_icon/no_fee.svg';
import fee_report from '../../assets/images/dashboard_icon/fee_report.svg';
import list from '../../assets/images/dashboard_icon/list.svg';
import status from '../../assets/images/dashboard_icon/status.svg';
import ReportsMenu from './ReportsMenu';
import { ToastContainer } from 'react-toastify';
import { scrollToTop } from '../Shared/ScrollOnTop';

const ReportDashboard = () => {
    return (
        <>
        <ToastContainer/>
        <ReportsMenu/>
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Reports</li>
                                </ol>
                            </nav>
                            <div className="page-title">Reports</div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="page-content">
                <div class="container">
                    <div class="row align-items-center g-3">
                        <div class="col-md-4 col-sm-6">
                            <Link to="/Reports/StudentStrengthReport" class="card m-card">
                                <div class="card-body text-center">
                                    <img src={Struck_Students} class="pb-3" alt=""/>
                                        <p class="m-2 mt-0">Strucked of Students</p>
                                        <h4 class="m-2">435</h4>
                                </div>
                            </Link>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <Link to="/Reports/NewlyAdmittedStudents" class="card a-card">
                                <div class="card-body text-center">
                                    <img src={new_student} class="pb-3" alt=""/>
                                        <p class="m-2 mt-0">Newly Admitted Students</p>
                                        <h4 class="m-2">34</h4>
                                </div>
                            </Link>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <Link to="/Reports/NewAdmittedUnPaid" class="card m-card">
                                <div class="card-body text-center">
                                    <img src={no_fee} class="pb-3" alt=""/>
                                        <p class="m-2 mt-0">New Admitted Unpaid</p>
                                        <h4 class="m-2">36</h4>
                                </div>
                            </Link>
                        </div>
                   
                        <div class="col-md-3 col-sm-6">
                            <div class="card m-card">
                                <div class="card-body text-center">
                                    <img src={student_strength} class="pb-3" alt=""/>
                                        <p class="m-2 mt-0">Student Strength Report</p>
                                        <Link to="/Reports/StudentStrengthReport" class="text-icon sky"> View Report <i class="ri-arrow-right-line"></i> </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="card m-card">
                                <div class="card-body text-center">
                                    <img src={status} class="pb-3" alt=""/>
                                        <p class="m-2 mt-0">Status Wise Change</p>
                                        <Link to="/Reports/StatusWiseChange" class="text-icon pink"> View Report <i class="ri-arrow-right-line"></i> </Link>
                                </div>
                            </div>
                        </div>
                       
                        <div class="col-md-3 col-sm-6">
                            <div class="card m-card">
                                <div class="card-body text-center">
                                    <img src={head_wise_report} class="pb-3" alt=""/>
                                        <p class="m-2 mt-0">Account Head Wise Report</p>
                                        <Link to="/Reports/AccountHeadwiseReport" class="text-icon pink"> View Report <i class="ri-arrow-right-line"></i> </Link>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-6">
                            <div class="card m-card">
                                <div class="card-body text-center">
                                    <img src={fee_report} class="pb-3" alt=""/>
                                        <p class="m-2 mt-0">Student Fine Report</p>
                                        <Link to="/Reports/StudentFineReport" class="text-icon sky"> View Report <i class="ri-arrow-right-line"></i> </Link>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="card m-card">
                                <div class="card-body text-center">
                                    <img src={attendece_report} class="pb-3" alt=""/>
                                        <p class="m-2 mt-0">Attendances Wise Report</p>
                                        <Link to="/Reports/AttendenceSummary" class="text-icon pink"> View Report <i class="ri-arrow-right-line"></i> </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="card m-card">
                                <div class="card-body text-center">
                                    <img src={fee_report} class="pb-3" alt=""/>
                                        <p class="m-2 mt-0">Fee Reporting</p>
                                        <Link to="/Reports/FeeReporting" class="text-icon sky"> View Report <i class="ri-arrow-right-line"></i> </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="card m-card">
                                <div class="card-body text-center">
                                    <img src={list} class="pb-3" alt=""/>
                                        <p class="m-2 mt-0">Defaulters List</p>
                                        <Link to="/Reports/DefaultersList" class="text-icon pink"> View Report <i class="ri-arrow-right-line"></i> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportDashboard;
