import TeacherMenu from './TeacherMenu';
import ShowStaff from "../Shared/ShowStaff";
import { scrollToTop } from '../Shared/ScrollOnTop';
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
function UpdatePhoto(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));

    // photo 
    const [isUpdated, setIsUpdated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [image, setImage] = useState('');
    const [cropData, setCropData] = useState("");
    const [cropper, setCropper] = useState(null);
    const [imageSelected, setImageSelected] = useState(false);
    const [imageCropped, setImageCropped] = useState(false);
    const [imgData, setImageData] = useState("");

    const [loading, setLoading] = useState(false);

    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
        setImageSelected(false);
        setLoading(false);
    };

    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {

        if (cropper) {
            const cropData = cropper.getCroppedCanvas().toDataURL();
            var cleanerBase64 = cropData.substring(22);
            console.log(cropData);
            setImageCropped(true);
            setCropData(cleanerBase64);
            setImageData(cropData); // Update imgData state with cropped image data
        }
        handleCloseModal();
    };

    const renderButtons = () => {
        if (imageSelected === true) {
            return (
                <>
                    <div className="modal-footer justify-content-center">
                        <button className="button button-outline-primary me-2" onClick={handleCloseModal}>Close</button>
                        <button className="button button-primary" onClick={getCropData}>Save Photo</button>
                    </div>
                </>
            )
        }
        else if (imageSelected === false && isUpdated === true) {
            return (
                <>
                    <div className="modal-footer justify-content-center">
                        <button className="button button-outline-primary me-2" onClick={handleCloseModal}>Close</button>
                    </div>
                </>
            )
        }
    }


    return (
        <>
            <TeacherMenu />
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Profile Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isUpdated ? (
                        <>
                            <div className="text-center">
                                <p>Photo Updated Successfully</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="upload-photo-wrap">
                                {imageSelected ? (
                                    <Cropper
                                        zoomTo={0.5}
                                        aspectRatio={4 / 4}
                                        preview=".img-preview"
                                        src={image}
                                        dragMode="move"
                                        viewMode={2}
                                        minCropBoxHeight={10}
                                        minCropBoxWidth={10}
                                        background={false}
                                        responsive={true}
                                        autoCropArea={1}
                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                        onInitialized={(instance) => {
                                            setCropper(instance);
                                        }}
                                        guides={true}
                                    />
                                ) : (
                                    <div className="choose-photo">
                                        <label htmlFor="ChoosePhoto">
                                            <i className="ri-upload-cloud-line"></i>
                                            <span>Browse Photo</span>
                                            <input
                                                id="ChoosePhoto"
                                                accept="image/jpeg,image/png"
                                                type="file"
                                                onChange={onChange}
                                            />
                                        </label>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </Modal.Body>

                {renderButtons()}
            </Modal>
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/teacher/Dashboard">Home</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Update Photo</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Update Photo</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body p-md-4">
                                        <div className="form-group">
                                            <label className="">Select Photo:</label>
                                        </div>
                                        <div className="col-md-3 w-100 text-center" style={{}}>
                                            <div className='m-auto mb-3' style={{ width: "150px", height: "150px", borderRadius: "50%" }}>
                                                <img className="mb-3" src={imgData} style={{ width: "150px", height: "150px", borderRadius: "50%", border: '1px dashed black' }} />
                                            </div>
                                            <button
                                                type="button"
                                                onClick={handleShowModal}
                                                className="button w-100 button-small"
                                            >
                                                Choose Photo
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body p-md-4">
                                        <div className="form-group">
                                            <label className="">Current Photo:</label>
                                        </div>
                                        <div className="col-md-3 w-100 text-center" style={{}}>
                                            <div className='m-auto mb-3' style={{ width: "150px", height: "150px", borderRadius: "50%", border: '1px dashed black' }}>
                                                <img className="mb-3" src={imgData} style={{ width: "150px", height: "150px", borderRadius: "50%" }} />
                                            </div>
                                            <button
                                                className="button w-100 button-small"
                                            >
                                                **....... Current Photo .......**
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card" style={{height:"285px"}}>
                                    <div className="card-body user-profile">
                                        <div className="row">
                                            <div className="col-md-12 mt-4">
                                                <h4 className="" style={{ color: "#003970", fontWeight: "bold" }}>
                                                    Photo Privacy Settings
                                                </h4>
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label className="form-check-label" for="flexRadioDefault1">
                                                        <b>Private:</b> Will only be shown within School
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-4">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label className="form-check-label" for="flexRadioDefault1">
                                                        <b>Public:</b>Will be shown on School Website
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 offset-md-8 mt-5">
                                                <div className="form-group ">
                                                    {loading ? (
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    ) : (
                                                        <button
                                                            type="submit"
                                                            value="Save"
                                                            className="button button-primary w-100 "
                                                        >
                                                            Update Photo
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
}

export default UpdatePhoto;