import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import TeacherRightsCheck from './TeacherRightsCheck';
import { scrollToTop } from '../Shared/ScrollOnTop';
function TeacherMenu(props) {

    return (
        <>
            <TeacherRightsCheck />
            <nav className={`navbar navbar-expand-lg navbar-module MenuSticky`}>
                <div className="container-fluid">
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item">
                                <NavDropdown title="Profile">
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/teacher/UpdateProfile">Update Profile</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/teacher/change-password">Change Password</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/teacher/unpaid-student">Unpaid Student</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/teacher/update-photo">Update Photo</NavLink>
                                </NavDropdown>
                            </li>
                            <li className="nav-item">
                                <NavDropdown title="Exams">
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/teacher/section-incharge">Marks Entry as Section Incharge</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/teacher/subject-teacher">Marks Entry as Subject Teacher</NavLink>
                                </NavDropdown>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/teacher/manage-sections">Manage Sections</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavDropdown title="Attendence">
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/teacher/MarkStudentAttendance">Mark Attendence</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/teacher/ATSummary">Attendence Summary</NavLink>
                                </NavDropdown>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default TeacherMenu;