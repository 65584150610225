import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import PayrollMenu from './PayrollMenu';
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from 'react-bootstrap/Dropdown';
import MonthsDropDown from '../Shared/MonthsDropDown';
import YearsDropDown from '../Shared/YearsDropDown';
import { Form } from 'react-bootstrap';
import "react-toastify/dist/ReactToastify.css";
import { calculateColumnTotal, getMonthName } from '../General/Utility';
import { scrollToTop } from '../Shared/ScrollOnTop';
import csvDownload from "json-to-csv-export";

function ManageLeaves(props) {
    document.title = "DPS School Management System";

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [isUpdated, setIsUpdated] = useState(false);

    const [loaddata, setLoaddata] = useState(false);


    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    const [listOfLedger, setLedger] = useState([]);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectMonth, setSelectMonth] = useState(moment().format("M"))
    const [year, setYear] = useState(moment().format("YYYY"))
    const [selaryType, setSelaryType] = useState(11)
    const [employeCode, setemployeCode] = useState()
    let totalAmount = 0;
    // const [selaryTypeName,setSelaryTypeName] = useState()


    // const [month,setMonth] = useState()
    useEffect(() => {

        setIsUpdated(true)
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            FYear: year,
            SelectedMonth: selectMonth,
            SalaryTypeId: selaryType
        };
        console.log(data)
        var api_config = {
            method: 'post',
            url: config.base_url + 'Payroll/GetArrearLeaves',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);
                console.log(totalRecords);
                // setSelaryTypeName(response.data.SalaryTypeName)

            })
            .catch(function (error) {
                console.log(error);
            });



    }, [isUpdated, pageNo, pageSize])

    const handleSubmit = (event) => {
        //         setSelectMonth(event.target.SelectedMonth.value)
        // setYear(event.target.FYear.value)
        // setSelaryType(event.target.SalaryTypeId.value)
        console.log(selaryType);
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);

            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                FYear: year,
                SelectedMonth: selectMonth,
                SalaryTypeId: selaryType,
            };
            console.log(data)
            var api_config = {
                method: 'post',
                url: config.base_url + 'Payroll/GetArrearLeaves',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };



            axios(api_config)
                .then(function (response) {
                    console.log(response.data);
                    setLedger(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    // setSelaryTypeName(response.data.SalaryTypeName)

                })
                .catch(function (error) {
                    console.log(error);
                });

        }

        setValidated(true);
    };



    const handleSubmitForCvs = (event) => {
        console.log(event);

        setLoaddata(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            FYear: year,
            SelectedMonth: selectMonth,
            SalaryTypeId: selaryType
        };

        // console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Payroll/GetArrearLeaves",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                console.log(typeof (response.data.ListofRecords));
                console.log(response.data);
                if (response.data.status_code == 1) {
                    if (response.data.ListofRecords.length > 0) {


                        let data = response.data.ListofRecords;
                        let count = 0;
                        const listOfLedgerNew = data.map(({ ...rest }) => ({
                            Serial_No: count++ + pageSize * (pageNo - 1),
                            ID: rest.ID,
                            Name: rest.Staff_Name,
                            Father_Name: rest.Father_Name,
                            Code: rest.Code,
                            Department: rest.Department,
                            Description: rest.Description,
                            Entry_Date: moment(rest.Entry_Date).format("DD/MM/YYYY"),
                            Last_Updated: moment(rest.Last_Updated).format("DD/MM/YYYY"),
                            Salary_Type: rest.Salary_Type,
                            Salary_Value: rest.Salary_Value,
                        }));


                        const dataToConvert = {
                            data: listOfLedgerNew,
                            filename: "ManageEmployee",
                            delimiter: ",",
                            headers: [
                                "Serial_No",
                                "ID",
                                "Name",
                                "Father_Name",
                                "Code",
                                "Department",
                                "Description",
                                "Entry_Date",
                                "Last_Updated",
                                "Salary_Type",
                                "Salary_Value"
                            ],
                        };
                        csvDownload(dataToConvert);

                        setLoaddata(false);
                    }
                    else if (response.data.ListofRecords.length == 0) {
                        toast.error("There is no data available for download", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setLoaddata(false);
                    }


                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoaddata(false);
                }
            })
            .catch(function (error) {
                toast.error("Network Error..", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoaddata(false);
            });
    };
    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return (
                listOfLedger.map((item, index) => {
                    totalAmount += item.Salary_Value;
                    return (
                        <tr key={index}>
                            <td>{count++ + (pageSize * (pageNo - 1))}</td>
                            <td>{item.ID}</td>
                            <td>{item.Code}</td>
                            <td>{item.Staff_Name}</td>
                            <td>{item.Father_Name}</td>
                            <td>{item.Description}</td>
                            <td>{item.Salary_Value}</td>
                            <td>
                                <ul className="inline-action" aria-labelledby="dropdownMenuButton1">
                                    <li><NavLink to={'/Payroll/AddLeave/' + item.Employee_ID} className=" green"><i className="ri-edit-line"></i></NavLink></li>
                                    <li><button type="button" onClick={(e) => deleteArrears(item.ID)} className="red"><i class="ri-delete-bin-line"></i></button></li>
                                </ul>
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }
    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const deleteArrears = (ID) => {
        const confirm = window.confirm("Are you sure you want to proceed ?");

        if (confirm) {

            setIsUpdated(false)
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                ID: ID
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Payroll/DeleteArrears',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status_code == 1) {
                        setIsUpdated(true);
                    }
                    setIsUpdated(false);

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    return (
        <>
            <ToastContainer />

            <PayrollMenu />

            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/PayRoll/PayRollDashboard">Payroll</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Manage Deductions</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Manage Deductions</div>
                            </div>
                            <div className="col-md-6">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div className="card mb-md-2">
                                        <div className="card-body p-md-4">
                                            <div className="row align-items-center">
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Select Months</label>
                                                        <select className="form-select" name="SelectedMonth"

                                                            defaultValue={selectMonth}
                                                            onChange={(e) => setSelectMonth(e.target.value)}
                                                        >
                                                            <option value="">Select Month</option>
                                                            <MonthsDropDown />
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Select Year</label>
                                                        <select className="form-select" name="FYear"
                                                            defaultValue={year}
                                                            onChange={(e) => setYear(e.target.value)}
                                                        >
                                                            <option value="">Select Year</option>
                                                            <YearsDropDown selected={year} />
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Employee Code</label>
                                                        <input type="number" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label>Salary Type</label>
                                                        <select className="form-select" name="SalaryTypeId"
                                                            onChange={(e) => setSelaryType(e.target.value)}
                                                            defaultValue={selaryType}
                                                        >
                                                            <option Selected Value="11">Arrears</option>
                                                            <option Value="7">Leave Deduction</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {loaddata ? (
                                                    <div class="col-md-2 offset-8 text-center ">
                                                        <div>

                                                            <div>
                                                                <Spinner
                                                                    animation="border"
                                                                    role="status"
                                                                ></Spinner>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="col-md-2 offset-8 text-end ">
                                                        <div className="form-group text-end">
                                                            <a
                                                                type="button"
                                                                onClick={handleSubmitForCvs}
                                                                className="print-icon"
                                                            >
                                                                <span>
                                                                    <i class="ri-file-excel-2-line"></i>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="col-md-2 text-end">
                                                    <div className="form-group">
                                                        <input type="submit" value="Search" className="button button-primary w-100"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body">
                                <table className="table table-theme">
                                    <thead>
                                        <tr>
                                            {/* <th colspan="4">Total Records Found : {listOfLedger.length}</th> */}
                                            {/* <th className="text-end" colspan="4">Total Amount : {calculateColumnTotal(listOfLedger, 'Salary_Value')}</th> */}
                                        </tr>
                                        <tr>
                                            <th>Serial No</th>
                                            <th>ID</th>
                                            <th>Code</th>
                                            <th>Staff Name</th>
                                            <th>Father Name</th>
                                            <th>Desicription</th>
                                            <th>Amount</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loading ?
                                                <tr>
                                                    <td className="text-center" colspan="8">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </td>
                                                </tr>
                                                :
                                                <>
                                                    {renderLedger()}
                                                    <tr>
                                                        <td colSpan="5"></td>
                                                        <td className="text-end">
                                                            <b> Total Amount </b>
                                                        </td>
                                                        <td colSpan="1">
                                                            <b>{totalAmount} </b>
                                                        </td>
                                                        <td></td>
                                                    </tr>

                                                </>

                                        }
                                    </tbody>
                                </table>
                                {
                                    totalRecords > 10 &&
                                    <div className="pagination-wrap">
                                        <div className="row align-items-center">
                                            <div className="col-md-2 form_default">
                                                <div className="d-flex align-items-center">
                                                    <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                    <div className="form-group">
                                                        <select className="form-select"
                                                            style={{ width: "100px" }}
                                                            onChange={(e) => {
                                                                setPageNo(1);
                                                                setPageSize(parseInt(e.target.value));
                                                            }}>
                                                            <option value="10" selected>10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="500">500</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <Pagination
                                                pageNo={pageNo}
                                                pageSize={pageSize}
                                                totalRecords={totalRecords}
                                                onPageChange={handlePageChange}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="container">

                  
                    <div className="card mt-md-3">
                        <div className="card-body  p-md-4">
                            <div className="row  ">
                                <div className="col-md-12 text-end ">
                                    <NavLink target="_blank"
                                        to={`/PayRoll/PrintManageLeaves/?month=${selectMonth}&year=${year}&selarytype=${selaryType} `}
                                        className="button button-primary">Print This Report</NavLink>

                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageLeaves;