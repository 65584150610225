import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import DesignationDropDown from "../Shared/DesignationDropDown";
import EmployeeTypeDropDown from "../Shared/EmployeeTypeDropDown";
import moment from "moment";
import Pagination from "../General/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import PayScaleBasic from "../Shared/PayScaleBasic";
import { Form } from "react-bootstrap";
import StaffAtMenu from "./StaffAtMenu";
import SessionsDropDown from "../Shared/SessionsDropDown";
import { scrollToTop } from "../Shared/ScrollOnTop";
import csvDownload from "json-to-csv-export";

function LeaveRegister(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [SessionId, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );

  const [designationID, setDesignationId] = useState(0);
  const [empTypeID, seEmployeeTypeId] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [showFilters, setShowFilters] = useState(true);
  const [RetireMentStatus, setRetireMentStatus] = useState(false);

  const [leaveType, setLeaveType] = useState("");

  // for filters
  const [Code, setCode] = useState("");
  const [DesignationID, setDesignationID] = useState(0);
  const [EmployeeTypeID, setEmployeeTypeID] = useState(0);
  const [Keywords, setKeywords] = useState("");
  const [loaddata, setLoaddata] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    document.title = "Leave Register";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SchoolID: 1,
      IS_Resigned: RetireMentStatus,
      Code: Code,
      DesignationID: DesignationID,
      EmployeeTypeID: EmployeeTypeID,
      Keywords: Keywords,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/SearchEmployees",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    // console.log(data);

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, Code, DesignationID, EmployeeTypeID]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handleSubmitForCvs = (event) => {
    console.log(event);

    setLoaddata(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: 10000,
      SchoolID: 1,
      IS_Resigned: RetireMentStatus,
      Code: Code,
      DesignationID: DesignationID,
      EmployeeTypeID: EmployeeTypeID,
      Keywords: Keywords,
    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/SearchEmployees",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          if (response.data.totalRecords > 0) {
            let data = response.data.ListofRecords;
            let count = 0;
            const listOfLedgerNew = data.map(({ ...rest }) => ({
              Serial_No: count++ + pageSize * (pageNo - 1),
              ID: rest.ID,
              Name: rest.Name,
              Father_Name: rest.Father_Name,
              DOB: moment(rest.Date_Of_Birth).format("DD/MM/YYYY"),
              DOJ: moment(rest.Date_Of_Joining).format("DD/MM/YYYY"),
              Account_No: `=T("${rest.Account_No}")`,
              Mobile_No: `=T("${rest.Mobile_No}")`,
              CNIC_NO: `=T("${rest.CNIC_NO}")`,
              Designation: rest.Selected_Designation.Name,
            }));

            const dataToConvert = {
              data: listOfLedgerNew,
              filename: "LeaveRegister",
              delimiter: ",",
              headers: [
                "Serial_No",
                "ID",
                "Name",
                "Father_Name",
                "DOB",
                "DOJ",
                "Account_No",
                "Mobile_No",
                "CNIC_NO",
                "Designation",
              ],
            };
            csvDownload(dataToConvert);

            setLoaddata(false);
          } else if (response.data.totalRecords == 0) {
            toast.error("There is no data available for download", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoaddata(false);
          }
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoaddata(false);
        }
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoaddata(false);
      });
  };

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div className="form-check">
                <input
                  key={item.ID}
                  data-type-id={item.ID}
                  className="form-check-input checkEmployees"
                  type="checkbox"
                  value={item.ID}
                />
              </div>
            </td>
            <td>{item.ID}</td>
            <td>{item.Code}</td>
            <td>{item.Name}</td>
            <td>{item.Father_Name}</td>
            <td>{moment(item.Date_Of_Birth).format("DD/MM/YYYY")}</td>
            <td>{moment(item.Date_Of_Joining).format("DD/MM/YYYY")}</td>
            <td>{item.Mobile_No}</td>
            <td>{item.Selected_Designation.Name}</td>
            <td className="text-center">
              <ul className="inline-action">
                <li>
                  <NavLink
                    target="_blank"
                    to={`/StaffAttendance/PrintLeaveRegister/?type=CL&emp_id=${item.ID}&SessionID=${SessionId}`}
                  >
                    CL
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    target="_blank"
                    to={`/StaffAttendance/PrintLeaveRegister/?type=AB&emp_id=${item.ID}&SessionID=${SessionId}`}
                  >
                    AB
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    target="_blank"
                    to={`/StaffAttendance/PrintLeaveRegister/?type=MAT&emp_id=${item.ID}&SessionID=${SessionId}`}
                  >
                    MAT
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    target="_blank"
                    to={`/StaffAttendance/PrintLeaveRegister/?type=LWOP&emp_id=${item.ID}&SessionID=${SessionId}`}
                  >
                    LWOP
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    target="_blank"
                    to={`/StaffAttendance/PrintLeaveRegister/?type=ET&emp_id=${item.ID}&SessionID=${SessionId}`}
                  >
                    ET
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    target="_blank"
                    to={`/StaffAttendance/PrintLeaveRegister/?type=CML&emp_id=${item.ID}&SessionID=${SessionId}`}
                  >
                    CML
                  </NavLink>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="10">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  const handleFilters = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setCode(event.target.Code.value);
    setDesignationID(event.target.DesignationID.value);
    setEmployeeTypeID(event.target.EmployeeTypeID.value);
    setKeywords(event.target.Keywords.value);
  };

  const handlePrintPayslips = () => {
    const inputsEmployees = document.querySelectorAll("input.checkEmployees");
    const valuesSelected = [];
    inputsEmployees.forEach((input) => {
      if (input.checked == true) {
        const value = parseInt(input.value);
        if (value > 0) {
          valuesSelected.push(value);
        }
      }
    });

    const queryParam = valuesSelected.join(",");
    //history.push(`/other-page?ids=${queryParam}`);

    let url =
      "/StaffAttendance/PrintLeaveRegister/?type=" +
      leaveType +
      "&emp_id=" +
      queryParam +
      "&SessionID=" +
      SessionId;
    window.open(url, "_blank");

    //console.log(valuesSelected);
  };

  const handleCheckAll = (e) => {
    const checkboxes = document.getElementsByClassName("checkEmployees");

    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = e.target.checked;
    }
  };

  return (
    <>
      <StaffAtMenu />
      <ToastContainer />

      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/StaffAttendance/StaffAttendanceDashboard">
                      Attendance Dashboard
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Leave Register for Employee
                  </li>
                </ol>
              </nav>
              <div className="page-title">Leave Register</div>
            </div>

            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/PayRoll/AddArrear"
                  className="button button-white button-icon"
                >
                  Add Leave
                  <i className="ri-add-line"></i>
                </Link>
                <button
                  onClick={() => {
                    setShowFilters((prevIsActive) => !prevIsActive);
                  }}
                  className="button button-white button-icon"
                >
                  {showFilters ? "Hide Filters" : "Show Filters"}
                  <i className="ri-filter-3-line"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        <div className="page-content">
          <form onSubmit={handleFilters}>
            <div className="container">
              <div className="card mb-2">
                <div className="card-body p-md-4">
                  <div className="row align-items-center gy-3">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Select Session</label>
                        <select
                          className="form-select"
                          onChange={(e) => setSessioinId(e.target.value)}
                          name="SessionID"
                        >
                          <option value="0">---All---</option>
                          <SessionsDropDown selectedSession={SessionId} />
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Gender</label>
                        <select className="form-select">
                          <option Value="">--All--</option>
                          <option Selected Value="1">
                            Male
                          </option>
                          <option Value="0">Female</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Designation</label>
                        <select className="form-select" name="DesignationID">
                          <option value="">Please Select</option>
                          <DesignationDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Department</label>
                        <select name="EmployeeTypeID" className="form-select">
                          <option value="">Please Select</option>
                          <EmployeeTypeDropDown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Status</label>
                        <select className="form-select">
                          <option Value="">--All--</option>
                          <option Value="false">Current</option>
                          <option Value="true">Retired</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Basic Pay Scale</label>
                        <select className="form-select">
                          <option Value="">--All--</option>
                          <PayScaleBasic />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Staff Code</label>
                        <input
                          type="text"
                          name="Code"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Staff ID</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Keywords</label>
                        <input
                          type="text"
                          name="Keywords"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Order by</label>
                        <select className="form-select">
                          <option Selected Value="Name">
                            Alphabetic
                          </option>
                          <option Value="Date_Of_Joining ASC">DOJ ASC</option>
                          <option Value="Date_Of_Joining DESC">DOJ DESC</option>
                          <option Value="Code Asc">Code Asc</option>
                          <option Value="Code Desc">Code Desc</option>
                        </select>
                      </div>
                    </div>

                    {loaddata ? (
                      <div class="col-md-2 offset-2 mt-4 text-center ">
                        <div>
                          <div>
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-2 offset-2 text-end ">
                        <div className="form-group text-end mt-4">
                          <a
                            type="button"
                            onClick={handleSubmitForCvs}
                            className="print-icon"
                          >
                            <span>
                              <i class="ri-file-excel-2-line"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    )}

                    <div className="col-md-2">
                      <div className="form-group mt-4">
                        <button
                          type="submit"
                          value="Search"
                          className="button button-primary w-100"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        className="form-check-input checkAll"
                        onChange={handleCheckAll}
                      />
                    </th>
                    <th>ID</th>
                    <th>Code</th>
                    <th>Employee Name</th>
                    <th>Father Name</th>
                    <th>DOB</th>
                    <th>DOJ</th>
                    <th>Mobile No.</th>
                    <th>Designation</th>

                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                                        <th colspan="8">
                                            TotalRecords &nbsp; Records Found!
                                        </th>
                                    </tr> */}
                  {loading ? (
                    <tr>
                      <td className="text-center" colspan="10">
                        <Spinner animation="border" role="status"></Spinner>
                      </td>
                    </tr>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
              {totalRecords > 10 && (
                <div className="pagination-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-2 form_default">
                      <div className="d-flex align-items-center">
                        <label className="me-2" style={{ fontSize: "15px" }}>
                          Page Size
                        </label>
                        <div className="form-group">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => {
                              setPageNo(1);
                              setPageSize(parseInt(e.target.value));
                            }}
                          >
                            <option value="10" selected>
                              10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <Pagination
                      pageNo={pageNo}
                      pageSize={pageSize}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card mt-2">
            <div className="card-body">
              <div className="row align-items-center gy-3">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="">Select Leave Type:</label>
                    <select
                      className="form-select"
                      onChange={(e) => setLeaveType(e.target.value)}
                    >
                      <option>Select Type</option>
                      <option value="CL">CL</option>
                      <option value="AB">AB</option>
                      <option value="MAT">MAT</option>
                      <option value="LWOP">LWOP</option>
                      <option value="ET">ET</option>
                      <option value="CML">CML</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2 offset-md-7">
                  <button
                    onClick={handlePrintPayslips}
                    className="button button-primary w-100"
                  >
                    Print Leave Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaveRegister;
