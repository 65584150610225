import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function ExamsDropDown(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [Exams, setExams] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        const apiData = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            PageNo: 1,
            PageSize: 100,
            SchoolID: 1,
            SessionID: props.SessionID
        };

        //console.log(apiData);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Exam/GetAllExams',
            headers: {
                'Content-Type': 'application/json'
            },
            data: apiData
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == "1") {
                    setExams(response.data.ListofRecords);
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);

    <ToastContainer />

    if (loading) {
        return <option selected disabled>Loading...</option>
    }
    else {
        return (
            Exams.map((item, index) => {
                return (
                    <option value={item.ID} key={index}>{item.Exam_Code + ' ' + item.Name}</option>
                )
            })
        );
    }
}

export default ExamsDropDown;