import React from 'react';
import AnnualMenu from './AnnualMenu';
import { NavLink } from 'react-router-dom';
import { scrollToTop } from '../Shared/ScrollOnTop';

function PromoteClasses(props) {
    return (
        <>
            <AnnualMenu />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/AnnualTasks/AnnualTasksDashboard">Annual Tasks</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Promote Classes</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Promote Classes</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body p-md-4">
                                <div className="bg-light p-md-4">
                                    <p className="m-0">
                                        This Module will give you the facility to promote your classes.
                                        <br />
                                        <b>Pre-Requirements:</b>
                                        You have already created the new classes and session and now you are promoting the classes into new one.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="card mt-2">
                            <div className="card-body p-md-4">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        <div className="section-title title-sm title-left">Old Session ManageSessions[ManageSessions.length - 2].Session_Name </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Select Wing:</label>
                                            <select className="form-select" required>
                                                <option value="" disabled selected>Please Select</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Select Class</label>
                                            <select className="form-select" required>
                                                <option value="" disabled selected>Please Select</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Select Section</label>
                                            <select className="form-select" required>
                                                <option value="" disabled selected>Please Select</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">
                        <div className="card mt-2">
                            <div className="card-body p-md-4">
                                <div className="row gy-3 align-items-center">
                                    <div className="col-md-12">
                                        <div className="section-title title-sm title-left">New Session ManageSessions[ManageSessions.length - 1].Session_Name </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Select Wing:</label>
                                            <select className="form-select" required>
                                                <option value="" disabled selected>Please Select</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Select Class</label>
                                            <select className="form-select" required>
                                                <option value="" disabled selected>Please Select</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Class Code</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Class Name</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Select Section</label>
                                            <select className="form-select" required>
                                                <option value="" disabled selected>Please Select</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Section Name</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-md-3 offset-md-3">
                                        <div className="form-group text-end mt-3">
                                            <input type="button" value="Promote Classes" className="button button-primary"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
}

export default PromoteClasses;