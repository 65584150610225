import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SessionsDropDown from "../Shared/SessionsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";
import { RenderHTMLStringWithoutDiv } from "../General/Utility";
import moment from "moment";
import TeacherMenu from "./TeacherMenu";
import SectionByTeacherDropDown from "../Shared/SectionByTeacherDropDown";
import { scrollToTop } from '../Shared/ScrollOnTop';

function ATSummary(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [loading, setLoading] = useState(false);
  const [AttendanceDate, setAttendanceDate] = useState();
  const [WingId, setWingId] = useState(0);
  const [SessionId, setSessioinId] = useState(localStorage.getItem("DefaultSession"));
  const [ClassId, setClassId] = useState(0);
  const [SectionID, setSectionID] = useState(0);
  const [FYear, setFYear] = useState(moment().format("YYYY"));
  const [SelectedMonth, setSelectedMonth] = useState(moment().format("M"));
  const [AttendanceString, setAttendanceString] = useState("");
  const [days, setdays] = useState(false);

  const handleSubmit = (event) => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      Section_Id: SectionID,
      FYear: FYear,
      SelectedMonth: SelectedMonth,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "StudentAttendance/GetAttendanceSummaryTeacher",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setAttendanceString(response.data.attendenceString);
          setdays(true)
          setLoading(false);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <TeacherMenu />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/teacher/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="">
                        Student Attendance
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Attendance Summary
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Monthly Attendance Summary</div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body card-form-wrap">
                    <div className="">
                      <div className="row align-items-center">
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label>Select Session</label>
                            <select
                              className="form-select"
                              onChange={(e) => setSessioinId(e.target.value)}
                              name="SessionID"
                            >
                              <option value="0">---All---</option>
                              <SessionsDropDown selectedSession={SessionId} />
                            </select>
                          </div>
                        </div>



                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label for="" className="">
                              Select Section:
                            </label>
                            <select
                              className="form-select"
                              onChange={(e) => setSectionID(e.target.value)}
                              value={SectionID}
                              name="Section_Id"
                              required
                            >
                              <option value="0">Please Select</option>
                              {SessionId !== 0 && (

                                <SectionByTeacherDropDown Sessionid={SessionId} />
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label>Select Month:</label>
                            <select
                              className="form-select"
                              onChange={(e) => setSelectedMonth(e.target.value)}
                              value={SelectedMonth}
                              name="SelectedMonth"
                              required
                            >
                              <option value="0">Please Select</option>
                              <MonthsDropDown />
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label>Select Year:</label>

                            <select
                              className="form-select"
                              onChange={(e) => setFYear(e.target.value)}
                              value={FYear}
                              name="SelectedYear"
                              required>
                              <option value="">Please Select</option>
                              <YearsDropDown currentYearSelectd={true} />
                            </select>
                          </div>
                        </div>

                        <div className="col-md-2 offset-md-10">
                          <div className="form-group">
                            {loading ? (
                              <Spinner
                                animation="border"
                                role="status"
                              ></Spinner>
                            ) : (
                              <button
                                type="submit"
                                onClick={handleSubmit}
                                value="Load Summary"
                                className="button button-primary w-100"
                              >
                                Load Summary
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            {loading ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <>
                <div>
                  {
                    days ?
                      <>
                        <div className="card my-2">
                          <div className="card-body">
                            <div className="row g-0">
                              <div className="days">
                                <div className="clander">
                                  <h3>Sun</h3>
                                </div>
                                <div className="clander">
                                  <h3>Mon</h3>
                                </div>
                                <div className="clander">
                                  <h3>Tue</h3>
                                </div>
                                <div className="clander">
                                  <h3>Wed</h3>
                                </div>
                                <div className="clander">
                                  <h3>Thr</h3>
                                </div>
                                <div className="clander">
                                  <h3>Fri</h3>
                                </div>
                                <div className="clander">
                                  <h3>Sat</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </>
                      :
                      <></>
                  }
                </div>
                <div className="card my-2">
                  <div className="card-body">
                    <div className="row g-0"></div>
                    {RenderHTMLStringWithoutDiv(AttendanceString)}
                  </div>
                </div>
              </>
            )}


          </div>
        </div>
      </div>
    </>
  );
}

export default ATSummary;
