import React, { useState } from "react";
import StudentMenu from "./StudentMenu";
import { NavLink } from "react-router-dom";
import { scrollToTop } from "../Shared/ScrollOnTop";
import { toast, ToastContainer } from "react-toastify";
import config from "../../Config";
import axios from "axios";
import { Form } from "react-bootstrap";
import moment from "moment/moment";

function ChangeRegistrationNo(props) {
  const [loading, setLoading] = useState(false);
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [Code, setCode] = useState("");
  const [ValidCode, setValidCode] = useState(false);
  const [student, setStudent] = useState({});
  const [StudentID, setStudentID] = useState(0);

  const [studentvalidated, setstudentvalidated] = useState(false);

  const [oldReg, setOldReg] = useState("");
  const [newReg, setNewReg] = useState("");
  const [reason, setReason] = useState("");

  const handledsearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidCode(false);
    } else {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        Registration_No: Code,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Student/GetStudentByRegistrationNo",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          console.log(response.data);
          if (response.data.status_code == 1) {
            setStudent(response.data.Student);
            setStudentID(response.data.Student.Student_ID);
            setValidCode(true);
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setStudent(null);
            setValidCode(false);
          }

          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    setstudentvalidated(true);
  };

  const ChangeRegNo = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidCode(false);
    } else {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,

        OldRegistrationNo: oldReg,
        NewRegistrationNo: newReg,
        ChangeReason: reason,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "FeeSystem/UpdateRegistrationNo",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          console.log(response.data);
          if (response.data.status_code == 1) {
            console.log(response.data);
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setValidCode(false);
          }

          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    setstudentvalidated(true);
  };

  return (
    <>
      <StudentMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to="/Dashboard">Dashboard</NavLink>
                  </li>
                  <li className="breadcrumb-item">
                    <NavLink to="/students/StudentDashboard">Students</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Change Registration No.
                  </li>
                </ol>
              </nav>
              <div className="page-title">Change Registration No.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="card">
                <div className="card-body card-form-wrap">
                  <div className="form-wrap">
                    <div className="form-legend">Step 1.Search Student</div>
                    <Form
                      noValidate
                      validated={studentvalidated}
                      onSubmit={handledsearch}
                    >
                      <div className="row">
                        <label
                          htmlFor=""
                          className="col-md-3 col-form-label col-form-label-sm"
                        >
                          Enter Registration No:
                        </label>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={Code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                        </div>
                        <div className="col-md-2 offset-md-4">
                          {/* <input type="button" value="Search" className="button button-primary w-100" /> */}
                          <button
                            type="submit"
                            value="Search"
                            className="button button-primary w-100"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                  <div className="form-wrap">
                    <div className="form-legend">Step 2. Confirm Details</div>
                    <Form noValidate validated={studentvalidated}>
                      <div className="row">
                        <label
                          htmlFor=""
                          className="col-md-2 col-form-label col-form-label-sm"
                        >
                          Student Name:
                        </label>
                        <div className="col-md-4">
                          <label className="label-value">
                            {student?.Student_Name}
                          </label>
                        </div>
                        <label className="col-md-2 col-form-label col-form-label-sm">
                          Father Name:
                        </label>
                        <div className="col-md-4">
                          <label className="label-value">
                            {student?.Father_Name}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <label
                          htmlFor=""
                          className="col-md-2 col-form-label col-form-label-sm"
                        >
                          F CNIC NO:
                        </label>
                        <div className="col-md-4">
                          <label className="label-value">
                            {student?.Father_CNIC}
                          </label>
                        </div>
                        <label
                          htmlFor=""
                          className="col-md-2 col-form-label col-form-label-sm"
                        >
                          Date of Birth:
                        </label>
                        <div className="col-md-4">
                          <label className="label-value">
                            {moment(student?.Date_Of_Birth).format(
                              "DD/MM/YYYY"
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="row no-gutters mb-3">
                        <label
                          htmlFor=""
                          className="col-md-2 col-form-label col-form-label-sm"
                        >
                          Form No:
                        </label>
                        <div className="col-md-4">
                          <label className="label-value">
                            {student?.Form_No}
                          </label>
                        </div>
                        <label
                          htmlFor=""
                          className="col-md-2 col-form-label col-form-label-sm"
                        >
                          Bay Form No:
                        </label>
                        <div className="col-md-4">
                          <label className="label-value">
                            {student?.Bay_Form_No}
                          </label>
                        </div>
                      </div>
                      <div className="row no-gutters mb-3">
                        <label
                          htmlFor=""
                          className="col-md-2 col-form-label col-form-label-sm"
                        >
                          Contact No:
                        </label>
                        <div className="col-md-4">
                          <div className="label-value">
                            {student?.Contact_Phone}
                          </div>
                        </div>
                        <label
                          htmlFor=""
                          className="col-md-2 col-form-label col-form-label-sm"
                        >
                          Gender:
                        </label>
                        <div className="col-md-4">
                          <label className="label-value">
                            {student?.Gender == true ? "Male" : "Female"}
                          </label>
                        </div>
                      </div>
                      <div className="row no-gutters mb-3">
                        <label
                          htmlFor=""
                          className="col-md-2 col-form-label col-form-label-sm"
                        >
                          Wing Name:
                        </label>
                        <div className="col-md-2">
                          <label className="label-value">
                            {student?.SelectedClass?.SelectedWing?.Wing_Name}
                          </label>
                        </div>
                        <label
                          htmlFor=""
                          className="col-md-2 col-form-label col-form-label-sm"
                        >
                          Class Name:
                        </label>
                        <div className="col-md-2">
                          <label className="label-value">
                            {student?.SelectedClass?.Class_Name}
                          </label>
                        </div>
                        <label
                          htmlFor=""
                          className="col-md-2 col-form-label col-form-label-sm"
                        >
                          Section Name:
                        </label>
                        <div className="col-md-2">
                          <label className="label-value">
                            {student?.SelectedSection?.Section_Name}
                          </label>
                        </div>
                      </div>
                      <div className="row no-gutters mb-3">
                        <label
                          htmlFor=""
                          className="col-md-3 col-form-label col-form-label-sm"
                        >
                          Temporary Address:
                        </label>
                        <div className="col-md-9">
                          <label className="label-value">
                            {student?.Temporary_Address}
                          </label>
                        </div>
                      </div>
                      <div className="row no-gutters mb-3">
                        <label
                          htmlFor=""
                          className="col-md-3 col-form-label col-form-label-sm"
                        >
                          Permanent Address:
                        </label>
                        <div className="col-md-9">
                          <label className="label-value">
                            {student?.Permanent_Address}
                          </label>
                        </div>
                      </div>
                    </Form>
                  </div>
                  <div className="form-wrap">
                    <div className="form-legend">Step 3. New Code Details</div>
                    <Form
                      noValidate
                      validated={studentvalidated}
                      onSubmit={ChangeRegNo}
                    >
                      <div className="row">
                        <label
                          htmlFor=""
                          className="col-md-2 col-form-label col-form-label-sm"
                        >
                          Old Code:{" "}
                        </label>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control "
                            value={oldReg}
                            onChange={(e) => setOldReg(e.target.value)}
                            required
                          />
                        </div>
                        <label
                          htmlFor=""
                          className="col-md-2 col-form-label col-form-label-sm"
                        >
                          New Code:{" "}
                        </label>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control "
                            value={newReg}
                            onChange={(e) => setNewReg(e.target.value)}
                            required
                          />
                        </div>
                        <label
                          htmlFor=""
                          className="col-md-2 col-form-label col-form-label-sm mt-3"
                        >
                          Change Reason:
                        </label>
                        <div className="col-md-10">
                          <input
                            type="text"
                            className="form-control mt-3 "
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <label
                          htmlFor=""
                          className="col-md-2 col-form-label col-form-label-sm"
                        >
                          Changed BY:
                        </label>
                        <div className="col-md-7">
                          <input type="text" className="form-control" id="" />
                        </div>
                        <div className="col-md-3">
                          {/* <input type="button" value="Change Reg No." className="button button-primary w-100" />
                           */}
                          <button
                            type="submit"
                            value="Search"
                            className="button button-primary w-100"
                          >
                            Change Reg No
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeRegistrationNo;
