
import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
// import AnnualMenu from "./AnnualMenu";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import moment from "moment";
import { scrollToTop } from "../Shared/ScrollOnTop";
import PayrollMenu from "./PayrollMenu";

function AddNewDesignation(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Designation, setDesignation] = useState({});
  let params = useParams();

  const [DesignationID, setDesignationID] = useState(params.id);


  const [designationName, setDesignationName] = useState("")
  const [Descriptions, setDescriptions] = useState("")
  const [Requirements, setRequirements] = useState("")
  const [OtherDetails, setOtherDetails] = useState("")


  console.log(DesignationID);
  let navigate = useNavigate();

  document.title = "Add Designation";
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,

        Name: designationName,

        Job_Descriptions: Descriptions,
        Job_Requirements: Requirements,
        Other_Details: OtherDetails,
      };

      // console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "/Payroll/AddNewDesignation",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
              navigate("/PayRoll/ManageDesignations")
            }, 3000)
          }
          else if (response.data.status_code == 2 && response.data.status_message == "Sorry, something went wrong.") {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });

          }
          else if (response.data.status_code == 0 && response.data.status_message == "Invalid Access Key.") {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });

          }

          else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidated(true);
  };

  return (
    <>
      {/* <LoginCheck /> */}
      {/* <AnnualMenu /> */}
      <PayrollMenu />

      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/AnnualTasks/AnnualTasksDashboard">
                      Manage Designation
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add New Designation
                  </li>
                </ol>
              </nav>
              <div className="page-title">Add New Designation</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/PayRoll/ManageDesignations"
                  className="button button-white button-icon"
                >
                  Manage Designation
                  <i className="ri-list-check"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body p-md-4">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="row align-items-center">
                  <div className="col-md-3">
                    <div className="form-group mb-4">
                      <label>Designation Name</label>
                      <input
                        type="text"
                        defaultValue={designationName}
                        className="form-control"
                        required
                        name="designationName"
                        onChange={(e) => setDesignationName(e.target.value)}
                      />

                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-4">
                      <label> Job Requirements</label>
                      <input
                        type="text"
                        defaultValue={Requirements}
                        className="form-control"
                        required
                        name="jobReq"
                        onChange={(e) => setRequirements(e.target.value)}
                      />

                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-4">
                      <label> Other Details</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        defaultValue={OtherDetails}
                        name="otherDetail"
                        onChange={(e) => setOtherDetails(e.target.value)}

                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="form-group ">
                      <label>Job Descriptions</label>
                      <input
                        type="text"
                        defaultValue={Descriptions}
                        className="form-control"
                        required
                        name="jobDes"
                        onChange={(e) => setDescriptions(e.target.value)}

                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      {loading ? (
                        <Spinner animation="border" role="status"></Spinner>
                      ) : (
                        <button
                          type="submit"
                          value="Save"
                          className="button button-primary w-100 mt-4"
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewDesignation;
