import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import AccountsMenu from './AccountsMenu';
import { NavLink } from 'react-router-dom';
import YearsDropDown from '../Shared/YearsDropDown';
import { scrollToTop } from '../Shared/ScrollOnTop';

function ChangeYear(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [DefaultFYear, setDefaultFYear] = useState(localStorage.getItem("DefaultFYear"));
    const [FYear, setFYear] = useState(0);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        document.title = "Add/Update Working Financial Year";
        //setFYear(DefaultFYear);

    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setLoading(true);
            //console.log(DefaultFYear);
            localStorage.setItem("DefaultFYear", DefaultFYear);
            toast.success("Financial Year Changed", {
                position: toast.POSITION.TOP_RIGHT
            });




            setLoading(false);
            window.location.reload();

        }
        setValidated(true);


    };

    return (
        <>
            <AccountsMenu />
            <ToastContainer />
            <div class="container body-content">

                <div class="page-head">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li class="breadcrumb-item"><NavLink to="/Accounts/AccountsDashboard">Accounts</NavLink></li>
                                        <li class="breadcrumb-item active" aria-current="page">Change Year</li>
                                    </ol>
                                </nav>
                                <div class="page-title">Change Year</div>
                            </div>
                            <div class="col-md-6">

                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="page-content">
                        <div class="container">
                            <div class="card">
                                <div class="card-body p-md-4">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <div class="row align-items-center">
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Selected Year</label>
                                                    <label class="label-gray">
                                                        {DefaultFYear}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Select New Year</label>
                                                    <select className="form-select" required name="ddlYears" onChange={(e) => setDefaultFYear(e.target.value)}  >
                                                        <option value="">Please Select</option>
                                                        <YearsDropDown selected={DefaultFYear} />
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2 offset-md-4 text-end">
                                                <div class="form-group mt-3">
                                                    {
                                                        loading ?
                                                            <Spinner animation="border" role="status"></Spinner>

                                                            :
                                                            <button type="submit" value="Change Year" className="button button-primary w-100 ">Change Year</button>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangeYear;