import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from 'react-router-dom';
import Examination_navbar from './Examination_navbar';
import ShowExam from '../Shared/ShowExam';
import { scrollToTop } from '../Shared/ScrollOnTop';

function ExamClasses(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [loadingItem, setLoadingItem] = useState(null);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(1000);
    const [totalRecords, setTotalRecords] = useState(0);

    let params = useParams();
    const [ExamID, setExamID] = useState(params.id);

    useEffect(() => {

        document.title = "Manage Exam Classes";

        setIsUpdated(false);
        //setLoading(true);
       
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            ExamID: ExamID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Exam/GetExamClasses',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                console.log(error);
            });

    }, [pageNo, pageSize, isUpdated]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{count++ +  (pageSize * (pageNo - 1))}</td>
                            <td>{item.Class_Id}</td>
                            <td>{item.Class_Code}</td>
                            <td>{item.Class_Short_Name}</td>
                            <td>{item.Class_Name}</td>
                            <td>{item.Wing_Name}</td>
                            <td>{item.Session_Name}</td>
                            <td>{
                                loadingItem === item.Class_Id ?
                                <Spinner animation="grow" size="sm" />
                                :
                                <div className="form-check">
                                    <input className="form-check-input"
                                    checked={item.IsSelected}
                                    type="checkbox" onChange={(e) => UpdateClass(e, item.Class_Id)} />
                                </div>
                            }</td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    const UpdateClass = (e, Class_ID) => {
        e.preventDefault();
        setLoadingItem(Class_ID);
      
        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Class_Id: Class_ID,
            ExamID: ExamID,
            hasRights: e.target.checked
        };

        //console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Exam/AddRemoveClassInExam',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {

                if(response.data.status_code == 1)
                {   
                    setIsUpdated(true); 
                    setLoadingItem(null);
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    // window.scrollTo(0, document.body.scrollHeight);
                    // console.log(document.body.scrollHeight);
                }
                
              
                

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
      <ToastContainer />

            <Examination_navbar />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/ExaminationSystem/ExaminationDashboard">Examinations</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Manage Exam Classes</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Manage Exam Classes</div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="collapse show" id="collapseFilteres">
                    <div className="page-content">
                        <div className="container">
                            <ShowExam ExamID={ExamID} /> 

                            <div className="card mt-2">
                                <div className="card-body">
                                    <table className="table table-theme table-hover">
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>
                                                    Class ID
                                                </th>
                                                <th>
                                                    Class Code
                                                </th>
                                                <th>
                                                    Class Short Name
                                                </th>
                                                <th>
                                                    Class Name
                                                </th>
                                                <th>
                                                    Wing Name
                                                </th>
                                                <th>
                                                    Session
                                                </th>
                                                <th>Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                        loading ?
                                            <tr>
                                                <td className="text-center" colspan="8">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {renderLedger()}
                                            </>
                                    }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </>
    );
}

export default ExamClasses;