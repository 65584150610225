import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import StaffAtMenu from './StaffAtMenu';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../General/Pagination";
import { scrollToTop } from '../Shared/ScrollOnTop';

function AttendanceRights(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {

        document.title = "Manage Attendance Rights";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'StaffAttendance/GetAllTypeAccess',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                console.log(error);
            });

    }, [pageNo, pageSize, isUpdated]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.ID}</td>
                            <td>{item.TypeName}</td>
                            <td>
                                {/* <input type="text" defaultValue={item.UserName} className="form-control"></input> */}
                                <input
                                    type="text"
                                    defaultValue={item.UserName}
                                    id={`userName_${item.ID}`}
                                    className="form-control"
                                />
                            </td>
                            <td>
                                {/* <ul className="inline-action" aria-labelledby="dropdownMenuButton1">
                                    <li><NavLink to={'/Setup/UpdateAccessType/' + item.ID} className=" green"><i className="ri-edit-line"></i></NavLink></li>
                                </ul> */}
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="4"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    const saveRights = () => {
        const updatedLedger = listOfLedger.map((item) => {
            const updatedUserName = document.getElementById(`userName_${item.ID}`).value;
            return { ...item, UserName: updatedUserName };
        });

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ListofTypeAccess: updatedLedger,
        };
        console.log(data);
        setLoading(true);

        axios
            .post(config.base_url + "StaffAttendance/UpdateRights", data)
            .then(function (response) {
                console.log(response.data);
                setLoading(false);
                setIsUpdated(true);
                toast.success("Rights saved successfully.");
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Failed to save rights.");
            });
    };



    return (
        <>
            <StaffAtMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                    <li className="breadcrumb-item"><NavLink to="/StaffAttendance/StaffAttendanceDashboard">Staff Attendance</NavLink></li>
                                    <li className="breadcrumb-item active" aria-current="page">Attendance Rights</li>
                                </ol>
                            </nav>
                            <div className="page-title"> Attendance Rights Settings</div>
                        </div>
                    </div>
                </div>
            </div>

            <div >

                <div className="page-content">
                    <div className="container">

                        <div className="card">
                            <div className="card-body">
                                <table className="table table-theme">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Type</th>
                                            <th>User Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loading ?
                                                <tr>
                                                    <td className="text-center" colspan="5">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </td>
                                                </tr>
                                                :
                                                <>
                                                    {renderLedger()}
                                                </>

                                        }


                                    </tbody>
                                </table>
                                {
                                    totalRecords > 100 &&
                                    <div className="pagination-wrap">

                                        <div className="row align-items-center">
                                            <div className="col-md-2 form_default">
                                                <div className="d-flex align-items-center">
                                                    <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                    <div className="form-group">
                                                        <select className="form-select"
                                                            style={{ width: "100px" }}
                                                            onChange={(e) => {
                                                                setPageNo(1);
                                                                setPageSize(parseInt(e.target.value));


                                                            }}>
                                                            <option value="10" selected>10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <Pagination
                                                pageNo={pageNo}
                                                pageSize={pageSize}
                                                totalRecords={totalRecords}
                                                onPageChange={handlePageChange}
                                            />
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>

                        <div className="card mt-2">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-2 offset-md-10">
                                        <div className="form-group ">
                                            {
                                                loading ?
                                                    <Spinner animation="border" role="status"></Spinner>

                                                    :
                                                    <button
                                                        type="submit"
                                                        value="Save"
                                                        className="button button-primary w-100"
                                                        onClick={saveRights}
                                                    >
                                                        Save Rights
                                                    </button>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default AttendanceRights;