import React, { useState } from "react";
import PayrollMenu from './PayrollMenu';
import { NavLink } from 'react-router-dom';
import MonthsDropDown from '../Shared/MonthsDropDown.js';
import YearsDropDown from '../Shared/YearsDropDown.js';
import DesignationDropDown from '../Shared/DesignationDropDown.js';
import WingsDropDown from '../Shared/WingsDropDown.js';
import EmployeeTypeDropDown from "../Shared/EmployeeTypeDropDown";
import moment from "moment";
import { scrollToTop } from '../Shared/ScrollOnTop';

function ZeroReports(props) {

    const [option2Month, setOption2Month] = useState(moment().format("M"));
    const [option2Year, setOption2Year] = useState(moment().format("YYYY"));
    const [WingID, setWingID] = useState(0);
    const [Department, setDepartment] = useState("");
    document.title="DPS School Management System"

    const handleDeparment = (e) => {
        setWingID(e.target.value);

        const selectedOption = e.target.options[e.target.selectedIndex].text;
        setDepartment(selectedOption);

    }

    return (
        <>
            <PayrollMenu />

            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/Dashboard">Dashboard</NavLink></li>
                                        <li className="breadcrumb-item"><NavLink to="/PayRoll/PayRollDashboard">Payroll</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Zero Reports</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Zero Reports</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-md-4">
                                        <div className="row gy-3 align-items-center">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Select Department:</label>
                                                    <select name="Wing" className="form-select"
                                                        onChange={handleDeparment} required>
                                                        <option value="">Please Select</option>
                                                        <EmployeeTypeDropDown />
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Select Month</label>
                                                    <select className="form-select"
                                                        required=""
                                                        value={option2Month}
                                                        onChange={(e) => setOption2Month(e.target.value)}>
                                                        <MonthsDropDown />
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Select Year</label>
                                                    <select className="form-select"
                                                        value={option2Year}
                                                        onChange={(e) => setOption2Year(e.target.value)}
                                                        required="">
                                                        <YearsDropDown />
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-2 offset-md-1 text-end">
                                                <div className="form-group mt-4">
                                                    <NavLink
                                                        className={`button w-100 button-primary ${option2Month === null || option2Year === null || WingID === 0 ? 'disabled' : ''}`}
                                                        to={`/PayRoll/PrintZeroReport?month=${option2Month}&year=${option2Year}&EmployeeTypeId=${WingID}&Deparment=${Department}`}
                                                        target="_blank"
                                                    >Print Zero Report</NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-2 offset-md-10 text-end">
                                                <div className="form-group">
                                                    <input type="button" value="Print Pay Slips" className="button button-primary w-100" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ZeroReports;