import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import moment from "moment";
import { Form } from "react-bootstrap";
import "cropperjs/dist/cropper.css";
import { Link, useNavigate, useParams } from 'react-router-dom';
import TeacherMenu from "./TeacherMenu";
import { scrollToTop } from '../Shared/ScrollOnTop';

const UpdateProfile = (props) => {

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [loading, setLoading] = useState(false);
  const [Student, setStudent] = useState({});

  useEffect(() => {

    if ([props.StudentID] > 0) {

      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        ID: props.StudentID

      };
      setLoading(true);
      var api_config = {
        method: 'post',
        url: config.base_url + 'Student/GetStudentByID',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      console.log(data)
      axios(api_config)
        .then(function (response) {
          console.log(response.data);
          if (response.data.status_code == 1) {
            setStudent(response.data.Student);

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error('Network Error..', {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    }
    else {
      setLoading(false);
    }

  }, [props.StudentID]);


  return (
    <>
      <TeacherMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/teacher/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Update Profile</li>
                  </ol>
                </nav>
                <div className="page-title">Update Profile</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body p-md-4">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Employee Code:</label>
                    <input value={"@Model.Code"} disabled className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Employee Name:</label>
                    <input value={"@Model.Name"} disabled className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Father Name:</label>
                    <input value={"@Model.Father_Name"} disabled className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Husband Name:</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Marital Status:</label>
                    <select className="form-select">
                      <option value="Not Available">Not Available</option>
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Widowed">Widowed</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Gender:</label>
                    <input type="text" name="" className="form-control" id="" />
                    {/* <label className="">
                      @if (@Model.Gender == true)
                      {
                        <p className="m-0">Male</p>
                      }
                      else
                      {
                        <p className="m-0">Female</p>
                      }
                    </label> */}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Designation :</label>
                    <input type="text" value={"@Model.Selected_Designation.Name"} className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Blood Group:</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">CNIC No:</label>
                    <input type="text" value={"@Model.CNIC_NO"} className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Bank Account No:</label>
                    <input type="text" value={"@Model.Account_No"} className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">EOBI No:</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">NTN NO:</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Phone No:</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Mobile No:</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor=""> Pay Scale :</label>
                    <input type="text" value={"@Model.Basic_Pay_Scale"} className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Group/Depart.:</label>
                    <input type="text" value={"@Model.Department"} className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Home Address:</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Qualification :</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-md-2 offset-md-4">
                  <div className="form-group mt-3">
                    <input type="submit" name="btnSave" value="Save" className="button button-primary w-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      {/* <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="card mb-3">
                <div className="card-body card-form-wrap">
                  <div className="form-wrap mb-md-0">
                    <div className="row align-items-center">
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Employee Code:
                      </label>
                      <div className="col-md-4">
                        <label className="label-value">
                          @Model.Code
                        </label>
                      </div>
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Employee Name:
                      </label>
                      <div className="col-md-4">
                        <label className="label-value">
                          @Model.Name
                        </label>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Father Name:
                      </label>
                      <div className="col-md-4">
                        <label className="label-value">
                          @Model.Father_Name
                        </label>
                      </div>
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Husband Name:
                      </label>
                      <div className="col-md-4">
                        <input type="text" className="bg-white form-control" />

                      </div>
                    </div>
                    <div className="row align-items-center">
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Marital Status:
                      </label>
                      <div className="col-md-4">
                        <select className="form-select">
                          <option value="Not Available">Not Available</option>
                          <option value="Single">Single</option>
                          <option value="Married">Married</option>
                          <option value="Divorced">Divorced</option>
                          <option value="Widowed">Widowed</option>

                        </select>
                      </div>
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Gender:
                      </label>
                      <div className="col-md-4">
                        <label className="label-value">
                          @if (@Model.Gender == true)
                          {
                            <p className="m-0">Male</p>
                          }
                          else
                          {
                            <p className="m-0">Female</p>
                          }
                        </label>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Designation :
                      </label>
                      <div className="col-md-4">
                        <label className="label-value">
                          @Model.Selected_Designation.Name
                        </label>
                      </div>
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Blood Group:
                      </label>
                      <div className="col-md-4">
                        <input type="text" className=" bg-white form-control" />
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        CNIC No:
                      </label>
                      <div className="col-md-4">
                        <label className="label-value">
                          @Model.CNIC_NO
                        </label>
                      </div>
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Bank Account No:
                      </label>
                      <div className="col-md-4">
                        <label className="label-value">
                          @Model.Account_No
                        </label>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        EOBI No:
                      </label>
                      <div className="col-md-4">
                        <input type="text" className="form-control bg-white"
                        />
                      </div>
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        NTN NO:
                      </label>
                      <div className="col-md-4">
                        <input type="text" className="form-control bg-white"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Phone No:
                      </label>
                      <div className="col-md-4">
                        <input type="text" className="form-control bg-white"
                        />
                      </div>
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Mobile No:
                      </label>
                      <div className="col-md-4">
                        <input type="text" className="form-control bg-white"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Pay Scale :
                      </label>
                      <div className="col-md-4">
                        <label className="label-value">
                          @Model.Basic_Pay_Scale
                        </label>
                      </div>
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Group/Depart.:
                      </label>
                      <div className="col-md-4">
                        <label className="label-value">
                          @Model.Department
                        </label>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Home Address:
                      </label>
                      <div className="col-md-10">
                        <input name="txtHomeAddress"
                          className="form-control bg-white"></input>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <label for="" className="col-md-2 col-form-label col-form-label-sm">
                        Qualification :
                      </label>
                      <div className="col-md-10">
                        <input name="txtQualification"

                          className="form-control bg-white"></input>
                      </div>
                    </div>
                    <div className="row align-items-center mb-md-0">
                      <div className="col-md-3 offset-md-9 text-end">
                        <input type="submit" name="btnSave"

                          value="Save" id="btnSave" className="button button-primary " />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default UpdateProfile