import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import SecurityMenu from "./SecurityMenu";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, FormCheck } from "react-bootstrap";
import moment from "moment";
import UserGroupDropDown from "../Shared/UserGroupDropDown";
import { scrollToTop } from "../Shared/ScrollOnTop";
import { flatMap } from "lodash";

function RightsAssignment(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [UserGroup, setUserGroup] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [loadingItem, setLoadingItem] = useState(null);
  const [selectedOption, setSelectedOption] = useState({});
  const [ledgerOld, setLedgerOld] = useState([]);
  const [filterByAction, setfilterByAction] = useState("ALL");
  const [stayFiltered,setstayFiltered] =useState([])

  let params = useParams();

  const [GroupID, setGroupID] = useState(params.id);

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Group Rights Assignment";
    setIsUpdated(false);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: GroupID,
      Status: filterByAction,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/GetUserGroupByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setUserGroup(response.data.UserGroup);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

   setLoading(true);
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: GroupID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/GetAllPagesByGroupID",
      headers: {
        "Content-Type": "application/json",
      },
      data: dashboardParms,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLedgerOld(response.data.ListofRecords);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [isUpdated, filterByAction]);

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.ID}</td>
            <td>{item.ControllerName}</td>
            <td>{item.FunctionName}</td>
            <td>{item.FullURL}</td>
            <td>{item.Details}</td>
            <td>
              {loadingItem === item.ID ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    checked={item.hasRights}
                    type="checkbox"
                    onChange={(e) => UpdateRights(e, item.ID)}
                  />
                </div>
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="6">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const UpdateRights = (e, ID) => {
    setLoadingItem(ID);
   
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ControllerID: ID,
      GroupID: GroupID,
      hasRights: e.target.checked,
    };

    //console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Members/UpdateGroupRight",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        setLedger(stayFiltered)
      setIsUpdated(true);
        setLoadingItem(null);
        toast.success(response.data.status_message, {
          position: toast.POSITION.TOP_RIGHT,
        });

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // filter by inputs ...................
  const filterAlls = (e) => {
    let term = e.target.value;
    if (term !== "") {
      let filteredArray = listOfLedger.filter((object) =>
        Object.values(object).some((value) =>
          value.toString().toLowerCase().includes(term.toLowerCase())
        )
      );
      setLedger(filteredArray);
      setstayFiltered(filteredArray)
    } else {
      setLedger(ledgerOld);
    }
  };
 
  return (
    <>
      {/* <LoginCheck /> */}
      <SecurityMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/Security/SecurityDashboard">Security</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Group Rights
                  </li>
                </ol>
              </nav>
              <div className="page-title">
                Rights Assignment for {UserGroup.GroupName}
              </div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/Security/GroupManagement"
                  className="button button-white button-icon"
                >
                  Manage Groups
                  <i className="ri-list-check"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="card mb-2">
            <div className="card-body p-md-4">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Controller</label>
                    <input
                      type="text"
                      name="Form_No"
                      className="form-control"
                      placeholder=""
                      onChange={(e) => filterAlls(e)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Action</label>
                    <input
                      type="text"
                      name="Form_No"
                      className="form-control"
                      placeholder=""
                      onChange={(e) => filterAlls(e)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">URL</label>
                    <input
                      type="text"
                      name="Form_No"
                      className="form-control"
                      placeholder=""
                      onChange={(e) => filterAlls(e)}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label htmlFor="">Status</label>
                    <select
                      className="form-select"
                      onChange={(e) => setfilterByAction(e.target.value)}
                      value={filterByAction}
                    >
                      <option value="ALL">ALL</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                </div>
           
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <table className="table table-theme table-hover">

                <thead>
                  <tr>
                    <th>Sr#</th>
                    <th>Controller</th>

                    <th>Action</th>
                    <th>URL</th>
                    <th>Details</th>
                    <th className="text-center">Action</th>
                  </tr>
             
                </thead>
                <tbody>
                  {loading ? (
                    <>
                      <div className="my-2">
                        <Spinner animation="border" role="status"></Spinner>
                      </div>
                    </>
                  ) : (
                    <>{renderLedger()}</>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RightsAssignment;
