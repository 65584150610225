import ReportsMenu from './ReportsMenu';
import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import { toast, ToastContainer } from 'react-toastify';
import { NavLink, Link } from "react-router-dom";
import { scrollToTop } from '../Shared/ScrollOnTop';
import moment from "moment";

const StudentStrengthReport = () => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

    const [showFilters, setShowFilters] = useState(true);

    const [loading, setLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    const [validated, setValidated] = useState(false);

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);


    const [NewWingId, setNewWingId] = useState(0);
    const [NewClassId, setNewClassId] = useState(0);
    const [Form_No, setForm_No] = useState(0);
    const [WingId, setWingId] = useState(0);
    const [SessionId, setSessioinId] = useState(localStorage.getItem("DefaultSession"));
    const [ClassId, setClassId] = useState(0);
    const [SectionID, setSectionID] = useState(0);
    const [GroupId, setGroupId] = useState(0);
    const [isUpdated, setIsUpdated] = useState(false);
    const [StudentID, setStudentID] = useState(0);
    const [Keywords, setKeywords] = useState("");
    const [OrderBy, setOrderBy] = useState(0);

    useEffect(() => {

        document.title = "New Students List";

        setIsUpdated(false);
        setLoading(true);

        const data = {
           AccessKey: AccessKey,
            UserID: UserID,
MemberType: MemberType,
            pageNo: pageNo,
            pageSize: pageSize,
            SessionID: SessionId,
            Wing_Id: WingId,
            Class_Id: ClassId,
            Section_Id: SectionID,
            Form_No: Form_No,
            Student_ID: StudentID,
            Keywords: Keywords,
            Group_Id: GroupId,
            OrderBy: OrderBy,
        };

      //  console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Student/SearchNewStudents',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                console.log(error);
            });

    }, [pageNo, pageSize, WingId, ClassId, SectionID, SessionId, Form_No, StudentID, Keywords, GroupId, OrderBy]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    

    return (
        <>
            <ReportsMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/Reports/ReportsDashboard">Reports</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Students Strength Report</li>
                                </ol>
                            </nav>
                            <div className="page-title">Students Strength Report</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`collapse ${showFilters ? 'show' : ''} `} id="collapseFilteres">
                {/* <Form noValidate validated={validated} onSubmit={handleSubmit}> */}
                <div className="page-content">
                    <div className="container">
                        <div className="card mb-2">
                            <div className="card-body p-md-4">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group mb-3">
                                            <label for="" className="">Select Session:</label>
                                            <select className="form-select" onChange={(e) => setSessioinId(e.target.value)} name="SessionID">
                                                <option value="0">---All---</option>
                                                <SessionsDropDown />
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group mb-3">
                                            <label>Select Wing:</label>
                                            <select name="Wing_Id" onChange={(e) => setNewWingId(e.target.value)} className="form-select">
                                                <option value="0">---All---</option>
                                                <WingsDropDown />
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group mb-3">
                                            <label for="" className="">Select Class:</label>
                                            <select className="form-select" onChange={(e) => setNewClassId(e.target.value)} name="Class_ID">
                                                <option value="">Please Select</option>
                                                {
                                                    SessionId != 0 && NewWingId != 0 &&
                                                    <ClassesDropDown SessionID={SessionId} WingID={NewWingId} />
                                                }

                                            </select>
                                        </div>
                                    </div>
                                   
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label for="" className="">Date of Attendance:</label>
                                            <input type="date" className='form-control' max={moment().format(config.date_format_input)} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label for="" className="">Fetch Data From:</label>
                                            <select className='form-select' name="" id="">
                                            <option value="">Please Select</option>
                                            <option value="1">Attendance Record</option>
                                            <option value="2">Saved Status</option>
                                            
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-2 offset-md-7 text-end">
                                        <div className="form-group text-end">
                                            <button type="submit" value="Search" className="w-100 button button-primary mt-4">Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
          
        </>
    );
}

export default StudentStrengthReport;
