import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function GetSubjectsBySectionIDExamIDDropDown({SectionID,ExamID}) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [Subject, setSubject] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        const apiData = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Section_Id: SectionID,
            ExamID: ExamID,
        };

        // console.log(apiData,SectionID,ExamID);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Exam/GetSubjectsBySectionIDExamID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: apiData
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    setSubject(response.data.ListofRecords);
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [SectionID,ExamID]);

    <ToastContainer />

    if (loading) {
        return <option selected disabled>Loading...</option>
    }
    else {
        return (
            Subject.map((item, index) => {
                return (
                    <option value={item.ID} key={index}>{item.ID + ' ' + item.Name}</option>
                )
            })
        );
    }
}

export default GetSubjectsBySectionIDExamIDDropDown;