import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../General/Pagination";
import PayrollMenu from "./PayrollMenu";
import { scrollToTop } from "../Shared/ScrollOnTop";

function EmployeeLedger(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Employee, setEmployee] = useState({});

  const today = moment();
  const month1 = today.clone().subtract(2, "months");
  const [StartDate, setStartDate] = useState(
    moment(month1).format("YYYY-MM-DD")
  );
  const [EndDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [ledger, setLedger] = useState([]);

  let params = useParams();
  const [EmployeeID, setEmployeeID] = useState(params.id);
  let navigate = useNavigate();

  let totalselaryDue = 0;
  let totalselaryDeductions = 0;

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    document.title = "View Employee Ledger";

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      ID: EmployeeID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/GetEmployeeByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setEmployee(response.data.Employee);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  useEffect(() => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      ID: EmployeeID,
      StartDate: StartDate,
      EndDate: EndDate,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Payroll/GetEmployeeLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log("data", response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setTotalRecords(response.data.totalRecords);
        }
      })
      .catch(function (error) {
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [pageNo, pageSize, StartDate, EndDate]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (ledger.length > 0) {
      return ledger.map((item, index) => {
        totalselaryDue += item.Salary_Due;
        totalselaryDeductions += item.Salary_Deduction;
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.Name}</td>
            <td>{moment(item.Entry_Date).format(config.date_format)}</td>
            <td>{item.Description}</td>
            <td>{item.Salary_Due}</td>
            <td>{item.Salary_Deduction}</td>
            <td>
              <ul className="inline-action">
                <li>
                  <NavLink className=" green">
                    <i className="ri-edit-line"></i>
                  </NavLink>
                </li>
                <li>
                  <button className="red">
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="7">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <PayrollMenu />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/PayRoll/PayRollDashboard">Payroll</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Employee Ledger
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Employee Ledger</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="card">
              <div className="card-body card-form-wrap">
                <div className="form-wrap">
                  <div className="form-legend">STAFF LEDGER DETAILS</div>
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label for="" className="">
                          Employe ID:{" "}
                        </label>
                        <label className="label-value">{Employee.ID}</label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label for="" className="">
                          Type/Depart:
                        </label>
                        <label className="label-value">
                          {Employee.Employee_Type_BE?.Name}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label for="" className="">
                          Full Name:{" "}
                        </label>
                        <label className="label-value">{Employee.Name}</label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label for="" className="">
                          Designation:
                        </label>
                        <label className="label-value">
                          {Employee.Selected_Designation?.Name}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label for="" className="">
                          Father Name:{" "}
                        </label>
                        <label className="label-value">
                          {Employee.Father_Name}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label for="" className="">
                          Pay Scale:
                        </label>
                        <label className="label-value">
                          {Employee.Basic_Pay_Scale}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label for="" className="">
                          Address:{" "}
                        </label>
                        <label className="label-value">
                          {Employee.Home_Address}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label for="" className="">
                          Joining Date:
                        </label>
                        <label className="label-value">
                          {moment(Employee.Date_Of_Joining).format(
                            config.date_format
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label for="" className="">
                          A/C No:{" "}
                        </label>
                        <label className="label-value">
                          {Employee.Account_No}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label for="" className="">
                          CNIC No:
                        </label>
                        <label className="label-value">
                          {Employee.CNIC_NO}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-2">
              <div className="card-body">
                <div className="row align-items-center mb-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label for="" className="">
                        Start Date:{" "}
                      </label>

                      <input
                        type="date"
                        className="form-control"
                        value={StartDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label for="" className="">
                        End Date:
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={EndDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 text-end">
                    <div className="form-group">
                      <input
                        type="button"
                        value="Filter Records"
                        className="button button-primary"
                      />
                    </div>
                  </div>
                </div>
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th>Serial No</th>
                      <th>Salary Type</th>
                      <th>Dated</th>
                      <th>Desicription</th>
                      <th>Salary Due</th>
                      <th>Deductions</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderLedger()}

                    <tr>
                      <td  colspan="3"></td>
                      <td  className="text-end">
                        {/* <b> Total Due </b> */}
                      </td>
                      <td colSpan="1">
                        <b>{totalselaryDue} </b>
                      </td>


                      {/* <td  className="">
                        <b> Total Deductions </b>
                      </td> */}
                      <td >
                        <b>{totalselaryDeductions} </b>
                      </td>







                    </tr>
                  </tbody>
                </table>
                {totalRecords > 10 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10" selected>
                                10
                              </option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeLedger;
